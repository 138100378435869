import {createContext, useReducer} from 'preact/compat'
import {useCallback, useRef} from 'preact/hooks'
import {ComponentChildren} from 'preact'
import {useSettingsContext} from "./SettingsContext";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import authReducer, {AuthReducerAction} from "./AuthReducer";
import {safeLocalStorage} from "../utils/storage";
import axios from "axios/index";
import {
  fetchCurrentUser,
  setAuthAxiosHeaders,
  saveUserLocally, getLocalUser
} from "../services/auth";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import Delayed from "../components/Delayed";
import useClearQueries from "../hooks/queries/useClearQueries";
import AdUnitPlaceholder from "../components/common/AdUnitPlaceholder";
import AppViewEvent from "../components/AppViewEvent";
import {pageInfo} from "../global";

type AuthProviderProps = {
  children: ComponentChildren;
}

export type AuthContextValue = {
  isLoading: boolean;
  isLoggedIn: boolean;
  isAnonymous: boolean;
  email:string;
  signIn: (options: {
    email?: string,
    signInToken?: string,
    refreshToken?:string,
    newsletterMannerOfConsent?: string,
    fallbackToAnonymousWhenFail: boolean,
  }) => Promise<void>;
  refetchUser: () => any;
  signInError?: any;
  isCurrentUserSuccess: boolean;
  dispatchAuth: (action: AuthReducerAction) => void
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined)


const handleStandAloneErrors = (e:any, context:string) => {
  const errorMsg = e.response?.data?.message

  if (errorMsg !== 'Invalid email' || context !== 'subscribe') { //fixme: Whats the purpose of this condition?
    // clearUserAuth()
    throw new Response('', {status: 401, statusText: 'Context is not "subscribe"'})
  } else if (errorMsg === 'Invalid email') {
    // clearUserAuth()
    throw new Response('', {status: 401, statusText: '401-invalid-email'})
  } else if (errorMsg === 'Pending email validation') {
    throw new Response('', {status: 401, statusText: '401-pending-auth'})
  } else {
    // clearUserAuth()
    throw new Response('', {status: 401, statusText: '401-something-went-wrong'})
  }
}

const initialState = {
  userEmail: '',
  isLoggedIn: false,
  isAuthenticated: false,
  isAnonymous: false,
  isLoading: false,
  signInError: undefined,
  initialUserError: undefined,
};

export const AuthProvider = ({children}: AuthProviderProps) => {
  const signInRetryCount = useRef(0)
  const clearQueries = useClearQueries()
  const {tokenType, signInToken, setSignInToken, appSlug, emailParam, setEmailParam, application, integrationType, context} = useSettingsContext()
  const queryClient = useQueryClient()
  const {executeRecaptcha} = useGoogleReCaptcha()
  const [authState, dispatch] = useReducer(authReducer, initialState);
  const {isLoading: isLoadingCurrentUser, refetch: refetchUser, isSuccess: isCurrentUserSuccess} = useQuery({
    queryKey: ['get-user', {appSlug}],
    cacheTime: 0,
    retry: 0,
    refetchOnWindowFocus: true,
    useErrorBoundary: true,
    queryFn: async () => {
      if (emailParam) {
        setEmailParam('')
        return signIn({email: emailParam, fallbackToAnonymousWhenFail: true})
      }
      if (signInToken) {
        setSignInToken('')
        return signIn({signInToken, fallbackToAnonymousWhenFail: true})
      }
      let localStorageUser
      try {
        const userString = getLocalUser(application.is_user_saved_in_session_storage, appSlug)
        if (userString !== undefined && userString !== 'undefined' && userString !== null) {
          localStorageUser = JSON.parse(userString)
        }
      } catch (e:any) {
        console.error(e)
        dispatch({type: 'initial_user_error', payload: e})
      }

      if (!localStorageUser) {
        if (application.anonymous_enabled) {
          return signIn({email: '', fallbackToAnonymousWhenFail: true})
        }
        throw new Response('', {status: 401, statusText: 'no-anonymous-allowed'})
      }

      try {
        setAuthAxiosHeaders(localStorageUser?.accessToken, localStorageUser?.appKeyId)
        const currentUser = await fetchCurrentUser()
        dispatch({type: 'initial_user_success', payload: {email: currentUser?.data?.email}});
        return currentUser
      } catch (e:any) {
        queryClient.removeQueries({queryKey: ['rewards', 'list', 'reward', 'profile']});
        dispatch({type: 'initial_user_error', payload: e})
        console.error('fetch current user error', e)
        if (e?.code !== 'ERR_NETWORK') {
          return signIn({refreshToken: localStorageUser?.refreshToken, fallbackToAnonymousWhenFail: true})
        }
        return null
      }
    }
  })

  const signIn: AuthContextValue['signIn'] = useCallback(async ({
                                                                  email,
                                                                  signInToken,
                                                                  refreshToken,
                                                                  newsletterMannerOfConsent,
                                                                  fallbackToAnonymousWhenFail
                                                                }) => {
    try {
      dispatch({type: 'auth_loading'});
      let recaptchaToken = ''
      if (application.recaptcha_login_enabled && executeRecaptcha && email) {
        recaptchaToken = await executeRecaptcha('login')
      }
      const newsletterUserConsent = document.getElementById('consentData')?.innerHTML
      const user = await axios
          .post(`/publishers/${appSlug}/auth`, {
            refreshToken: refreshToken || undefined,
            newsletterUserConsent: newsletterUserConsent || undefined,
            newsletterMannerOfConsent: newsletterMannerOfConsent || undefined,
            recaptchaToken: recaptchaToken || undefined,
          }, {
            params: {
              email: email || undefined,
              token_type: tokenType || undefined,
              signin_token: signInToken || undefined,
              page_info_url: pageInfo.url
            },
            headers: {
              lang: safeLocalStorage.getItem('lang') ?? ''
            }
          })
          .then((response) => response.data)

      const {appKeyId, token, refreshToken: newRefreshToken, email: emailFromSignin} = user

      saveUserLocally(application.is_user_saved_in_session_storage, {accessToken: token, refreshToken: newRefreshToken, appSlug, appKeyId})
      setAuthAxiosHeaders(token, appKeyId)
      dispatch({type: 'auth_success', payload: {email: emailFromSignin}});

      return user
    } catch (e: any) {
      if (!integrationType) {
        handleStandAloneErrors(e, context)
      }
      let user:any = {}
      if (application.anonymous_enabled && fallbackToAnonymousWhenFail) {
        if (signInRetryCount.current === 0) {
          if (integrationType === 'panel') {
            clearQueries()
          }
          signInRetryCount.current++
          user = await signIn({email: '', fallbackToAnonymousWhenFail})
          signInRetryCount.current = 0
        }
      } else {
        console.error('sign in error', e)
        dispatch({type: 'auth_error', payload: e})
        throw e
      }

      return user
    }
  }, [appSlug, application.anonymous_enabled, application.recaptcha_login_enabled, clearQueries, context, executeRecaptcha, integrationType, tokenType])

  if (isLoadingCurrentUser) return (
      <Delayed>
        <AdUnitPlaceholder />
      </Delayed>
  )

  // if (!authState.isAuthenticated) return (
  //     <Delayed>
  //       <AdUnitPlaceholder />
  //     </Delayed>
  // )

  return (
      <AuthContext.Provider
          value={{
            isLoading: authState.isLoading,
            signIn,
            isLoggedIn: authState.isLoggedIn,
            isAnonymous: authState.isAnonymous,
            email: authState.userEmail,
            refetchUser,
            signInError: authState.signInError,
            isCurrentUserSuccess,
            dispatchAuth: dispatch
          }}
      >
        {authState.isAuthenticated &&
          <>
            <AppViewEvent />
          </>
        }
        {children}
      </AuthContext.Provider>
  )
}

export default AuthContext

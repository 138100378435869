export enum StorefrontType {
  CardFlip = 'card-flip',
  Overlay = 'overlay',
}

export type Integration = {
  id: string;
  with_container: boolean;
  phrases: any;
  storefront_type: StorefrontType;
  ad_label_disabled: boolean
  third_party_label_disabled: boolean
  affiliate_disclosure_disabled: boolean
};

export type Panel = Omit<Integration, 'with_container' | 'storefront_type'>;
export type Modal = Omit<Integration, 'with_container'>;

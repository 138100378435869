import {FunctionalComponent, h} from 'preact';
import styles from './PoweredByGoloot.styles.scss'
import clsx from "clsx";
import {useTranslation} from "../contexts/I18nContext";
import DOMPurify from "dompurify";
import {useSettingsContext} from "../contexts/SettingsContext";

type Props = {
  rewardsAmount: number
}

const PoweredByGoloot: FunctionalComponent<Props> = ({rewardsAmount}) => {
  const {getIntegrationOrAppSetting} = useSettingsContext()
  const {t} = useTranslation()

  if (rewardsAmount === 0) return null
  if (getIntegrationOrAppSetting('third_party_label_disabled')) return null

  return (
      <div className={clsx('py-3 mb-3', styles.PoweredByGoloot)}
           dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t('privacyPolicy.goloot'), {ADD_ATTR: ['target']})}} />
  );
};

export default PoweredByGoloot;


import {useMemo} from "preact/hooks";
import useUserProfileQuery from "./queries/useUserProfileQuery";
import {Reward} from "../interfaces/sdk";

const useRewardsClaimLimitReached = (rewards: Reward[]) => {
  const {progress} = useUserProfileQuery()

  return useMemo(() => {
    if (!progress) return []

    if (progress.claimLimitReached) {
      return rewards.filter(reward => Boolean(reward.claimed))
    }

    return rewards
  }, [rewards, progress])

}

export default useRewardsClaimLimitReached

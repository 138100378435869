import {Reward} from "../interfaces/sdk";
import {TRACKING} from "../global";

export const injectImpressionTrackingTag = (rewards: Reward[], visibilityPercents: number[]) => {
  rewards.forEach((reward, index) => {
    if (reward.tracking_impression_tag) {
      const visibilityPercent = visibilityPercents[index]
      //Check if already exist before appending to body
      if (!document.getElementById(`reward-impression-${reward.id}`) && visibilityPercent >= TRACKING.thumbnail_impression_threshold * 100) {
        const trackingTagContainer = document.createElement('div')
        trackingTagContainer.style.display = 'none'
        trackingTagContainer.id = `reward-impression-${reward.id}`
        trackingTagContainer.innerHTML = reward.tracking_impression_tag.replace('[timestamp]', String(Date.now()))
        document.body.appendChild(trackingTagContainer)
      }
    }
  })
}

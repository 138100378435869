import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const ChevronRightIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Chevron right icon</title>
    <path d="M19.6113 12C19.6113 11.6132 19.4994 11.2266 19.2754 10.8906L14.5098 3.74023C14.2008 3.27723 13.681 3 13.125 3C11.796 3 11.0032 4.48189 11.7402 5.58789L16.0156 12L11.7402 18.4121C11.0032 19.5181 11.796 21 13.125 21C13.682 21 14.2008 20.7218 14.5098 20.2598L19.2754 13.1094C19.4994 12.7734 19.6113 12.3868 19.6113 12ZM13.2793 12C13.2793 11.6132 13.1674 11.2266 12.9434 10.8906L8.17773 3.74023C7.86873 3.27723 7.34897 3 6.79297 3C5.46397 3 4.6712 4.48189 5.4082 5.58789L9.68359 12L5.40625 18.4121C4.66925 19.5181 5.46397 21 6.79297 21C7.34997 21 7.86873 20.7218 8.17773 20.2598L12.9434 13.1094C13.1674 12.7734 13.2793 12.3867 13.2793 12Z" fill="currentCOlor" />
  </svg>
)

export default ChevronRightIcon

import './styles/index.scss'
import App from './App'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import {isCancel} from "axios";

if (typeof window !== 'undefined') {
  Sentry.init({
    dsn: 'https://26d7dd35e0e14b10809e8ea0e9161f60@o1121375.ingest.sentry.io/6157841',
    integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Replay()
    ],
    replaysSessionSampleRate: process.env.PREACT_APP_ENVIRONMENT === 'production' ? 0.1 : 1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: process.env.PREACT_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
    environment: process.env.PREACT_APP_ENVIRONMENT,
    beforeSend (event, hint) {
      const environmentWhiteList = ['development', 'staging', 'production']
      if (environmentWhiteList.includes(process.env.PREACT_APP_ENVIRONMENT || '') && process.env.NODE_ENV === 'production') {
        if (isCancel(hint?.originalException)) {
          return null;
        }
        return event
      }
      return null
    },
  })
}

if (process.env.NODE_ENV === 'development' && process.env.PREACT_APP_ENABLE_MOCKING === 'true') {
  // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'warn',
  });
}

export default App

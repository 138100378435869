import useEvents, {rewardIds} from "./useEvents";
import {useEffect, useRef, useState} from "preact/hooks";
import useAuth from "./useAuth";
import {toast} from "react-hot-toast";
import {getClaimErrorKey} from "../i18n/apiErrors";
import useTranslation from "./useTranslation";
import {resendRewardEmail} from "../services/rewards";
import useSingleRewardQuery from "./queries/useSingleRewardQuery";
import useClaimRewardMutation from "./mutations/useClaimRewardMutation";
import {useNavigate, useParams} from "react-router-dom";
import {useSettingsContext} from "../contexts/SettingsContext";
import * as panelRoutes from '../appRouter/routesPaths/panel'
import * as standaloneRoutes from '../appRouter/routesPaths/standalone'

type Props = {
  rewardId: string;
  requestId: string;
  enabled: boolean;
  claimId?: string;
}

const useReward = ({rewardId, requestId, enabled, claimId}: Props) => {
  const navigate = useNavigate()
  const {integrationType, appSlug, context} = useSettingsContext()
  const {autoClaim} = useParams()
  const raiseEvent = useEvents()
  const {t, locale} = useTranslation()
  const performAutoClaim = useRef(autoClaim === 'true')
  const {data: response, isLoading, isFetching} = useSingleRewardQuery({
    id: rewardId,
    requestId,
    enabled,
    claimId,
    onFetchRewardError: () => {
      switch (true) {
        case integrationType === 'panel':
          navigate(panelRoutes.home())
          break
        case !integrationType:
          navigate(standaloneRoutes.home({appSlug, context}))
          break
      }
    }
  })
  const claimMutation = useClaimRewardMutation()
  const {isAnonymous} = useAuth()
  const reward = response?.data

  const existingClaimData = reward?.claimed?.[0]
  const claimedData = claimMutation?.data?.data?.discount_code ?? existingClaimData
  const claimLoading = claimMutation.isLoading
  const isClaimed = Boolean(claimedData)
  const [redirectUrl, setRedirectUrl] = useState({id: '', url: ''})

  const handleClaimError = (error: any) => {
    toast.error(t(getClaimErrorKey(error)))
  }

  const handleClaim = (isAutoClaim = false, userConsent: string) => {
    if (!claimLoading && !isClaimed) {
      if (reward && isAutoClaim === false) {
        void raiseEvent({name: 'reward-claim-click', ...rewardIds(reward), view_context: 'reward-view'})
      }
      claimMutation.mutate({id: rewardId, requestId, locale, user_consent: userConsent}, {
        onError: handleClaimError,
        onSuccess: (data) => {
          if (data?.data?.discount_code?.shop_url && data?.data?.discount_code?.redirect) {
            setRedirectUrl({id: rewardId, url: data?.data?.discount_code?.shop_url})
          }
        }
      })
    }
  }

  const handleShopClick = (): void => {
    if (!reward) return

    const shopUrl = claimedData?.shop_url
    if (shopUrl) {
      void raiseEvent({
        name: 'reward-shop-now-click',
        request_id: requestId,
        ...rewardIds(reward),
        view_context: 'reward-view'
      })
      window.open(shopUrl, '_blank', 'noopener')
    }

  }

  const handleResendEmail = async () => {
    if (!reward) return
    try {
      if (!claimedData) {
        throw new Error()
      }
      void raiseEvent({
        name: 'reward-resend-email-click',
        request_id: requestId,
        ...rewardIds(reward)
      })
      await resendRewardEmail(claimedData.claim_id, requestId)
      toast.success(t('notifications.emailSent'))
    } catch (error) {
      toast.error(t('errors.generic'))
    }
  }

  const handleCopyCodeClick = () => {
    if (!reward) return

    void raiseEvent({
      name: 'reward-copy-code-click',
      request_id: requestId,
      ...rewardIds(reward)
    })
  }

  useEffect(() => {
    if (performAutoClaim.current && requestId && !isAnonymous) {
      performAutoClaim.current = false
      handleClaim(true, document.getElementById('consentData')?.innerHTML || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, isAnonymous])


  return {
    handleClaim,
    claimLoading,
    claimedData,
    handleShopClick,
    handleResendEmail,
    handleCopyCodeClick,
    reward,
    isLoading,
    isFetching,
    isClaimed,
    redirectUrl
  }
}

export default useReward

import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const EmailIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Email icon</title>
    <path d="M0 3.35999V4.7578C3.21984 7.54036 9.85539 13.2751 10.3987 13.7484C11.0756 14.3374 11.7437 14.4009 12 14.4009C12.2563 14.4009 12.9249 14.3378 13.6012 13.7494C14.1244 13.2938 20.5363 7.69603 24 4.67155V3.35999H0ZM24 5.94373C20.4422 9.04982 14.7233 14.0426 14.2303 14.4712C13.3102 15.2724 12.3638 15.359 12 15.359C11.6362 15.359 10.6898 15.2724 9.76969 14.4703C9.25945 14.0268 3.38112 8.9466 0 6.02436V20.64H24V5.94373Z" fill="currentColor" />
  </svg>
)

export default EmailIcon

import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const ReadingIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Reading icon</title>
    <g clip-path="url(#clip0_266_17127)">
    <path d="M12 0C9.3525 0 7.2 2.1525 7.2 4.8C7.2 5.31375 7.29 5.80125 7.44 6.27C9.47625 6.63937 10.9556 7.14187 11.865 7.5L12 7.545L12.135 7.5C13.0444 7.14187 14.5275 6.65437 16.56 6.285C16.7119 5.8125 16.8 5.30813 16.8 4.8C16.8 2.1525 14.6475 0 12 0ZM1.44 6.72C1.17563 6.72 0.96 6.93563 0.96 7.2V10.08H1.44C2.78625 10.08 3.84 11.1337 3.84 12.48V13.44C3.84 14.7863 2.78625 15.84 1.44 15.84H0.96V20.64C0.96 20.9062 1.17563 21.12 1.44 21.12C7.125 21.12 10.6856 23.2256 11.52 23.775V8.385C10.1213 7.83375 6.645 6.72 1.44 6.72ZM22.56 6.72C17.355 6.72 13.8787 7.83375 12.48 8.385V23.775C13.3144 23.2256 16.8769 21.12 22.56 21.12C22.8263 21.12 23.04 20.9062 23.04 20.64V15.84H22.56C21.2137 15.84 20.16 14.7863 20.16 13.44V12.48C20.16 11.1337 21.2137 10.08 22.56 10.08H23.04V7.2C23.04 6.93563 22.8263 6.72 22.56 6.72ZM0.96 11.04C0.43125 11.04 0 11.4712 0 12V13.92C0 14.4487 0.43125 14.88 0.96 14.88H1.44C2.25563 14.88 2.88 14.2556 2.88 13.44V12.48C2.88 11.6644 2.25563 11.04 1.44 11.04H0.96ZM22.56 11.04C22.4587 11.04 22.3556 11.0513 22.26 11.07C21.5944 11.2013 21.12 11.7656 21.12 12.48V13.44C21.12 14.2556 21.7444 14.88 22.56 14.88H23.04C23.5688 14.88 24 14.4487 24 13.92V12C24 11.4712 23.5688 11.04 23.04 11.04H22.56Z" fill="currentColor" />
    </g>
    <defs>
    <clipPath id="clip0_266_17127">
    <rect width="24" height="24" fill="white" />
    </clipPath>
    </defs>
  </svg>
)

export default ReadingIcon

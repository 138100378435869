export const getRewardValueText = (reward) => {
  switch (reward.value_type) {
    case 'percentage':
      return `${reward.value}%`
    case 'custom':
      return reward.custom_title
    default:
      // TODO: Handle currencies.
      return `$${reward.value}`
  }
}

export const isValidEmail = (email) => {
  // Regex is from: https://emailregex.com/
  const regex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return regex.test(email)
}

export const toLocalDateString = (value, locale) => {
  const date = new Date(value)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  // Note: if value results in an invalid date, then 'Invalid Date' will be returned.
  return date.toLocaleString(locale, options)
}
import RewardSingle from "../../components/RewardSingle";
import {useDidMount} from "rooks";

function PanelRewardRoute() {
  useDidMount(() => {
    window.scrollTo(0, 0);
  })

  return (
      <RewardSingle childApi={null} onCloseClick={null} />
  )
}

export default PanelRewardRoute

import {FunctionalComponent, h} from 'preact';
import {useTranslation} from "../../contexts/I18nContext";
import FadeIn from "../FadeIn";
import DiscountCode from "../DiscountCode";
import Button from "../Button";
import {ClaimData, Reward} from "../../interfaces/sdk";
import {useEffect, useRef, useState} from "preact/hooks";
import {OnCopyCodeClick} from "./claimCta.types";

interface Props {
  reward: Reward
  onShopClick: () => void;
  onCopyCodeClick?: OnCopyCodeClick;
  onResendEmail: () => void;
  redirectUrl: { id: string; url: string };
  claimData?: ClaimData;
  shopNowBtnLabel: string;
}

const AlreadyClaimed: FunctionalComponent<Props> = ({
                                                      reward, onShopClick, onCopyCodeClick, onResendEmail, redirectUrl,
                                                      claimData, shopNowBtnLabel,
                                                    }) => {
  const redirectLinkRef = useRef<HTMLAnchorElement>(null)
  const {t} = useTranslation()
  const [hasResentEmail, setHasResentEmail] = useState(false)

  const {claim_btn_text, claim_email_disabled} = reward

  const handleResendEmailClick = async () => {
    setHasResentEmail(true)
    onResendEmail()
  }

  useEffect(() => {
    // Safari blocks "window.open()", so trigger a click event on <a href >/ instead...
    if (redirectUrl.url && redirectUrl.id === reward.id) {
      redirectLinkRef?.current?.click()
    }
  }, [redirectUrl, reward.id])

  // TODO: We may be able to remove the custom_cta part of the fallback translation here as that functionality may have been deprecated.
  return (
      <FadeIn transitionDuration={800}>
        <DiscountCode codeType={reward.code_type} code={claimData?.code} onCopyCodeClick={onCopyCodeClick} />
        <Button fullWidth onClick={onShopClick} className="mb-3">
          {shopNowBtnLabel}
        </Button>
        {Boolean(redirectUrl.id === reward.id) && (
            <a
                ref={redirectLinkRef}
                href={redirectUrl.url}
                target="_blank"
                rel="noreferrer"
                className="d-none"
            >
              {claim_btn_text || t('reward.btn.claim')}
            </a>
        )}
        {!claim_email_disabled && (
            <p className="body4 mb-0">
              <span style={{fontSize: 13}}>{t('reward.claim.claimedSummary')}</span> <br />
              <Button
                  link
                  className="body4 link primary"
                  disabled={hasResentEmail}
                  onClick={handleResendEmailClick}
              >
                {t('reward.claim.resend')}
              </Button>
            </p>
        )}
      </FadeIn>
  )
};

export default AlreadyClaimed;


import {useEffect, useState} from "preact/hooks";
import { useIntersectionObserverRef } from "rooks";
import { ClaimContext } from "../interfaces/events";
import { Reward } from "../interfaces/sdk";
import useEvents, { rewardIds } from "./useEvents";
import useAppScrollListener from "./useAppScrollListener";
import isElementVisible from "../utils/isElementVisible";
import {injectImpressionTrackingTag} from "../utils/tracking";
import {useParams} from "react-router-dom";


export const getVisibleRewards = (rewards: Reward[]): {visibleRewards: Reward[], visibilityPercents: number[], positions: number[]} => {
  // Find rewards that are visible in viewport
  const visibleRewards = [] as Reward[]
  const visibilityPercents = [] as number[]
  const positions = [] as number[]
  rewards.forEach((reward, position) => {
    const rewardElement = document.getElementById(reward.id)
    const {isVisible, visiblePercentage} = isElementVisible(rewardElement, 1)
    if (isVisible) {
      visibleRewards.push(reward)
      visibilityPercents.push(visiblePercentage)
      positions.push(position)
    }
  })
  return {
    visibleRewards,
    visibilityPercents,
    positions,
  }
}

export const useRewardsListEvents = (isClaimed: boolean, fullList: boolean, rewards: Reward[], requestId?: string) => {
  const {rewardId} = useParams()
  const raiseEvent = useEvents()
  const [isOnScreen, setIsOnScreen] = useState(false)
  const [rewardsRef] = useIntersectionObserverRef((entries) => {
    if (entries && entries[0]) {
      setIsOnScreen(entries[0].isIntersecting);
    }
  });
  const claimContext: ClaimContext['claim_context'] = isClaimed ? 'claimed' : 'claimable'

  // Scroll rewards.
  useAppScrollListener(() => {
    if (isOnScreen && rewards.length && requestId) {
      const {visibleRewards, visibilityPercents, positions} = getVisibleRewards(rewards)
      const name = fullList ? 'rewards-all-scroll' : 'rewards-featured-scroll'

      raiseEvent({
        name,
        request_id: requestId,
        ...rewardIds(visibleRewards),
        claim_context: claimContext,
        visibility_percents: visibilityPercents,
        positions
      })
      injectImpressionTrackingTag(visibleRewards, visibilityPercents)
    }
  })

  // View all rewards.
  useEffect(() => {
    if (rewardId) return

    if (fullList && !!requestId && rewards.length > 0 && isOnScreen) {
      const {visibleRewards, visibilityPercents, positions} = getVisibleRewards(rewards)
      raiseEvent({
        name: 'rewards-all-view',
        request_id: requestId,
        ...rewardIds(visibleRewards),
        claim_context: claimContext,
        visibility_percents: visibilityPercents,
        positions
      })
      injectImpressionTrackingTag(visibleRewards, visibilityPercents)
    }
  }, [claimContext, fullList, isOnScreen, raiseEvent, requestId, rewardId, rewards])

  // View featured rewards.
  useEffect(() => {
    if (rewardId) return

    if (!fullList && !!requestId && rewards.length > 0 && isOnScreen) {
      const {visibleRewards, visibilityPercents, positions} = getVisibleRewards(rewards)
      raiseEvent({
        name: 'rewards-featured-view',
        request_id: requestId,
        ...rewardIds(visibleRewards),
        claim_context: claimContext,
        visibility_percents: visibilityPercents,
        positions
      })
      injectImpressionTrackingTag(visibleRewards, visibilityPercents)
    }
  }, [claimContext, fullList, isOnScreen, raiseEvent, requestId, rewardId, rewards])

  return [rewardsRef]
}

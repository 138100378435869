import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const GiftIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Gift icon</title>
    <g clip-path="url(#clip0_235_15395)">
    <path d="M5.90998 0.47998C4.98373 0.47998 4.29373 0.789355 3.88498 1.27498C3.47623 1.76061 3.35998 2.36436 3.35998 2.87998C3.35998 4.10623 4.02373 5.10186 4.93498 5.74498C5.21623 5.94373 5.51436 6.10123 5.83498 6.23998H0.47998V11.52H9.59998V6.71998H14.4V11.52H23.52V6.23998H18.165C18.4856 6.10123 18.7837 5.94373 19.065 5.74498C19.9762 5.10186 20.64 4.10623 20.64 2.87998C20.64 2.36436 20.5237 1.76061 20.115 1.27498C19.7062 0.789355 19.0162 0.47998 18.09 0.47998C17.1319 0.47998 16.3575 0.88498 15.78 1.43998C15.2025 1.99498 14.7881 2.69436 14.4 3.35998C14.0119 4.02561 13.6462 4.66498 13.26 5.09998C12.8737 5.53498 12.5212 5.75998 12 5.75998C11.4787 5.75998 11.1262 5.53498 10.74 5.09998C10.3537 4.66498 9.98811 4.02561 9.59998 3.35998C9.21185 2.69436 8.79748 1.99498 8.21998 1.43998C7.64248 0.88498 6.86811 0.47998 5.90998 0.47998ZM5.90998 1.43998C6.61311 1.43998 7.09311 1.69498 7.54498 2.12998C7.99686 2.56498 8.39436 3.18561 8.77498 3.83998C9.15561 4.49436 9.52873 5.17498 10.02 5.72998C10.0294 5.74123 10.0406 5.74873 10.05 5.75998H8.15998C7.19248 5.75998 6.19311 5.47123 5.47498 4.96498C4.75686 4.45873 4.31998 3.77436 4.31998 2.87998C4.31998 2.51811 4.40623 2.15998 4.61998 1.90498C4.83373 1.64998 5.18436 1.43998 5.90998 1.43998ZM18.09 1.43998C18.8137 1.43998 19.1662 1.64998 19.38 1.90498C19.5937 2.15998 19.68 2.51811 19.68 2.87998C19.68 3.77436 19.2431 4.45873 18.525 4.96498C17.8069 5.47123 16.8075 5.75998 15.84 5.75998H13.95C13.9594 5.74873 13.9706 5.74123 13.98 5.72998C14.4712 5.17498 14.8444 4.49436 15.225 3.83998C15.6056 3.18561 16.0031 2.56498 16.455 2.12998C16.9069 1.69498 17.3869 1.43998 18.09 1.43998ZM1.43998 12.48V23.52H22.56V12.48H14.4V22.56H9.59998V12.48H1.43998Z" fill="currentColor" />
    </g>
    <defs>
    <clipPath id="clip0_235_15395">
    <rect width="24" height="24" fill="white" />
    </clipPath>
    </defs>
  </svg>
)

export default GiftIcon

import {FunctionalComponent, h} from 'preact';
import RewardSingle from "../../components/RewardSingle";
import {useInContentLayoutContext} from "../../components/layouts/InContentLayout/InContentLayout";
import {globalEvent, settings} from "../../global";

const InContentRewardRoute: FunctionalComponent = (props) => {
  const {childApi} = useInContentLayoutContext()

  const onCloseClick = () => {
    if (settings.isStorefrontOverlay && childApi) {
      childApi.emit('close-gl-rewards-modal', {
        event_id_prev: globalEvent.previousEventId
      })
    }
  }

  return (
      <div>
        <RewardSingle childApi={childApi} onCloseClick={onCloseClick} />
      </div>
  );
};

export default InContentRewardRoute;


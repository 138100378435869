import {FunctionalComponent, h} from 'preact';
import Repeat from "../../Repeat";
import CardPlaceholder from "../CardPlaceholder";
import styles from './CarouselPlaceholder.style.scss'

type Props = {
  numTimes?: number
}

const CarouselPlaceholder: FunctionalComponent<Props> = ({numTimes = 10}) => {

  return (
      <div className="d-flex gap-3">
        <Repeat numTimes={numTimes}>
          {index => (
              <CardPlaceholder key={index} extraClass={styles.cardPlaceholderWrapper} />
          )}
        </Repeat>
      </div>
  );
};

export default CarouselPlaceholder;


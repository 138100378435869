import {FunctionalComponent} from 'preact';
import ClaimableRewardsSection from "../../components/sections/ClaimableRewardsSection";
import {Outlet} from "react-router-dom";

const StandAloneHomeRoute: FunctionalComponent = () => {
  return (
      <div className="container">
        <ClaimableRewardsSection />
        <Outlet />
      </div>
  )
};

export default StandAloneHomeRoute;


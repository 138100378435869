import {FunctionalComponent} from "preact"
import {useEffect, useState} from "preact/hooks"
import {useTranslation} from "../contexts/I18nContext"
import useAuth from "../hooks/useAuth"
import {isValidEmail} from "../utils/strings"
import Button from "./Button"
import TextField from "./TextField"
import {User} from "../interfaces/sdk";

type SignInFormProps = {
  ctaButtonText: string;
  onSignInAttempt?: () => void;
  onSignInSuccess?: (user: User) => void;
  onSignInSettle?: () => void;
  onSignInFail?: (error: string) => void;
  newsletterMannerOfConsent: string;
  from?: 'claim';
}

const SignInForm: FunctionalComponent<SignInFormProps> = ({
                                                            ctaButtonText,
                                                            onSignInAttempt,
                                                            onSignInSuccess,
                                                            onSignInSettle,
                                                            onSignInFail,
                                                            newsletterMannerOfConsent,
                                                            from,
                                                          }) => {
  const [isLoading, setIsLoading] = useState(false)
  const {signIn} = useAuth()
  const {t} = useTranslation()
  const [email, setEmail] = useState<string>('')
  const [emailTouched, setEmailTouched] = useState(false)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)

  useEffect(() => {
    document.body.classList.add('show-recaptcha')
    return () => {
      document.body.classList.remove('show-recaptcha')
    }
  }, [])

  useEffect(() => {
    if (emailTouched) {
      setEmailError(
          isValidEmail(email) ? undefined : t('errors.invalidEmail')
      )
    }
  }, [email, emailTouched, t])

  const handleConnectClick = async () => {
    if (!isValidEmail(email)) {
      setEmailTouched(true)
    } else {
      setIsLoading(true)
      if (onSignInAttempt) {
        onSignInAttempt()
      }

      signIn({email, newsletterMannerOfConsent, fallbackToAnonymousWhenFail: false})
          .then((user) => {
            if (onSignInSuccess) {
              onSignInSuccess(user as unknown as User)
            }
          })
          .catch((error) => {
            if (onSignInFail) {
              onSignInFail(error)
            }
          })
          .finally(() => {
            if (onSignInSettle) {
              onSignInSettle()
            }
            setIsLoading(false)
          })
    }
  }

  return (
      <div id="signin-form">
        <TextField
            id="email"
            value={email}
            disabled={isLoading}
            onChange={(e: any) => setEmail(e.target.value)}
            placeholder={t('field.email.placeholder')}
            aria-required={true}
            onAccept={handleConnectClick}
            error={emailError}
            type="email"
        />
        <Button
            fullWidth
            secondary
            disabled={!email || Boolean(emailError)}
            onClick={handleConnectClick}
            isLoading={isLoading}
        >
          {ctaButtonText}
        </Button>
      </div>
  )
}

export default SignInForm

import {TranslationKey} from "../contexts/I18nContext";

const claimErrorCodes = [
    'RewardDailyClaimPerApplicationLimitError',
    'RewardWeeklyClaimPerApplicationLimitError',
    'RewardClaimedMoreThanOnceError',
    'RewardAlreadyClaimedTodayError',
    'UserRegistrationRequiredError',
] as const
type ClaimErrorCode = typeof claimErrorCodes[number]

export const getClaimErrorKey = (error: any): TranslationKey => {
  const claimErrorCode = error.response?.data?.name as ClaimErrorCode

  if (claimErrorCodes.includes(claimErrorCode)) {
    return `apiErrorCodes.claim.${claimErrorCode}`
  }
  return `apiErrorCodes.claim.ClaimError`
}

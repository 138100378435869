import Button from './Button'
import {copyToClipboard} from '../utils'
import {FunctionalComponent} from 'preact'
import {useTranslation} from '../contexts/I18nContext'
import toast from 'react-hot-toast'
import clsx from 'clsx'
import styles from './DiscountCode.style.scss'
import {useSettingsContext} from "../contexts/SettingsContext";
import {OnCopyCodeClick} from "./claimCta/claimCta.types";

type Props = {
  code?: string;
  redact?: boolean;
  codeType?: string;
  onCopyCodeClick?: OnCopyCodeClick;
}

const DiscountCode: FunctionalComponent<Props> = ({
                                                    code = 'XXXXXXX',
                                                    codeType = 'none',
                                                    redact = false,
                                                    onCopyCodeClick
                                                  }) => {
  const {t} = useTranslation()
  const {layout} = useSettingsContext()

  const handleCopyClick = async () => {
    const success = await copyToClipboard(code);
    if (typeof onCopyCodeClick === 'function') {
      onCopyCodeClick(success)
    }
    if (success) {
      toast.success(t('notifications.codeCopied'))
    } else {
      toast.error(t('errors.generic'))
    }
  }

  if (codeType === 'generic' || codeType === 'unique') {
    return (
        <>
          {redact ? (
              <>
                {layout !== 'carousel' &&
                  <div className={clsx([styles.panel, 'mb-4'])}>
                    <div className={clsx([styles.codeWrapper, 'body3'])}>
                      <strong>
                        <span className={styles.redact}> {code}</span>
                      </strong>
                    </div>
                    <div className={styles.copyButtonWrapper}>
                      <Button
                        secondary
                        className={styles.copyButton}
                        onClick={handleCopyClick}
                        disabled
                      >
                        {t('reward.claim.copy')}
                      </Button>
                    </div>
                  </div>
                }
              </>
          ) : (
              <div className={clsx([styles.panel, 'mb-4'])}>
                <div className={clsx([styles.codeWrapper, 'body3'])}>
                  <strong>
                    <span> {code}</span>
                  </strong>
                </div>
                <div className={styles.copyButtonWrapper}>
                  <Button
                      secondary
                      className={styles.copyButton}
                      onClick={handleCopyClick}
                  >
                    {t('reward.claim.copy')}
                  </Button>
                </div>
              </div>
          )}
        </>
    )
  }
  return (
      <>
        {!redact &&
          <p className="body3 mb-4">
            {t('reward.claim.noCodeRequired')}
          </p>
        }
      </>
  )
}

export default DiscountCode

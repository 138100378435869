import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const NavLeftIcon: FunctionalComponent<Props> = (props) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M15 0.280029C6.88253 0.280029 0.280029 6.88253 0.280029 15C0.280029 23.1175 6.88253 29.72 15 29.72C23.1175 29.72 29.72 23.1175 29.72 15C29.72 6.88253 23.1175 0.280029 15 0.280029ZM17.3725 20.9475C17.6225 21.1975 17.6225 21.6025 17.3725 21.8525C17.2475 21.9775 17.085 22.04 16.92 22.04C16.755 22.04 16.5925 21.9775 16.4675 21.8525L10.0675 15.4525C9.81753 15.2025 9.81753 14.7975 10.0675 14.5475L16.4675 8.14753C16.7175 7.89753 17.1225 7.89753 17.3725 8.14753C17.6225 8.39753 17.6225 8.80253 17.3725 9.05253L11.425 15L17.3725 20.9475Z"
          fill="#595959" />
    </svg>


)

export default NavLeftIcon

import clsx from "clsx"
import styles from './TextField.style.scss'
import { Fragment, FunctionalComponent, h, VNode } from "preact"

type AdornmentProps = h.JSX.HTMLAttributes<HTMLDivElement>

export const Adornment: FunctionalComponent<AdornmentProps> = ({ children, className, ...props }) => {
  return (
    <div className={clsx([styles.inputAdornment, className])} {...props}>
      {children}
    </div>  
  )
}

type Props = h.JSX.HTMLAttributes<HTMLDivElement> & {
  id: string;
  error?: string;
  startAdornment?: VNode;
  endAdornment?: VNode;
  onAccept?: () => void;
}

// TODO: Improve text field functionality to support visible labels and icons.
const TextField: FunctionalComponent<Props> = ({
  id,
  className,
  error,
  startAdornment,
  endAdornment,
  onAccept,
  ...props
}) => {
  const {label, placeholder} = props
  const hasError = Boolean(error)

  const handleKeyPress = (e: KeyboardEventInit) => {
    if (onAccept && e.key === 'Enter') {
      onAccept()
    }
  }

  return (
    <Fragment>
      <div className={clsx([styles.inputRoot, { [styles.error]: hasError }])}>
        {startAdornment}
        <label
          for={id}
          className='visually-hidden'
        >
          {label ?? placeholder}
        </label>
        <input
          id={id}
          className={clsx([className, styles.textInput])}
          aria-invalid={hasError}
          onKeyPress={handleKeyPress}
          type="text"
          {...props}
        />
        {endAdornment}
      </div>
      {hasError && (
        <p role="alert" className={styles.errorMsg}>{error}</p>
      )}
    </Fragment>
  )
}

export default TextField
import { useEffect, useState } from 'preact/hooks'
import styles from './LoadingSpinner.style.scss'

function LoadingSpinner ({ children, loading, delay = 250 }) {
  const [showSpiner, setShowSpinner] = useState(loading)

  useEffect(() => {
    if (showSpiner !== loading) {
      const id = setTimeout(() => setShowSpinner(loading), loading ? delay : 0)
      return () => clearTimeout(id)
    }
  }, [loading, delay, showSpiner])

  return showSpiner
    ? (
      <div class={styles['lds-ellipsis']}>
        <div /><div /><div /><div />
      </div>
      )
    : children
}

export default LoadingSpinner

import {h} from "preact";
import styles from "./FlippingCard.style.scss";
import clsx from "clsx";
import styled, {keyframes} from 'styled-components';

interface CardProps {
  frontContent: JSX.Element;
  backContent: JSX.Element;
  flipped: boolean;
  id: string;
}

const showBackCardAnim = keyframes`${{
  from: {
    opacity: 0,
    transform: "translate3d(0, 10%, 0)",
  },
  to: {
    opacity: 1,
    transform: 'none'
  }
}}`;

const BackCardDiv = styled.div`
  animation: 0.6s ${showBackCardAnim};
`;

function FlippingCard({frontContent, backContent, flipped, id}: CardProps) {

  return (
      <div id={id} className={clsx(styles.card, {
        [styles.flipped]: flipped
      })}
      >
        {flipped ? (
            <BackCardDiv>{backContent}</BackCardDiv>
        ) : (
            <div>{frontContent}</div>
        )}
      </div>
  );
}

export default FlippingCard

import {useCallback} from "preact/hooks";
import {globalEvent, pageInfo} from "../global";
import {Reward} from "../interfaces/sdk";
import {useSettingsContext} from "../contexts/SettingsContext";
import useEvents, {rewardIds} from "./useEvents";
import {useInContentLayoutContext} from "../components/layouts/InContentLayout/InContentLayout";
import isElementVisible from "../utils/isElementVisible";
import * as panelRoutes from "../appRouter/routesPaths/panel";
import * as standaloneRoutes from "../appRouter/routesPaths/standalone";
import {useNavigate} from "react-router-dom";

const MINIMUM_VISIBILITY_PERCENT_TO_OPEN = 70

const useOpenReward = () => {
  const {
    storeFrontType,
    integrationType,
    activeIntegration,
    publisherDomain,
    appSlug,
    layout,
    context,
    isStandaloneApp
  } = useSettingsContext()
  const raiseEvent = useEvents()
  const {childApi, setOpenedRewardId} = useInContentLayoutContext()
  const navigate = useNavigate()

  const raiseRewardTileClickEvent = useCallback((reward: Reward) => {
    raiseEvent({
      name: 'rewards-tile-click',
      request_id: '',
      claim_context: reward.claimed ? 'claimed' : 'claimable',
      ...rewardIds(reward)
    })
  }, [raiseEvent])

  const handleOpenReward = useCallback((reward: Reward, claimId: string) => {
    const {isVisible} = isElementVisible(document.getElementById(reward.id), MINIMUM_VISIBILITY_PERCENT_TO_OPEN)
    raiseRewardTileClickEvent(reward)

    if (storeFrontType === 'overlay') {
      setOpenedRewardId(reward.id)

      const heroImage = reward.hero_image || reward.brand_email_banner
      const searchParams = new URLSearchParams({
        publisher_domain: publisherDomain,
        appname: appSlug,
        integration_type: integrationType || '',
        layout: layout || '',
        integration_id: activeIntegration?.id || '',
        app_view_id: globalEvent.appViewId || '',
        event_id_prev: globalEvent.previousEventId || '',
        hero_image: heroImage,
        is_storefront_overlay: 'true',
        page_info_url: pageInfo.url
      })
      if (integrationType === 'modal') {
        childApi.emit('hide-modal-carousel', null)
      }
      childApi.emit('open-reward-modal', {
        href: `${window.location.origin}/in-content/reward/${reward.id}/-/${claimId}?${searchParams.toString()}`,
        rewardId: reward.id,
        rewardName: reward.name,
        heroImage,
        integrationId: activeIntegration?.id || ''
      })
    }

    if (layout === 'carousel' && isVisible) {
      setOpenedRewardId(reward.id)
    }

    if (integrationType === 'panel') {
      const to = panelRoutes.rewardSingle({
        id: reward.id,
        requestId: '-',
        claimId,
      })
      navigate(to)
    }

    if (isStandaloneApp) {
      const to = standaloneRoutes.rewardSingle({
        appSlug,
        context,
        rewardId: reward.id,
        claimId,
      })
      navigate(to)
    }

  }, [activeIntegration?.id, appSlug, childApi, context, integrationType, isStandaloneApp, layout, navigate, publisherDomain, raiseRewardTileClickEvent, setOpenedRewardId, storeFrontType])

  return {
    handleOpenReward,
  }
}

export default useOpenReward

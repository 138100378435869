import {Children, useState} from "preact/compat";
import {useEffect} from "preact/hooks";
import {ComponentType, FunctionalComponent} from "preact";
import {JSXInternal} from "preact/src/jsx";

interface Props {
  delay?: number;
  transitionDuration?: number;
  wrapperTag?: ComponentType | keyof JSXInternal.IntrinsicElements;
  childTag?: ComponentType | keyof JSXInternal.IntrinsicElements;
  className?: string;
  childClassName?: string;
  visible?: boolean;
  onComplete?: () => any;
}

const FadeIn: FunctionalComponent<Props> = (props) => {
  const [maxIsVisible, setMaxIsVisible] = useState(0);
  const transitionDuration = typeof props.transitionDuration === "number" ? props.transitionDuration : 400;
  const delay = typeof props.delay === "number" ? props.delay : 50;
  const WrapperTag = props.wrapperTag || "div";
  const ChildTag = props.childTag || "div";
  const visible = typeof props.visible === "undefined" ? true : props.visible;

  useEffect(() => {
    let count = Children.count(props.children);
    if (!visible) {
      // Animate all children out
      count = 0;
    }

    if (count == maxIsVisible) {
      // We're done updating maxVisible, notify when animation is done
      const timeout = setTimeout(() => {
        if (props.onComplete) props.onComplete();
      }, transitionDuration);
      return () => clearTimeout(timeout);
    }

    // Move maxIsVisible toward count
    const increment = count > maxIsVisible ? 1 : -1;
    const timeout = setTimeout(() => {
      setMaxIsVisible(maxIsVisible + increment);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay, maxIsVisible, visible, transitionDuration, props]);

  return (
      <WrapperTag className={props.className}>
        {Children.map(props.children, (child, i) => {
          return (
              <ChildTag
                  className={props.childClassName}
                  style={{
                    transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
                    transform: maxIsVisible > i ? "none" : "translateY(20px)",
                    opacity: maxIsVisible > i ? 1 : 0,
                  }}
              >
                {child}
              </ChildTag>
          );
        })}
      </WrapperTag>
  );
}

export default FadeIn

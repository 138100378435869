import {FunctionalComponent, h} from 'preact';
import DOMPurify from "dompurify";
import styles from './AffiliateDisclosure.styles.scss'
import {useSettingsContext} from "../contexts/SettingsContext";
import useAppPhrases from "../hooks/useAppPhrases";

type Props = {
  rewardsAmount: number
}

const AffiliateDisclosure: FunctionalComponent<Props> = ({rewardsAmount}) => {
  const {getIntegrationOrAppSetting} = useSettingsContext()
  const tAppPhrases = useAppPhrases()
  const disclosureText = rewardsAmount > 1 ? tAppPhrases('affiliate_disclosure_multiple_offers') : tAppPhrases('affiliate_disclosure_single_offer')

  if (getIntegrationOrAppSetting('affiliate_disclosure_disabled')) return null

  return (
      <div className={styles.AffiliateDisclosure}
           dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(disclosureText)}}
      />
  );
};

export default AffiliateDisclosure;


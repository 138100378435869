import {Fragment, h} from 'preact'
import styles from './QubErrorPage.styles.scss'
import ErrorImg from './ErrorImg'
import Header from './Header'
import Footer from './Footer'
import {ErrorPageComponent} from '../../StandAloneErrorPage'
import {useQubProperty} from './qubProperties'
import {useSettingsContext} from "../../../../contexts/SettingsContext";
import {useRouteError} from "react-router-dom";

const QubErrorPage: ErrorPageComponent = () => {
  const {application, emailParam} = useSettingsContext()
  const property = useQubProperty()
  const error = useRouteError() as { status: number, statusText: string } || {};

  const invalidEmail = error.statusText === '401-invalid-email'
  const unauthorized = error.status === 401

  return (
      <div className={styles.pageWrapper}>
        <Header appInfo={property} />

        <div className={styles.errorPage}>
          {!unauthorized && !invalidEmail && (
              <Fragment>
                <ErrorImg className={styles.errorImage} />
                <p>Oups! Cette page n'est pas disponible.</p>
                <p className={styles.subMessage}>Erreur 404 - fichier introuvable</p>
              </Fragment>
          )}
          {invalidEmail && (
              <Fragment>
                <p>{emailParam} semble être un courriel invalide. Veuillez réessayer.</p>
              </Fragment>
          )}
          {unauthorized && !invalidEmail && <p>Veuillez vous connecter pour réclamer vos prochaines récompenses.</p>}


          <div style={{margin: '2em'}}>
            <a href={property.app_website_url} className={styles.btnLink}>
              Visiter {property.name}
            </a>
          </div>
          <div className={styles.footerWrapper}>
            {application && <Footer appInfo={application} />}
          </div>
        </div>
      </div>
  )
}

export default QubErrorPage

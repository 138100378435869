import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const ProfileIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Profile icon</title>
    <path d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12 4.75C13.795 4.75 15.25 6.205 15.25 8C15.25 9.795 13.795 11.25 12 11.25C10.205 11.25 8.75 9.795 8.75 8C8.75 6.205 10.205 4.75 12 4.75ZM12 20C9.562 20 7.379 18.909 5.912 17.188C5.531 16.741 5.616 16.07 6.085 15.717C7.602 14.576 10.366 14 12 14C13.634 14 16.398 14.576 17.916 15.717C18.385 16.07 18.47 16.742 18.089 17.188C16.621 18.909 14.438 20 12 20Z" fill="currentColor" />
  </svg>
)

export default ProfileIcon

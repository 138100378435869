import {FunctionalComponent, h, VNode} from 'preact';
import styles from './Section.styles.scss'
import clsx from "clsx";

interface Props extends Omit<h.JSX.HTMLAttributes<HTMLElement>, 'icon'> {
  title?: string;
  titleClass?: string;
  contentSpacing?: 0 | 1 | 2 | 3;
  summary?: string;
  icon?: VNode;
  textCenter?: boolean;
  reducePadding?: boolean;
  extraClass?: string;
  isBolderTitle?: boolean;
}

const Section: FunctionalComponent<Props> = ({
  children,
  title,
  titleClass,
  contentSpacing = 1,
  summary,
  icon,
  textCenter = false,
  reducePadding = false,
  extraClass,
  isBolderTitle = false,
  ...props
}) => {
  const spacingClass = `mb-${contentSpacing}`
  const titleMargin = summary ? 'mb-1' : spacingClass

  return (
    <section className={clsx(styles.section, extraClass, {'text-center': textCenter, [styles.reducePadding]: reducePadding}, )} {...props}>
      {icon}
      {Boolean(title) && (
          <h5 className={clsx('h6', titleClass, titleMargin, {
            bold: !isBolderTitle,
            [styles.bolderTitle]: isBolderTitle
          })}>
            {title}
          </h5>
      )}
      {Boolean(summary) && (
        <p className={`body4 ${spacingClass}`}>{summary}</p>
      )}
      {children}
    </section>
  );
}

export default Section;


import {FunctionalComponent, h} from 'preact';
import styles from './RichText.style.scss'
import clsx from 'clsx'
import DOMPurify from 'dompurify';

interface Props {
  text: string;
  className?: string;
}

const RichText: FunctionalComponent<Props> = ({text, className = '', ...props}) => {
  return (
  <div className={clsx(styles.richText, className)} {...props} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text, { ADD_ATTR: ['target'] })}} />
)};

export default RichText;


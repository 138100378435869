import './styles/animate.min.css'
import {useDocumentEventListener} from 'rooks'
import {APP_SCROLL_EVENT_NAME} from './hooks/useAppScrollListener'
import {RouterProvider,} from "react-router-dom";
import router from "./appRouter/appRouter";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  useDocumentEventListener('scroll', (e) => {
    document.dispatchEvent(new CustomEvent(APP_SCROLL_EVENT_NAME, {detail: {originalEvent: e}}))
  })

  return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RouterProvider router={router} />
      </QueryClientProvider>

  )
}
export default App

import {FunctionalComponent, h} from 'preact';
import styles from './TermsAndConditions.style.scss'
import useTranslation from "../hooks/useTranslation";
import RichText from './RichText';

interface Props {
  url: string;
}

const TermsAndConditions: FunctionalComponent<Props> = ({url}) => {
  const {t} = useTranslation()
  return (
    <div className={styles.terms}>
      {url ? (
          <RichText
            className={styles.terms}
            text={t('reward.terms.legalese.withLink', { href: url })}
          />
        ) : (
         t('reward.terms.legalese.withoutLink')
        )
      }
    </div>
  )
};

export default TermsAndConditions;


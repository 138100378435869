import { FunctionalComponent, h, Fragment } from 'preact'
import style from './Header.styles.scss'
import { AppInfo } from '../../interfaces/publishers'
import hardFestivalLogo from './publishers/hardfestival/assets/logo.png'
import clsx from 'clsx'
import {useThemeContext} from "../../contexts/themeContext/ThemeContext";

export interface HeaderProps {
  appInfo: Pick<AppInfo, 'name' | 'app_website_url' | 'logo' | 'slug'>
}

export type HeaderComponent = FunctionalComponent<HeaderProps>

const Header: HeaderComponent = ({ appInfo }) => {
  const {headerBgColor} = useThemeContext()

  return (
    <header
      class={clsx(style.header, {
        [style.hardFestivalHeader]: appInfo.slug === 'hardfestival',
      })}
      style={{backgroundColor: headerBgColor || ''}}
    >
      {appInfo.app_website_url && appInfo.name && (
        <Fragment>
          {appInfo.slug === 'hardfestival' ? (
            <a href={appInfo.app_website_url}>
              <img src={hardFestivalLogo} className={style.hardFestivalLogo} alt={appInfo.name} />
            </a>
          ) : (
              <a href={appInfo.app_website_url}>
                <img src={appInfo.logo} className={style.img} alt={appInfo.name} />
              </a>
          )}
        </Fragment>
      )}
    </header>
  )
}

export default Header

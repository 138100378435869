import Polyglot, {PolyglotOptions} from 'node-polyglot'
import { createContext } from 'preact/compat'
import { useContext, useEffect, useMemo, useState } from 'preact/hooks'
import { ComponentChildren } from 'preact'
import en from '../i18n/en.json'
import fr from '../i18n/fr.json'
import { getBrowserLanguages } from '../utils'
import { setAuthAxiosLangHeader } from '../services/auth'
import { safeLocalStorage } from '../utils/storage'
import {useSettingsContext} from "./SettingsContext";

/****
 * These type helpers allow us to generage a union type of all valid translation keys directly
 * from the imported babel json file. Code is adapted from this stack overflow post:
 * https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
 */
type Join<K, P> = K extends string | number ?
    P extends string | number ?
    `${K}${"" extends P ? "" : "."}${P}`
    : never : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";
/*********/

// Union type of all valid translation keys.
export type TranslationKey = Leaves<typeof en>

type PhraseItem = {
  [key: string]: PhraseItem | string;
}

type I18nProviderProps = {
  children: ComponentChildren | ((lang: string) => ComponentChildren);
}

type TranslationFn<T extends string = string> = ((phrase: T, options?: number | Polyglot.InterpolationOptions | undefined) => string) & {
  _polyglot: Polyglot;
}

type I18nContextValue = {
  t: TranslationFn<TranslationKey>;
  locale: string;
  setLocale: (locale: string) => void;
  setLocaleSupport: (langs: string[], defaultLang: string) => void;
}

const phraseData = { en, fr }

const I18nContext = createContext<I18nContextValue | undefined>(undefined)

const useLocale = (languages: string[], fallbackLanguage = 'en') => {
  const {application} = useSettingsContext()
  const [locale, setLocale] = useState(fallbackLanguage)
  const [supported, setSupported] = useState({
    languages,
    defaultLanguage: fallbackLanguage
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const lang = params.get('language') || params.get('lang')
    const appMainLanguage = application.languages.length === 1 ? application.languages[0] : ''

    // Establish language fallback priority
    const priority = [lang, appMainLanguage, ...getBrowserLanguages(), supported.defaultLanguage]
    const result = priority.find((v) => v && supported.languages.includes(v))

    setLocale(result || fallbackLanguage)
  }, [supported, fallbackLanguage, application.languages])

  useEffect(() => {
    if (locale) {
      safeLocalStorage.setItem('lang', locale)
    }
  }, [locale])

  const setLocaleSupport = useMemo(
    () => (languages: string[], defaultLanguage: string) =>
      setSupported({ languages, defaultLanguage }),
    [setSupported],
  )

  useEffect(() => {
    if (application) {
      setLocaleSupport(application.languages, '')
    }
  }, [application, setLocaleSupport])

  return {
    locale,
    setLocale,
    setLocaleSupport
  }
}

export const usePolyglot = <K extends string = string,>(
  phraseData: PhraseItem,
  locale: string,
  options: PolyglotOptions = {}
): TranslationFn<K> => {
  const t = useMemo(() => {
    const polyglot = new Polyglot({
      phrases: phraseData[locale as keyof typeof phraseData] || null,
      locale,
      interpolation: {
        prefix: '{{',
        suffix: '}}',
      },
      ...options
    })
    const t = polyglot.t.bind(polyglot) as typeof polyglot.t
    ;(t as TranslationFn)._polyglot = polyglot

    return t as TranslationFn<K>
  }, [phraseData, locale, options])

  return t
}

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const {application} = useSettingsContext()
  const { locale, setLocale, setLocaleSupport } = useLocale(application.languages, 'en')

  const t = usePolyglot<TranslationKey>(phraseData, locale)

  useEffect(() => {
    setAuthAxiosLangHeader(locale)
  }, [locale])

  return (
    <I18nContext.Provider
      value={{
        t,
        locale,
        setLocale,
        setLocaleSupport,
      }}
    >
      {typeof children === 'function' ? children(locale) : children}
    </I18nContext.Provider>
  )
}

export const useTranslation = () => {
  const context = useContext(I18nContext)
  if (context === undefined) {
    throw new Error('I18nContext must be used within an I18nProvider')
  }
  return context
}

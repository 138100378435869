import {Fragment, FunctionalComponent, h} from 'preact';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import useTranslation from "../hooks/useTranslation";
import {useSettingsContext} from "./SettingsContext";

// This component is to make sure that the rest of the application is not rendered until the executeRecaptcha function is ready
const RecaptchaControl: FunctionalComponent = ({children}) => {
  const {executeRecaptcha} = useGoogleReCaptcha()

  if (!executeRecaptcha) {
    return null
  }

  return (
      <Fragment>{children}</Fragment>
  )
}


const GoogleRecaptchaWithAppSettings: FunctionalComponent = ({children}) => {
  const {locale} = useTranslation()
  const {application} = useSettingsContext()

  if (!application.recaptcha_login_enabled) {
    return <Fragment>{children}</Fragment>
  }

  return (
      <GoogleReCaptchaProvider
          reCaptchaKey={process.env.PREACT_APP_RECAPTCHA_KEY || ''}
          language={locale}
          useRecaptchaNet={false}
          useEnterprise
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined
          }}
      >
        <RecaptchaControl>{children}</RecaptchaControl>
      </GoogleReCaptchaProvider>
  );
};

export default GoogleRecaptchaWithAppSettings;


import {FunctionalComponent} from 'preact'
import {ClaimData, Reward} from '../../interfaces/sdk'
import useAuth from '../../hooks/useAuth'
import {TranslationKey, useTranslation} from '../../contexts/I18nContext'
import {useSettingsContext} from "../../contexts/SettingsContext";
import useAppLocale from '../../hooks/useAppLocale'
import DOMPurify from 'dompurify'
import AnonymousClaimable from "./AnonymousClaimable";
import AlreadyClaimed from "./AlreadyClaimed";
import Claimable from "./Claimable";
import {OnClaimClicked, OnCopyCodeClick, PrivacyTextData} from "./claimCta.types";
import useUserProfileQuery from "../../hooks/queries/useUserProfileQuery";
import Button from '../Button';
import FadeIn from '../FadeIn';

interface Props {
  reward: Reward;
  claimLoading: boolean;
  claimData?: ClaimData;
  redirectUrl: { id: string; url: string };
  onClaimClicked: OnClaimClicked
  onShopClick: () => void;
  onResendEmail: () => void;
  onCopyCodeClick?: OnCopyCodeClick;
}

const ClaimCta: FunctionalComponent<Props> = ({
                                                reward, claimLoading, claimData, onClaimClicked, onShopClick,
                                                onResendEmail, onCopyCodeClick, redirectUrl,
                                              }) => {
  const {
    brand_name,
    contact_info_url,
    privacy_policy_url,
    shop_now_btn_text,
    custom_cta,
  } = reward
  const {application, disableEmailSignIn} = useSettingsContext()
  const {isAnonymous} = useAuth()
  const {t} = useTranslation()
  const isClaimed = Boolean(claimData)
  const tAppLocale = useAppLocale()
  const appPrivacyUrl = tAppLocale('privacy_policy_url')
  const {progress} = useUserProfileQuery()

  const textData: PrivacyTextData = {
    appName: application.name,
    appPrivacyUrl,
    brandPrivacyUrl: privacy_policy_url,
    brandName: brand_name,
    contactInfoUrl: contact_info_url,
  }

  // TODO: We may be able to remove the custom_cta part of the fallback translation here as that functionality may have been deprecated.
  const shopNowBtnLabel = shop_now_btn_text || t(`reward.btn.${custom_cta || 'shop_now'}` as TranslationKey)

  if (!progress) return null

  const canClaim = !progress.claimLimitReached

  if (disableEmailSignIn) {
    return (
        <FadeIn transitionDuration={800}>
          <Button fullWidth onClick={onShopClick}>
            {shopNowBtnLabel}
          </Button>
        </FadeIn>
    )
  }

  if (isClaimed) {
    return (
        <AlreadyClaimed
            reward={reward}
            onShopClick={onShopClick}
            onCopyCodeClick={onCopyCodeClick}
            onResendEmail={onResendEmail}
            redirectUrl={redirectUrl}
            claimData={claimData}
            shopNowBtnLabel={shopNowBtnLabel}
        />
    )
  } else if (canClaim && isAnonymous) {
    if (reward.anonymous_claim_allowed && !reward.email_required) return (
        <Claimable
            reward={reward}
            textData={textData}
            claimLoading={claimLoading}
            onClaimClicked={onClaimClicked}
        />
    )
    return (
        <AnonymousClaimable
            reward={reward}
            onClaimClicked={onClaimClicked}
            textData={textData}
        />
    )
  } else if (canClaim) {
    return (
        <Claimable
            reward={reward}
            textData={textData}
            claimLoading={claimLoading}
            onClaimClicked={onClaimClicked}
        />
    )
  }
  return (
      <p className="mb-0"
         dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t('reward.claim.unavailable'))}}
      />
  )
}

export default ClaimCta

import { useContext } from 'preact/hooks'
import AuthContext from '../contexts/AuthContext'

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('AuthContext must be used within an AuthProvider')
  }
  return context
}

export default useAuth
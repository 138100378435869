import {createBrowserRouter} from "react-router-dom";
import PanelHomeRoute from "./routes/PanelHomeRoute";
import PanelLayout from "../components/layouts/PanelLayout";
import PanelRewardRoute from "./routes/PanelRewardRoute";
import InContentHomeRoute from "./routes/InContentHomeRoute";
import RootLayout from "../components/layouts/RootLayout";
import InContentLayout from "../components/layouts/InContentLayout";
import ErrorPage from "../components/common/ErrorPage";
import StandAloneLayout from "../components/layouts/StandAloneLayout";
import StandAloneHomeRoute from "./routes/StandAloneHomeRoute";
import {getHostAppSlug} from "../utils";
import {I18nProvider} from "../contexts/I18nContext";
import SettingsProvider from "../contexts/SettingsContext";
import StandAloneErrorPage from "../components/standalone/StandAloneErrorPage";
import {AuthProvider} from "../contexts/AuthContext";
import StandAloneRewardRoute from "./routes/StandAloneRewardRoute";
import InContentRewardRoute from "./routes/InContentRewardRoute";
import GoogleRecaptchaWithAppSettings from "../contexts/GoogleRecaptchaWithAppSettings";
import ThemeProvider from "../contexts/themeContext/ThemeContext";
import ClaimRoute from "./routes/ClaimRoute";

const hostAppSlug = getHostAppSlug()

export const routes = [
  {
    path: '/',
    element: (
        <SettingsProvider>
          <I18nProvider>
            <GoogleRecaptchaWithAppSettings>
              <ThemeProvider>
                <RootLayout />
              </ThemeProvider>
            </GoogleRecaptchaWithAppSettings>
          </I18nProvider>
        </SettingsProvider>
    ),
    errorElement: (
        <ErrorPage />
    ),
    children: [
      {
        path: hostAppSlug ? ':context?' : ':appSlug/:context?',
        element: <AuthProvider><StandAloneLayout /></AuthProvider>,
        errorElement: (
            <StandAloneErrorPage />
        ),
        children: [
          {
            path: '',
            element: <StandAloneHomeRoute />,
            children: [
              {
                path: 'rewards/:rewardId/:claimId?',
                element: <StandAloneRewardRoute />,
              },
            ]
          }
        ]
      },
      {
        path: 'panel',
        element: <AuthProvider><PanelLayout /></AuthProvider>,
        children: [
          {
            path: '',
            element: <PanelHomeRoute />,
          },
          {
            path: 'reward/:rewardId/:requestId?/:claimId?',
            element: <PanelRewardRoute />,
          },
        ],
      },
      {
        path: 'in-content',
        element: <AuthProvider><InContentLayout /></AuthProvider>,
        children: [
          {
            path: '',
            element: <InContentHomeRoute />
          },
          {
            path: 'reward/:rewardId/:requestId?/:claimId?',
            element: <InContentRewardRoute />
          },
        ]
      }
    ]
  },
  {
    path: '/claim/:rewardId',
    element: <ClaimRoute />
  },
]

const router = createBrowserRouter(routes);

export default router

import useUserProfileQuery from "./queries/useUserProfileQuery";
import {useMemo} from "preact/hooks";
import {isDateAfter} from "../utils";
import useTranslation from "./useTranslation";
import {useSettingsContext} from "../contexts/SettingsContext";
import {AppInfo} from "../interfaces/publishers";
import usePhrases from "./usePhrases";

const useHomePageTitle = (): string => {
  const {application} = useSettingsContext()
  const {progress} = useUserProfileQuery()
  const {t} = useTranslation()
  const tPhrases = usePhrases()

  return useMemo(() => {
    if (!progress) {
      return ''
    }

    if (
        typeof progress.latestActivity?.completion_message === 'string' &&
        isDateAfter(
            progress.latestActivity.completion_date,
            progress.latestClaimDate || 0,
        )
    ) {
      return progress.latestActivity.completion_message
    }

    if (progress.remainingThisWeek <= 0) {
      const day = application.weekly_start_day.toLowerCase() as AppInfo['weekly_start_day']
      const weeklyStartDay = t(`common.day.${day}`)
      const options = {day: weeklyStartDay}

      return tPhrases('weekly_limit_reached', options)
    }

    if (progress.remainingToday <= 0) {
      return tPhrases('daily_limit_reached')
    }

    switch (true) {
      case progress.remaining === Infinity:
        return tPhrases('unlimited_claims')
      case progress.remaining === 1:
        return tPhrases('one_available_claim')
      default: {
        const options = {count: progress.remaining}
        return tPhrases('available_claims', options)
      }
    }

  }, [application.weekly_start_day, progress, t, tPhrases])
}

export default useHomePageTitle

import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const FacebookIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Facebook icon</title>
    <path d="M17.525 9.00006H14V7.00006C14 5.96806 14.084 5.31806 15.563 5.31806H17.431V2.13806C16.522 2.04406 15.608 1.99806 14.693 2.00006C11.98 2.00006 10 3.65706 10 6.69906V9.00006H7V13.0001L10 12.9991V22.0001H14V12.9971L17.066 12.9961L17.525 9.00006Z" fill="currentColor" />
  </svg>

)

export default FacebookIcon

import {EventType, EventIdentity} from "../interfaces/events"
import {safeLocalStorage} from "../utils/storage";
import {authAxios} from "./auth"

type EventData = EventIdentity & {
  name: EventType["name"];
  source: 'gl-web-sdk';
  data: Omit<EventType, "name">;
  conversion: boolean;
  location: string | undefined;
  init_source?: string;
  tags?: string;
}

export const sendEvent = async (data: EventData) => {
  try {
    // For debug logging of events.
    if (safeLocalStorage.getItem('log-events')) {
      console.info('Event:', data.name, {payload: data})
    }

    const {data: responseData} = await authAxios({
      method: 'post',
      url: 'events/log',
      data,
    })

    return responseData.status === 200
  } catch (error) {
    return false
  }
}

/*
Note: We're not currently using this endpoint.
export const sendActivityEvent = async (data) => {
  try {
    const { data: responseData } = await authAxios({
      method: 'post',
      url: 'activities',
      data,
    })

    return responseData.status === 200
  } catch (error) {
    return false
  }
}
*/

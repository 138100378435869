import {Fragment, FunctionalComponent} from 'preact';
import { useEffect, useState } from 'preact/hooks';

type Props = {
  isPanelOpened: boolean
}

// A wrapper that prevents content from loading until the user opens the panel
const OpenedPanelContainer: FunctionalComponent<Props> = ({ children, isPanelOpened }) => {
  const [showContent, setShowContent] = useState(isPanelOpened)

  useEffect(() => {
    setShowContent((current) => current || isPanelOpened)
  }, [isPanelOpened])

  if (!showContent) return null

  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

export default OpenedPanelContainer

import {Fragment, FunctionalComponent, VNode} from 'preact'

type Props = {
  numTimes: number
  children: (index: number) => VNode
}

const Repeat: FunctionalComponent<Props> = ({numTimes, children}) => {
  const items = []
  for (let i = 0; i < numTimes; i++) {
    items.push(children(i))
  }
  return <Fragment>{items}</Fragment>
}

export default Repeat;

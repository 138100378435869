import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const RefreshIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Refresh icon</title>
    <path d="M12.0001 2.40002C6.88888 2.40002 2.70388 6.4219 2.42638 11.4675C2.40388 11.8125 2.567 12.1425 2.85575 12.3338C3.1445 12.5231 3.51388 12.5438 3.82138 12.3863C4.12888 12.2288 4.3295 11.9175 4.34263 11.5725C4.56575 7.5244 7.892 4.32002 12.0001 4.32002C14.2014 4.32002 16.1758 5.2444 17.5726 6.72002H16.3201C15.9733 6.7144 15.6526 6.89627 15.4783 7.19627C15.302 7.4944 15.302 7.86565 15.4783 8.16377C15.6526 8.46377 15.9733 8.64565 16.3201 8.64002H19.3258C19.4345 8.65877 19.5451 8.65877 19.6558 8.64002H21.1201V3.84002C21.1239 3.58127 21.0226 3.3319 20.8389 3.14815C20.6551 2.9644 20.4058 2.86315 20.1451 2.8669C19.6164 2.8744 19.1926 3.3094 19.2001 3.84002V5.65315C17.4395 3.65815 14.8633 2.40002 12.0001 2.40002ZM20.6551 11.5069C20.1245 11.4844 19.6783 11.8969 19.6576 12.4275C19.4345 16.4756 16.1083 19.68 12.0001 19.68C9.79888 19.68 7.82638 18.7556 6.42763 17.28H7.68013C8.027 17.2856 8.34763 17.1038 8.522 16.8038C8.69825 16.5056 8.69825 16.1344 8.522 15.8363C8.34763 15.5363 8.027 15.3544 7.68013 15.36H4.66513C4.56388 15.345 4.46263 15.345 4.36138 15.36H2.88013V20.16C2.8745 20.5069 3.05638 20.8275 3.35638 21.0019C3.6545 21.1781 4.02575 21.1781 4.32388 21.0019C4.62388 20.8275 4.80575 20.5069 4.80013 20.16V18.3469C6.56075 20.3419 9.137 21.6 12.0001 21.6C17.1114 21.6 21.2964 17.5781 21.5739 12.5325C21.5926 12.2738 21.5045 12.0188 21.332 11.8256C21.1576 11.6325 20.9139 11.5181 20.6551 11.5069Z" fill="currentColor" />
  </svg>
)

export default RefreshIcon

import {FunctionalComponent, h} from 'preact';
import styles from './WelcomeMessage.styles.scss'

type Props = {
  headerTitle: string
  subheading: string
}

const WelcomeMessage: FunctionalComponent<Props> = ({headerTitle, subheading}) => {
  if (!headerTitle && !subheading) {
     return null;
  }
  return (
      <div className={styles.WelcomeMessage}>
        {Boolean(headerTitle) &&
          <h1 className={styles.heading}>
            {headerTitle}
          </h1>
        }
        {Boolean(subheading) &&
          <div className={styles.subheading}>
            {subheading}
          </div>
        }
      </div>
  );
};

export default WelcomeMessage;


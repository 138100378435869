import {useState} from "preact/compat";
import {useEffect} from "preact/hooks";

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState<boolean | undefined>()

  useEffect(() => {
    const handleResize = () => {
      setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0)
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isTouchDevice

}

export default useIsTouchDevice

import {FunctionalComponent} from 'preact'
import styles from './RewardSingle.styles.scss'
import BrandSummary from './BrandSummary'
import useTranslation from '../hooks/useTranslation'
import Section from './Section'
import useEvents, {rewardIds} from "../hooks/useEvents";
import {useEffectOnceWhen} from "rooks";
import TermsAndConditions from './TermsAndConditions'
import ClaimCta from './claimCta/ClaimCta'
import clsx from 'clsx'
import RichText from './RichText'
import {toLocalDateString} from '../utils/strings'
import ExpandableContainer from './ExpandableContainer'
import useAppScrollListener from "../hooks/useAppScrollListener";
import RewardSinglePlaceholder from "./common/RewardSinglePlaceholder";
import useReward from "../hooks/useReward";
import {useParams} from "react-router-dom";
import {useEffect} from "preact/hooks";
import {useSettingsContext} from "../contexts/SettingsContext";
import {settings} from "../global";

type Props = {
  onClaimStart?: () => void
  onClaimEnd?: () => void
  childApi: ChildAPI | null
  onCloseClick: (() => void) | null
}

const RewardSingle: FunctionalComponent<Props> = ({onClaimStart, onClaimEnd, childApi, onCloseClick}) => {
  const {rewardId = '', requestId = ''} = useParams()
  const {
    isFetching,
    reward,
    claimLoading,
    handleClaim,
    claimedData,
    handleShopClick,
    handleResendEmail,
    handleCopyCodeClick,
    redirectUrl,
  } = useReward({rewardId, requestId, enabled: true})
  const {integrationType} = useSettingsContext()

  const { t, locale } = useTranslation()
  const raiseEvent = useEvents()
  const isClaimed = Boolean(claimedData)

  useAppScrollListener(() => {
    !!reward && raiseEvent({ name: 'reward-scroll', ...rewardIds(reward) })
  })

  useEffectOnceWhen(() => {
    if (reward) {
      raiseEvent({
        name: 'reward-view',
        request_id: requestId,
        ...rewardIds(reward)
      })
    }
  }, !!reward)

  useEffect(() => {
    if (claimLoading) {
      typeof onClaimStart === 'function' && onClaimStart()
    } else {
      typeof onClaimEnd === 'function' && onClaimEnd()
    }
  }, [claimLoading, onClaimEnd, onClaimStart])

  const renderRewardContent = () => {
    if (!reward) return null

    const {
      brand_name,
      brand_logo,
      offer_details,
      steps_to_redeem,
      brand_about,
      terms_url,
      terms,
    } = reward

    return (
        <main className={clsx(styles.main, {[styles.noHeroImage]: isClaimed})}>
          <Section textCenter>
            <BrandSummary name={brand_name} logo={brand_logo} />
            <h2 className='h6 mb-4'>
              {reward.offer_title}
            </h2>
            {!isFetching &&
              <ClaimCta
                reward={reward}
                claimLoading={claimLoading}
                claimData={claimedData}
                onClaimClicked={(_, userConsent) => handleClaim(false, userConsent)}
                onShopClick={handleShopClick}
                onResendEmail={handleResendEmail}
                onCopyCodeClick={handleCopyCodeClick}
                redirectUrl={redirectUrl}
              />
            }
          </Section>

          <Section title={t('reward.expiration.title')} reducePadding>
            {reward.discount_end_date
                ? toLocalDateString(reward.discount_end_date, locale)
                : toLocalDateString(reward.end_date, locale)
            }
          </Section>

          {isClaimed && Boolean(steps_to_redeem) && (
              <Section title={t('reward.redeem.title')} reducePadding>
                <RichText text={steps_to_redeem} />
              </Section>
          )}

          {Boolean(offer_details) && (
              <Section title={t('reward.details.title')} reducePadding>
                <ExpandableContainer>
                  <RichText text={offer_details} />
                </ExpandableContainer>
              </Section>
          )}

          {!isClaimed && Boolean(brand_about) && (
              <Section title={t('reward.about.title')} reducePadding>
                <ExpandableContainer>
                  <RichText text={brand_about} />
                </ExpandableContainer>
              </Section>
          )}

          {Boolean(terms) && (
              <Section title={t('reward.terms.title')} reducePadding>
                <ExpandableContainer maxCollapsedHeight={0} hideLinkText={t('reward.terms.hide')}
                                     showLinkText={t('reward.terms.show')}>
                  <RichText text={terms} />
                </ExpandableContainer>
              </Section>
          )}

          <Section>
            <TermsAndConditions url={terms_url} />
          </Section>

        </main>
    )
  }

  const heroImage = reward?.hero_image || reward?.brand_email_banner || settings.heroImagePlaceholder

  return (
    <div className={clsx(styles.rewardSingle, {[styles.isPanel]: integrationType === 'panel'})}>
      {integrationType !== 'panel' && typeof onCloseClick === 'function' &&
        <button type="button"
                className={styles.btnClose}
                aria-label="Close"
                onClick={onCloseClick}
        >
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
              fill="#1D1D1D" fill-opacity="0.5" />
            <path
              d="M11.3268 10.6602C11.1942 10.6602 11.0646 10.6998 10.9546 10.7739C10.8446 10.848 10.7591 10.9532 10.7092 11.076C10.6593 11.1989 10.6472 11.3339 10.6744 11.4637C10.7016 11.5935 10.7669 11.7122 10.862 11.8047L15.0573 16L10.862 20.1954C10.798 20.2568 10.7469 20.3304 10.7117 20.4118C10.6765 20.4932 10.6579 20.5808 10.657 20.6695C10.6561 20.7582 10.6729 20.8462 10.7064 20.9283C10.74 21.0104 10.7895 21.085 10.8523 21.1478C10.915 21.2105 10.9896 21.26 11.0717 21.2936C11.1538 21.3271 11.2418 21.3439 11.3305 21.343C11.4192 21.3421 11.5068 21.3235 11.5882 21.2883C11.6697 21.2531 11.7432 21.202 11.8047 21.1381L16 16.9428L20.1953 21.1381C20.2567 21.202 20.3303 21.2531 20.4117 21.2883C20.4931 21.3235 20.5808 21.3421 20.6695 21.343C20.7581 21.3439 20.8461 21.3271 20.9282 21.2936C21.0104 21.2601 21.085 21.2105 21.1477 21.1478C21.2104 21.085 21.26 21.0104 21.2935 20.9283C21.327 20.8462 21.3438 20.7582 21.3429 20.6695C21.342 20.5808 21.3234 20.4932 21.2883 20.4118C21.2531 20.3304 21.202 20.2568 21.138 20.1954L16.9427 16L21.138 11.8047C21.2343 11.7111 21.3001 11.5905 21.3268 11.4589C21.3534 11.3272 21.3396 11.1905 21.2873 11.0668C21.2349 10.9431 21.1464 10.838 21.0333 10.7655C20.9203 10.6929 20.7879 10.6562 20.6536 10.6602C20.4804 10.6654 20.316 10.7377 20.1953 10.862L16 15.0573L11.8047 10.862C11.7425 10.7982 11.6682 10.7474 11.5862 10.7127C11.5041 10.6781 11.4159 10.6602 11.3268 10.6602Z"
              fill="white"
            />
          </svg>
        </button>
      }

      {heroImage && (
        <div className={styles.heroImage} style={{ backgroundImage: `url("${heroImage}")` }} />
      )}
      {!reward ? (
          <RewardSinglePlaceholder />
      ) : renderRewardContent()}

    </div>
  )
}

export default RewardSingle

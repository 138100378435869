import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useSettingsContext} from "../../contexts/SettingsContext";
import {fetchClaimedReward, fetchOneReward} from "../../services/rewards";
import {queries} from "../../queryKeyFactory";
import {RewardResponse, RewardsResponse} from "../../interfaces/sdk";
import {formatUserProgress} from "../../utils/userProgress";
import toast from "react-hot-toast";
import useTranslation from "../useTranslation";
import {useParams} from "react-router-dom";

type Settings = {
  id: string,
  claimId?: string,
  requestId: string,
  enabled: boolean,
  onFetchRewardError?: (e:any) => void
}

const useSingleRewardQuery = ({id, claimId, requestId, enabled, onFetchRewardError}: Settings) => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {allRewardsLimit, initialParentRewardId} = useSettingsContext()
  const {claimId: paramClaimId} = useParams()
  const _claimId = paramClaimId || claimId

  return useQuery({
    queryKey: ['reward', {id}],
    queryFn: async () => {
      try {
        let response: RewardResponse
        if (_claimId) {
          response = await fetchClaimedReward(_claimId)
        } else {
          response = await fetchOneReward(id, requestId)
        }
        queryClient.setQueryData(queries.profile.user.queryKey, () => {
          return formatUserProgress(response.userProgress)
        })
        return response
      } catch (e) {
        toast.error(t('errors.generic'))
        typeof onFetchRewardError === 'function' && onFetchRewardError(e)
        return null
      }

    },
    enabled,
    placeholderData: () => {
      const cachedClaimable = queryClient.getQueryData<RewardsResponse>(
          queries.rewards.list({
            list_type: 'claimable',
            reward_id: initialParentRewardId,
            limit: allRewardsLimit
          }).queryKey
      )
      const claimableRewards = cachedClaimable?.data.payload || []
      return {
        ...cachedClaimable,
        data: claimableRewards.find(reward => reward.id === id)
      }
    },
  })
}

export default useSingleRewardQuery


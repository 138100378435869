import {FunctionalComponent, h} from 'preact';
import styles from "./CardPlaceholder.style.scss";
import clsx from "clsx";

type Props = {
  extraClass?:string
}

const CardPlaceholder: FunctionalComponent<Props> = ({extraClass}) => {

  return (
      <div className={clsx(styles.tile, extraClass)} aria-hidden>
        <div className={styles.cardWrapper}>
          <div className={clsx(styles.card)}>
            <div className={clsx(styles.thumbnail)} />
            <div className={clsx(styles.cardBody, "placeholder-glow")}>
              <div className="placeholder col-12" />
              <div className="placeholder col-12" />
              <div className="placeholder col-12" />
            </div>
          </div>
        </div>
      </div>
  );
};

export default CardPlaceholder;


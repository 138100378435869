const calculateVisibleAreaPercentage = (element: HTMLElement):number => {
  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  // Calculate the dimensions of the element
  const elementHeight = rect.height;
  const elementWidth = rect.width;

  // Calculate the coordinates of the element's boundaries
  const elementTop = rect.top;
  const elementBottom = rect.bottom;
  const elementLeft = rect.left;
  const elementRight = rect.right;

  // Calculate the visible height and width of the element within the viewport
  const visibleHeight = Math.min(elementBottom, windowHeight) - Math.max(elementTop, 0);
  const visibleWidth = Math.min(elementRight, windowWidth) - Math.max(elementLeft, 0);

  // Calculate the percentage of the element's visibility
  return (visibleHeight * visibleWidth) / (elementHeight * elementWidth) * 100;
}

export default (element: HTMLElement | null, minimumVisibilityPercent: number): { isVisible: boolean, visiblePercentage: number } => {
  if (!element) return {isVisible: false, visiblePercentage: 0}

  // Calculate the percentage of the element's visibility
  const visiblePercentage = calculateVisibleAreaPercentage(element)

  return {
    isVisible: visiblePercentage >= minimumVisibilityPercent,
    visiblePercentage
  };
}

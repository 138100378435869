import {ActivityCompletion, UserProgress} from "../interfaces/sdk";

export type FormattedUserProgress = {
  remainingToday: number;
  remainingThisWeek: number;
  remaining: number;
  totalClaimed: number;
  claimLimitReached: boolean;
  latestActivity: ActivityCompletion | null;
  latestClaimDate?: string;
}

export const formatUserProgress = (userProgress: UserProgress): FormattedUserProgress => {
  const {
    claimed_today,
    claimed_this_week,
    daily_allowance,
    weekly_allowance,
    credits_balance,
    total_claimed,
    latest_claim_date
  } = userProgress.rewards

  const remainingToday = Math.max(0, (daily_allowance || Infinity) + credits_balance - claimed_today)
  const remainingThisWeek = Math.max(0, (weekly_allowance || Infinity) + credits_balance - claimed_this_week)
  const remaining = Math.min(remainingToday, remainingThisWeek)

  return {
    remaining,
    remainingToday,
    remainingThisWeek,
    claimLimitReached: remaining === 0,
    totalClaimed: total_claimed || 0,
    latestActivity: userProgress.latest_activity || null,
    latestClaimDate: latest_claim_date
  }
}

import properties from './qub/qubProperties'

export const isContextValidForPublisher = (appSlug: string, context: string): boolean => {
  // We consider an empty context to be valid as we will fall back to a default.
  if (context === '') {
    return true
  }
  const ctx = context.toLowerCase()

  switch (appSlug.toLowerCase()) {
    case 'qub':
      return Object.keys(properties).includes(ctx)

    default:
      return ctx === 'newsletter' || ctx === 'subscribe'
  }
}


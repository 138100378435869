import { FunctionalComponent, h } from 'preact'

interface Props {
  className?: string
}

const ErrorImg: FunctionalComponent<Props> = (props: Props) => {
  return (
    <svg
      width="678"
      height="422"
      viewBox="0 0 678 422"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_98_90)">
        <path
          d="M177.535 256.834C248.646 256.834 306.292 199.34 306.292 128.417C306.292 57.4942 248.646 0 177.535 0C106.425 0 48.7793 57.4942 48.7793 128.417C48.7793 199.34 106.425 256.834 177.535 256.834Z"
          fill="#FFC8D6"
        />
        <path
          d="M0.65625 30.8335C0.65625 77.5567 30.0453 115.365 66.3646 115.365Z"
          fill="#2F2E41"
        />
        <path
          d="M66.3647 115.365C66.3647 68.1163 99.1613 29.8837 139.692 29.8837Z"
          fill="currentColor"
        />
        <path
          d="M24.4639 35.0691C24.4639 79.4512 43.2047 115.365 66.3649 115.365Z"
          fill="currentColor"
        />
        <path d="M66.3647 115.365C66.3647 54.992 104.272 6.13916 151.119 6.13916Z" fill="#2F2E41" />
        <path
          d="M52.5415 115.96C52.5415 115.96 61.86 115.674 64.6684 113.68C67.4768 111.685 79.0026 109.304 79.6993 112.502C80.396 115.701 93.7034 128.412 83.1828 128.496C72.6622 128.581 58.7376 126.862 55.9347 125.159C53.1317 123.456 52.5415 115.96 52.5415 115.96Z"
          fill="#A8A8A8"
        />
        <path
          opacity="0.2"
          d="M83.3705 127.383C72.85 127.468 58.9254 125.749 56.1224 124.046C53.9878 122.749 53.1372 118.095 52.8526 115.948C52.6555 115.957 52.5415 115.96 52.5415 115.96C52.5415 115.96 53.1317 123.456 55.9347 125.159C58.7377 126.862 72.6622 128.581 83.1828 128.496C86.2197 128.472 87.2687 127.394 87.2111 125.798C86.7891 126.763 85.6309 127.365 83.3705 127.383Z"
          fill="black"
        />
        <path
          d="M120.713 271.267C183.143 271.267 233.753 264.4 233.753 255.93C233.753 247.459 183.143 240.592 120.713 240.592C58.2833 240.592 7.67383 247.459 7.67383 255.93C7.67383 264.4 58.2833 271.267 120.713 271.267Z"
          fill="#3F3D56"
        />
        <path
          opacity="0.1"
          d="M120.713 268.807C173.128 268.807 215.618 263.041 215.618 255.93C215.618 248.818 173.128 243.053 120.713 243.053C68.2989 243.053 25.8086 248.818 25.8086 255.93C25.8086 263.041 68.2989 268.807 120.713 268.807Z"
          fill="black"
        />
        <path
          d="M506.377 421.425C600.857 421.425 677.447 411.032 677.447 398.213C677.447 385.394 600.857 375.002 506.377 375.002C411.897 375.002 335.307 385.394 335.307 398.213C335.307 411.032 411.897 421.425 506.377 421.425Z"
          fill="#3F3D56"
        />
        <path
          d="M188.416 403.113C245.17 403.113 291.179 396.87 291.179 389.17C291.179 381.469 245.17 375.226 188.416 375.226C131.661 375.226 85.6528 381.469 85.6528 389.17C85.6528 396.87 131.661 403.113 188.416 403.113Z"
          fill="#3F3D56"
        />
        <path
          d="M280.298 377.413C334.702 391.28 439.279 359.326 450.764 323.153"
          stroke="#2F2E41"
          stroke-width="2.79089"
          stroke-miterlimit="10"
        />
        <path
          d="M187.812 156.15C187.812 156.15 266.395 134.446 271.231 204.382C276.067 274.318 206.551 294.214 260.955 308.08"
          stroke="#2F2E41"
          stroke-width="2.79089"
          stroke-miterlimit="10"
        />
        <path
          d="M111.889 323.926C111.889 323.926 135.508 317.403 136.961 338.423C138.415 359.442 117.521 365.422 133.873 369.59"
          stroke="#2F2E41"
          stroke-width="2.79089"
          stroke-miterlimit="10"
        />
        <path
          d="M471.375 339.575L466.613 369.898C466.613 369.898 443.17 382.32 459.653 382.685C476.137 383.05 553.792 382.685 553.792 382.685C553.792 382.685 568.81 382.685 545.001 369.533L540.239 337.749L471.375 339.575Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M456.337 382.356C460.081 379.034 466.613 375.572 466.613 375.572L471.375 345.249L540.239 345.314L545.001 375.207C550.553 378.274 553.993 380.625 556.005 382.428C559.063 381.732 562.404 379.146 545.001 369.533L540.239 337.749L471.375 339.575L466.613 369.898C466.613 369.898 446.913 380.337 456.337 382.356Z"
          fill="black"
        />
        <path
          d="M649.66 128.229H361.221C355.196 128.229 350.312 133.1 350.312 139.109V332.897C350.312 338.906 355.196 343.777 361.221 343.777H649.66C655.684 343.777 660.568 338.906 660.568 332.897V139.109C660.568 133.1 655.684 128.229 649.66 128.229Z"
          fill="#2F2E41"
        />
        <path d="M650.129 139.737H360.752V301.215H650.129V139.737Z" fill="#3F3D56" />
        <path
          d="M505.44 136.449C506.452 136.449 507.272 135.631 507.272 134.622C507.272 133.614 506.452 132.796 505.44 132.796C504.429 132.796 503.609 133.614 503.609 134.622C503.609 135.631 504.429 136.449 505.44 136.449Z"
          fill="#F2F2F2"
        />
        <path
          d="M660.568 314.002V332.897C660.568 334.326 660.286 335.741 659.738 337.061C659.19 338.381 658.387 339.58 657.374 340.591C656.361 341.601 655.158 342.402 653.835 342.949C652.511 343.496 651.092 343.777 649.66 343.777H361.221C359.788 343.777 358.37 343.496 357.046 342.949C355.723 342.402 354.52 341.601 353.507 340.591C352.494 339.58 351.691 338.381 351.142 337.061C350.594 335.741 350.312 334.326 350.313 332.897V314.002H660.568Z"
          fill="#2F2E41"
        />
        <path
          d="M586.393 402.413V406.067H389.323V403.144L389.595 402.413L394.452 389.261H582.363L586.393 402.413Z"
          fill="#2F2E41"
        />
        <path
          d="M662.231 398.836C661.872 400.367 660.517 401.982 657.455 403.509C646.466 408.989 624.121 402.048 624.121 402.048C624.121 402.048 606.905 399.125 606.905 391.453C607.389 391.123 607.893 390.824 608.414 390.558C613.035 388.12 628.353 382.104 655.515 390.812C657.516 391.439 659.303 392.607 660.678 394.187C661.778 395.472 662.641 397.102 662.231 398.836Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M662.231 398.836C648.781 403.977 636.792 404.36 624.488 395.837C618.283 391.541 612.645 390.477 608.415 390.558C613.035 388.12 628.353 382.104 655.515 390.813C657.516 391.439 659.303 392.607 660.678 394.187C661.779 395.472 662.642 397.102 662.231 398.836Z"
          fill="black"
        />
        <path
          d="M645.367 395.837C647.997 395.837 650.129 395.183 650.129 394.376C650.129 393.569 647.997 392.914 645.367 392.914C642.737 392.914 640.605 393.569 640.605 394.376C640.605 395.183 642.737 395.837 645.367 395.837Z"
          fill="#F2F2F2"
        />
        <path
          d="M505.441 335.922C509.284 335.922 512.4 332.814 512.4 328.981C512.4 325.147 509.284 322.039 505.441 322.039C501.597 322.039 498.481 325.147 498.481 328.981C498.481 332.814 501.597 335.922 505.441 335.922Z"
          fill="#F2F2F2"
        />
        <path
          opacity="0.1"
          d="M586.393 402.413V406.066H389.323V403.144L389.595 402.413H586.393Z"
          fill="black"
        />
        <path d="M192.043 95.8606H66.3091V241.762H192.043V95.8606Z" fill="#2F2E41" />
        <path d="M204.737 81.391H53.6147V133.24H204.737V81.391Z" fill="#3F3D56" />
        <path d="M204.737 142.886H53.6147V194.736H204.737V142.886Z" fill="#3F3D56" />
        <path d="M204.737 204.382H53.6147V256.231H204.737V204.382Z" fill="#3F3D56" />
        <path opacity="0.4" d="M174.513 90.4345H164.841V100.081H174.513V90.4345Z" fill="#FFC8D6" />
        <path opacity="0.8" d="M188.416 90.4345H178.744V100.081H188.416V90.4345Z" fill="#FF7886" />
        <path d="M202.319 90.4345H192.647V100.081H202.319V90.4345Z" fill="currentColor" />
        <path opacity="0.4" d="M174.513 151.327H164.841V160.973H174.513V151.327Z" fill="#FFC8D6" />
        <path opacity="0.8" d="M188.416 151.327H178.744V160.973H188.416V151.327Z" fill="#FF7886" />
        <path d="M202.319 151.327H192.647V160.973H202.319V151.327Z" fill="currentColor" />
        <path opacity="0.4" d="M174.513 212.22H164.841V221.866H174.513V212.22Z" fill="#FFC8D6" />
        <path opacity="0.8" d="M188.416 212.22H178.744V221.866H188.416V212.22Z" fill="#FF7886" />
        <path d="M202.319 212.22H192.647V221.866H202.319V212.22Z" fill="currentColor" />
        <path
          d="M192.043 371.987C218.417 371.987 239.797 350.663 239.797 324.358C239.797 298.054 218.417 276.73 192.043 276.73C165.669 276.73 144.288 298.054 144.288 324.358C144.288 350.663 165.669 371.987 192.043 371.987Z"
          fill="#2F2E41"
        />
        <path d="M184.789 361.738H170.281V387.663H184.789V361.738Z" fill="#2F2E41" />
        <path d="M213.805 361.738H199.297V387.663H213.805V361.738Z" fill="#2F2E41" />
        <path
          d="M182.371 392.486C189.048 392.486 194.461 390.461 194.461 387.964C194.461 385.467 189.048 383.442 182.371 383.442C175.694 383.442 170.281 385.467 170.281 387.964C170.281 390.461 175.694 392.486 182.371 392.486Z"
          fill="#2F2E41"
        />
        <path
          d="M211.387 391.883C218.064 391.883 223.476 389.858 223.476 387.361C223.476 384.864 218.064 382.839 211.387 382.839C204.71 382.839 199.297 384.864 199.297 387.361C199.297 389.858 204.71 391.883 211.387 391.883Z"
          fill="#2F2E41"
        />
        <path
          d="M193.252 328.579C202.266 328.579 209.573 321.291 209.573 312.301C209.573 303.31 202.266 296.022 193.252 296.022C184.238 296.022 176.931 303.31 176.931 312.301C176.931 321.291 184.238 328.579 193.252 328.579Z"
          fill="white"
        />
        <path
          d="M193.252 317.727C196.257 317.727 198.692 315.297 198.692 312.301C198.692 309.304 196.257 306.874 193.252 306.874C190.247 306.874 187.812 309.304 187.812 312.301C187.812 315.297 190.247 317.727 193.252 317.727Z"
          fill="#3F3D56"
        />
        <path
          d="M145.719 280.065C141.863 262.842 154.189 245.438 173.25 241.193C192.311 236.948 210.889 247.469 214.745 264.693C218.601 281.916 205.968 288.268 186.907 292.513C167.847 296.758 149.575 297.288 145.719 280.065Z"
          fill="currentColor"
        />
        <path
          d="M236.832 314.859C248.955 309.684 257.463 302.411 255.834 298.615C254.205 294.82 243.056 295.938 230.933 301.113C218.81 306.288 210.302 313.561 211.931 317.356C213.56 321.152 224.709 320.034 236.832 314.859Z"
          fill="#2F2E41"
        />
        <path
          d="M147.368 346.812C159.491 341.637 167.998 334.365 166.369 330.569C164.741 326.773 153.592 327.891 141.469 333.066C129.345 338.242 120.838 345.514 122.467 349.31C124.096 353.106 135.244 351.988 147.368 346.812Z"
          fill="#2F2E41"
        />
        <path
          d="M219.849 338.225C219.849 342.887 207.815 352.092 194.461 352.092C181.107 352.092 168.468 343.49 168.468 338.828C168.468 334.166 181.107 335.211 194.461 335.211C207.815 335.211 219.849 333.563 219.849 338.225Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_98_90">
          <rect width="676.791" height="421.425" fill="white" transform="translate(0.65625)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ErrorImg

import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const ShareIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Share icon</title>
    <path d="M2.39997 21.5981C2.39247 21.5981 2.38685 21.5981 2.37935 21.5981C2.1206 21.5869 1.91622 21.3731 1.91997 21.1144C1.92185 20.9962 2.19747 9.44624 13.44 9.12749V5.75999C13.44 5.57436 13.5468 5.40562 13.7137 5.32687C13.8787 5.24624 14.0793 5.27062 14.2218 5.38687L21.9018 11.6269C22.0143 11.7187 22.08 11.8556 22.08 12C22.08 12.1444 22.0143 12.2812 21.9037 12.3731L14.2237 18.6131C14.0793 18.7294 13.8806 18.7519 13.7137 18.6731C13.5468 18.5944 13.44 18.4256 13.44 18.24V14.8837C3.43685 15.0656 2.89685 20.9006 2.8781 21.1519C2.86122 21.405 2.65122 21.5981 2.39997 21.5981Z" fill="currentColor" />
  </svg>
)

export default ShareIcon

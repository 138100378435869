import Cookies from 'js-cookie'
import {FunctionalComponent, VNode} from 'preact';
import {createContext, StateUpdater, useState} from "preact/compat";
import {useCallback, useContext, useEffect} from "preact/hooks";
import {authAxios, TokenType} from "../services/auth";
import {TEST_APP_SLUG} from "../global";
import {useDidMount, useLocalstorageState, useSessionstorageState} from "rooks";
import {v4 as uuidv4} from 'uuid';
import axios from "axios";
import {AppInfo} from "../interfaces/publishers";
import {getHostAppSlug, getMainDomain, removeParamsFromUrl} from "../utils";
import {useParams} from "react-router-dom";
import {Integration, StorefrontType} from "../interfaces/integrations";

type Limit = number

type SettingsContextValue = {
  newsletterBannerClickId: string;
  allRewardsLimit: Limit;
  setAllRewardsLimit: StateUpdater<Limit>;
  tokenType: TokenType;
  signInToken: string;
  setSignInToken: StateUpdater<string>;
  publisherDomain: string;
  appSlug: string;
  integrationType: 'in-content' | 'panel' | 'modal' | null | string;
  activeIntegration: Integration | undefined;
  isStandaloneApp: boolean;
  layout: 'carousel' | string | null;
  application: AppInfo;
  emailParam: string;
  setEmailParam: StateUpdater<SettingsContextValue['emailParam']>;
  initialParentRewardId: string;
  setInitialParentRewardId: StateUpdater<string>;
  firstClaimRewardId: string;
  setFirstClaimRewardId: StateUpdater<string>;
  headerTitle: string | null;
  subheading: string | null;
  disableEmailSignIn: boolean;
  directClaim: string;
  setDirectClaim: StateUpdater<string>;
  storeFrontType: StorefrontType | undefined;
  context: string;
  getIntegrationOrAppSetting: (settingKey: 'ad_label_disabled' | 'third_party_label_disabled' | 'affiliate_disclosure_disabled') => any;
  frontEndUserId: string;
  userSessionId: string;
}

type Props = {
  children: VNode | ((integrationType: SettingsContextValue['integrationType']) => VNode);
}

const SettingsContext = createContext<SettingsContextValue | undefined>(undefined)

const hostAppSlug = getHostAppSlug()

const SettingsProvider: FunctionalComponent<Props> = ({children}) => {
  const searchParams = new URLSearchParams(window.location.search)
  const {appSlug: routerAppSlug, context: contextParam} = useParams()
  let initialAppSlug = searchParams.get('appname') || hostAppSlug || routerAppSlug || ''
  if (process.env.NODE_ENV === 'test') {
    initialAppSlug = TEST_APP_SLUG
  }
  const newsletterBannerClickId = searchParams.get('newsletter_banner_click_id') || ''
  const [appSlug] = useState<SettingsContextValue['appSlug']>(initialAppSlug.toLowerCase())
  let email = searchParams.get('gl_email') || searchParams.get('email') || ''
  email = email.replace(/ /g, '+');
  const [emailParam, setEmailParam] = useState(email)
  const [signInToken, setSignInToken] = useState((searchParams.get('signin_token') ?? ''))
  const [integrationId] = useState((searchParams.get('integration_id') ?? ''))
  const [userSessionId] = useSessionstorageState('user_session_id', uuidv4())
  const [layout] = useState<SettingsContextValue['layout']>(searchParams.get('layout'))
  const defaultRewardsLimit = layout === 'carousel' ? 3 : 8
  const limit = Number(searchParams.get('limit') || defaultRewardsLimit)
  const [allRewardsLimit, setAllRewardsLimit] = useState<Limit>(limit)
  const [initialParentRewardId, setInitialParentRewardId] = useState(searchParams.get('reward_id') || '')
  const [directClaim, setDirectClaim] = useState(searchParams.get('direct_claim') || searchParams.get('reward') || '')
  const [firstClaimRewardId, setFirstClaimRewardId] = useState('')

  // Cache search param values as we only want the values from initial page load.
  const [publisherDomain] = useState(searchParams.get('publisher_domain') ?? '')
  const [integrationType] = useState<SettingsContextValue['integrationType']>(searchParams.get('integration_type'))
  const [application, setApplication] = useState<AppInfo>()
  const [activeIntegration, setActiveIntegration] = useState<SettingsContextValue['activeIntegration']>()
  const [headerTitle] = useState<SettingsContextValue['headerTitle']>(searchParams.get('header_title'))
  const [subheading] = useState<SettingsContextValue['subheading']>(searchParams.get('subheading'))
  const disableEmailSignIn = (searchParams.get('disable_email_signin')) === 'true'

  const contextFromUrl = searchParams.get('context') || ''
  const [context, setContext] = useLocalstorageState('context', contextFromUrl)
  const frontEndUserId = Cookies.get("gl-fuid") || uuidv4();

  useEffect(() => {
    const newContext = contextParam || contextFromUrl
    if (newContext) {
      setContext(newContext)
    } else {
      setContext('')
    }
  }, [contextFromUrl, contextParam, setContext])

  useEffect(() => {
    if (!appSlug) {
      throw new Response('', {status: 404, statusText: 'Missing app slug'})
    }
    axios.get(`${process.env.PREACT_APP_PUBLIC_BUCKET_APP_INFO}/${appSlug}.json`, {
      params: {
        t: Date.now()
      }
    })
        .then(resp => {
          setApplication(resp.data)

          if (!integrationType) return

          // Find integration data
          if (integrationType === 'in-content') {
            const integration = resp.data.integrations[integrationType].find((item:any) => item.id === integrationId)
            setActiveIntegration(integration)
          } else {
            const integration = resp.data.integrations[integrationType as string]
            setActiveIntegration(integration)
          }
        })
  }, [appSlug, integrationId, integrationType])

  useDidMount(() => {
    const hostUrl = new URL(publisherDomain || window.location.href)

    Cookies.set("gl-fuid", frontEndUserId, {
      expires: 365,
      path: "/",
      sameSite: "None",
      secure: true,
      "__Host-name": hostUrl.hostname,
      Partitioned: true,
      domain: getMainDomain(window.location.href),
    });

    authAxios.defaults.headers['gl-fuid'] = frontEndUserId
  })

  useEffect(() => {
    authAxios.defaults.headers['gl-sid'] = userSessionId
    authAxios.defaults.headers['gl-iid'] = integrationId
  }, [integrationId, userSessionId])

  const getIntegrationOrAppSetting = useCallback((
      settingKey: 'ad_label_disabled' | 'third_party_label_disabled' | 'affiliate_disclosure_disabled'
  ) => {
    if (integrationType && activeIntegration) {
      return activeIntegration[settingKey]
    }
    //Standalone app
    return application?.[settingKey]

  }, [activeIntegration, application, integrationType])

  if (!application) return null
  if (Boolean(integrationType) && !activeIntegration) return null

  removeParamsFromUrl('direct_claim', 'reward', 'reward_id', 'email', 'signin_token', 'token_type', 'context')

  return (
      <SettingsContext.Provider
          value={{
            newsletterBannerClickId,
            allRewardsLimit,
            setAllRewardsLimit,
            tokenType: searchParams.get('token_type') || '',
            signInToken,
            setSignInToken,
            publisherDomain,
            appSlug,
            integrationType,
            activeIntegration,
            application,
            emailParam,
            setEmailParam,
            initialParentRewardId,
            setInitialParentRewardId,
            firstClaimRewardId,
            setFirstClaimRewardId,
            layout,
            headerTitle,
            subheading,
            disableEmailSignIn,
            directClaim,
            setDirectClaim,
            storeFrontType: activeIntegration?.storefront_type || StorefrontType.CardFlip,
            isStandaloneApp: !activeIntegration,
            context,
            getIntegrationOrAppSetting,
            frontEndUserId,
            userSessionId,
          }}
      >
        {typeof children === 'function' ? children(integrationType) : children}
      </SettingsContext.Provider>
  );
};

export default SettingsProvider;

export const useSettingsContext = () => {
  const context = useContext(SettingsContext)
  if (context === undefined) {
    throw new Error('useSettingsContext must be used within SettingsProvider')
  }
  return context
}


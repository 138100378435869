import { AppInfo } from '../../../../interfaces/publishers'
import * as logos from './assets/logos'
import {useSettingsContext} from "../../../../contexts/SettingsContext";

const properties: {
  [key: string]: Pick<AppInfo, 'name' | 'logo' | 'app_website_url' | 'slug'>
} = {
  qub: {
    logo: logos.qub,
    name: 'QUB',
    app_website_url: 'https://www.qub.ca',
    slug: 'qub',
  },
  jdm: {
    logo: logos.jdm,
    name: 'le Journal de Montréal',
    app_website_url: 'https://www.journaldemontreal.com/',
    slug: 'jdm',
  },
  jdq: {
    logo: logos.jdq,
    name: 'le Journal de Québec',
    app_website_url: 'https://www.journaldequebec.com/',
    slug: 'jdq',
  },
  sb: {
    logo: logos.sb,
    name: 'Salut Bonjour',
    app_website_url: 'https://www.salutbonjour.ca/',
    slug: 'sb',
  },
  tv: {
    logo: logos.tvaplus,
    name: 'TVA+',
    app_website_url: 'https://www.qub.ca/tvaplus/tva/en-direct',
    slug: 'tv',
  },
  tvan: {
    logo: logos.tvan,
    name: 'TVA Nouvelles',
    app_website_url: 'https://www.tvanouvelles.ca/',
    slug: 'tvan',
  },
  tvas: {
    logo: logos.tvas,
    name: 'TVA Sports',
    app_website_url: 'https://www.tvasports.ca/',
    slug: 'tvas',
  },
  music: {
    logo: logos.musique,
    name: 'qub musique',
    app_website_url: 'https://www.qub.ca/musique',
    slug: 'music',
  },
  profil: {
    logo: logos.profil,
    name: 'le profil QUB',
    app_website_url: 'https://www.qub.ca/leprofilqub',
    slug: 'profil',
  },
}

export const useQubProperty = () => {
  const {context} = useSettingsContext()
  const key = (context).toLowerCase()
  return Object.keys(properties).includes(key) ? properties[key] : properties.profil
}

export default properties

import {h, FunctionalComponent} from "preact"
import { IconNameMap } from "./index";

type Props = h.JSX.HTMLAttributes<SVGElement> & {
  iconName: keyof typeof IconNameMap | string;
  fallbackIconName?: keyof typeof IconNameMap;
}

const NamedIcon: FunctionalComponent<Props> = ({
  iconName,
  fallbackIconName = 'gift',
  ...props
}) => {
  const Icon = IconNameMap[iconName as keyof typeof IconNameMap] ?? IconNameMap[fallbackIconName];
  return <Icon {...props} />
}

export default NamedIcon

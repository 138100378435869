import {useEffect, useState} from 'preact/hooks';

type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | undefined;

const useBreakpoint = (): Breakpoints => {
  const [breakpoint, setBreakpoint] = useState<Breakpoints>(undefined);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1024) {
      setBreakpoint('lg');
    } else if (width >= 768) {
      setBreakpoint('md');
    } else if (width >= 460) {
      setBreakpoint('sm');
    } else {
      setBreakpoint('xs');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export default useBreakpoint;

import {h, FunctionalComponent} from 'preact'

type Props = h.JSX.HTMLAttributes<SVGElement> & {
  fill?: string
}

const EditIcon: FunctionalComponent<Props> = ({
                                                    width = 16,
                                                    height = 16,
                                                    fill = '#1D1D1D',
                                                  }) => {
  return (
      <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.276 1.33337C12.1055 1.33337 11.9347 1.39835 11.8047 1.52869L10.6667 2.66671L13.3333 5.33337L14.4714 4.19535C14.732 3.93469 14.732 3.51265 14.4714 3.25264L12.7474 1.52869C12.6171 1.39835 12.4466 1.33337 12.276 1.33337ZM9.66667 3.66671L2 11.3334V14H4.66667L12.3333 6.33337L9.66667 3.66671Z"
            fill={fill}
        />
      </svg>

  )
}

export default EditIcon

import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const NavRightIcon: FunctionalComponent<Props> = (props) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M15 0.280029C6.88253 0.280029 0.280029 6.88253 0.280029 15C0.280029 23.1175 6.88253 29.72 15 29.72C23.1175 29.72 29.72 23.1175 29.72 15C29.72 6.88253 23.1175 0.280029 15 0.280029ZM19.9325 15.4525L13.5325 21.8525C13.4075 21.9775 13.245 22.04 13.08 22.04C12.915 22.04 12.7525 21.9775 12.6275 21.8525C12.3775 21.6025 12.3775 21.1975 12.6275 20.9475L18.575 15L12.6275 9.05253C12.3775 8.80253 12.3775 8.39753 12.6275 8.14753C12.8775 7.89753 13.2825 7.89753 13.5325 8.14753L19.9325 14.5475C20.1825 14.7975 20.1825 15.2025 19.9325 15.4525Z"
          fill="#595959" />
    </svg>

)

export default NavRightIcon

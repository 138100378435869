import {FunctionalComponent, h} from 'preact'
import styles from './ErrorPage.styles.scss'
import CarCrashIcon from "../icons/CarCrashIcon";
import en from '../../i18n/en.json'
import {useRouteError} from "react-router-dom";

type Props = unknown

const ErrorPage: FunctionalComponent<Props> = () => {
  const error = useRouteError()
  console.error('error', error)

  return (
    <div className={styles.errorPage}>
      <div className="mb-4">
        <CarCrashIcon />
      </div>
      <div>
        <p className="body1 bold">
          {en.crashPage.title}
        </p>
        <p>
          {en.crashPage.subtitle}
        </p>
      </div>
    </div>
  )
}

export default ErrorPage

import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const PadlockIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Padlock icon</title>
    <g clip-path="url(#clip0_266_16653)">
    <path d="M10.9349 3.19409e-05C10.3705 -0.00184306 9.79488 0.0787822 9.22488 0.255032C6.19301 1.19628 4.45863 4.44941 5.39988 7.48503V7.51503C5.52363 7.85628 5.85551 8.93628 6.05988 9.60003H4.31988C3.50426 9.60003 2.87988 10.2244 2.87988 11.04V22.56C2.87988 23.3757 3.50426 24 4.31988 24H19.6799C20.4955 24 21.1199 23.3757 21.1199 22.56V11.04C21.1199 10.2244 20.4955 9.60003 19.6799 9.60003H7.07988C6.93176 9.12378 6.48551 7.71566 6.29988 7.20003H6.31488C5.52926 4.66691 6.95988 1.97253 9.49488 1.18503C12.0243 0.399407 14.7336 1.82253 15.5249 4.35003C15.5249 4.35191 15.5249 4.36316 15.5249 4.36503C15.6336 4.74566 16.1549 6.43503 16.1549 6.43503C16.198 6.60753 16.333 6.74441 16.5055 6.78566C16.6799 6.82878 16.8618 6.77253 16.9799 6.63941C17.098 6.50441 17.1336 6.31691 17.0699 6.15003C17.0699 6.15003 16.528 4.40253 16.4399 4.09503V4.08003C15.7349 1.80566 13.7286 0.260657 11.4899 0.0300319C11.3043 0.0112819 11.1224 3.19409e-05 10.9349 3.19409e-05ZM11.9999 14.4C12.8155 14.4 13.4399 15.0244 13.4399 15.84C13.4399 16.2713 13.2486 16.65 12.9599 16.89V18.24C12.9599 18.7688 12.5286 19.2 11.9999 19.2C11.4711 19.2 11.0399 18.7688 11.0399 18.24V16.89C10.7511 16.65 10.5599 16.2713 10.5599 15.84C10.5599 15.0244 11.1843 14.4 11.9999 14.4Z" fill="currentColor" />
    </g>
    <defs>
    <clipPath id="clip0_266_16653">
    <rect width="24" height="24" fill="white" />
    </clipPath>
    </defs>
  </svg>
)

export default PadlockIcon

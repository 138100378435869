import {getHostAppSlug} from "../../utils";

const BASE_PATH = '/'
const hostAppSlug = getHostAppSlug()

export const home = (options: { appSlug: string, context: string }) => {
  const {appSlug, context} = options

  let path = ''
  if (context) {
    path = `${BASE_PATH}${appSlug}/${context}`
  } else {
    path = `${BASE_PATH}${appSlug}`
  }

  if (hostAppSlug) {
    const pathParts = path.split('/')
    const index = pathParts.findIndex(item => item === appSlug)
    if (index) {
      pathParts[index] = ''
      //fixme: Implement a more robust solution to modify the url with the URL() constructor perhaps
      path = pathParts.join('/').replace('//', '/')
    }
  }

  return path
}

export const rewardSingle = (options: { appSlug: string, context: string, rewardId: string, claimId: string }) => {
  let homeRoute = home(options)
  if (homeRoute.endsWith('/')) {
    homeRoute = homeRoute.slice(0, -1)
  }

  if (options.claimId) {
    return `${homeRoute}/rewards/${options.rewardId}/${options.claimId}`
  }

  return `${homeRoute}/rewards/${options.rewardId}`
}

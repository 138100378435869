
export type AuthReducerState = {
  userEmail: string,
  isLoggedIn: boolean,
  isAnonymous: boolean,
  isLoading: boolean,
  signInError?: string,
  initialUserError?: Error,
  isAuthenticated: boolean,
};

export type AuthReducerAction =
    | { type: "initial_user_success", payload: {email:string} }
    | { type: "initial_user_error", payload: AuthReducerState['initialUserError'] }
    | { type: "auth_loading" }
    | { type: "auth_success", payload: {email:string} }
    | { type: "auth_error", payload: AuthReducerState['signInError']}

const authReducer = (prevState: AuthReducerState, action: AuthReducerAction) => {
  switch (action.type) {
    case 'initial_user_success':
      return {
        ...prevState,
        userEmail: action.payload.email,
        isAnonymous: !action.payload.email,
        isLoggedIn: Boolean(action.payload.email),
        isAuthenticated: true
      }
    case 'initial_user_error':
      return {
        ...prevState, initialUserError: action.payload
      }
    case 'auth_loading':
      return {
        ...prevState, isLoading: true,
      };
    case 'auth_success': {
      return {
        ...prevState,
        userEmail: action.payload.email,
        isAnonymous: !action.payload.email,
        isLoading: false,
        signInError: undefined,
        isLoggedIn: true,
        isAuthenticated: true
      };
    }
    case 'auth_error':
      return {
        ...prevState,
        signInError: action.payload,
        isLoading: false,
      };
    default:
      return prevState
  }
};

export default authReducer

import styles from './RewardThumbnail.style.scss'
import clsx from 'clsx'
import {FunctionalComponent, h} from "preact";
import { Reward } from '../interfaces/sdk';
import {useEffect, useState} from "preact/hooks";
import {safeLocalStorage} from "../utils/storage";
import useTranslation from "../hooks/useTranslation";
import {useSettingsContext} from "../contexts/SettingsContext";
import useClaimRewardMutation from "../hooks/mutations/useClaimRewardMutation";
import useOpenReward from "../hooks/useOpenReward";
import useAppPhrases from "../hooks/useAppPhrases";
import {useIntersectionObserverRef} from "rooks";
// @ts-ignore
import {Helmet} from 'react-helmet'
import ClickOutToClaim from "./ClickOutToClaim";

interface Props {
  reward: Reward;
  large?: boolean;
}

const RewardThumbnail: FunctionalComponent<Props> = ({
  reward,
}) => {
  const {t, locale} = useTranslation()
  const tAppPhrases = useAppPhrases()
  const {getIntegrationOrAppSetting} = useSettingsContext()
  const {
    thumbnail, brand_logo, brand_email_banner, brand_name,
    offer_title, condensed_offer_title, id
  } = reward
  const thumbnailImage = thumbnail || brand_email_banner
  const [claimData, setClaimData] = useState()
  const isClaimed = Boolean(reward.claimed) || Boolean(claimData)
  const claimMutation = useClaimRewardMutation()
  const {handleOpenReward} = useOpenReward()
  const [isOnScreen, setIsOnScreen] = useState(false)
  const [thumbnailRef] = useIntersectionObserverRef((entries) => {
    if (entries && entries[0]) {
      setIsOnScreen(entries[0].isIntersecting);
    }
  }, {threshold: 0.05});

  const shopUrl = reward.claimed?.[0]?.shop_url

  useEffect(() => {
    const onLocalstorageChange = ({key, newValue, oldValue}: StorageEvent) => {
      const claimKey = `claim_reward_id_${id}`
      if (key === claimKey) {
        if (newValue) {
          setClaimData(JSON.parse(newValue))
          safeLocalStorage.removeItem(claimKey)
        }
      }
    }
    window.addEventListener('storage', onLocalstorageChange);

    return () => {
      window.removeEventListener('storage', onLocalstorageChange);
    }
  }, [id])

  const onThumbnailClick = () => {
    if (reward.claim_at_tile_click) {
     if (shopUrl && reward.claimed?.[0]?.redirect && !reward.claimed?.[0]?.code) {
        window.open(shopUrl, '_blank', 'noopener')
        return
      }
      const existingClaimData = reward?.claimed?.[0]
      const claimedData = claimMutation?.data?.data?.discount_code ?? existingClaimData
      const isClaimed = Boolean(claimedData)

      if (isClaimed) {
        handleOpenReward(reward, reward.claimed?.[0].claim_id || '')
        return
      }
      if (claimMutation.isLoading) return

      const userConsent = document.getElementById('consentData')?.innerHTML || ''

      claimMutation.mutate({id: reward.id, requestId: '', locale, user_consent: userConsent}, {
        onSuccess: (data) => {
          const shopUrl = data?.data?.discount_code?.shop_url
          if (shopUrl && data?.data?.discount_code?.redirect) {
            window.open(shopUrl, '_blank', 'noopener')
          }
          if (data?.data?.discount_code?.code || !data?.data?.discount_code?.redirect) {
            handleOpenReward(reward, data?.data?.discount_code?.claim_id)
          }
        },
        onError: (error: any) => {
          const status = error?.response?.status
          if (typeof status === 'number' && status >= 400) {
            handleOpenReward(reward, reward.claimed?.[0].claim_id || '')
          }
        },
      })
    } else {
      handleOpenReward(reward, reward.claimed?.[0].claim_id || '')
    }
  }

  const renderCardBody = () => {
    return (
        <>
          <div className={styles.thumbnail} style={{backgroundImage: `url("${thumbnailImage}")`}} />
          <div className={styles.cardBody}>
            <div className={styles.brandSection}>
              <img className={styles.logo} src={brand_logo} alt="Logo" />
              <div className={styles.brandName}>{brand_name}</div>
            </div>
            <div className={styles.ctaSection}>
              <div className={styles.offerTitle}>{condensed_offer_title || offer_title}</div>
              <div className={styles.redeemBtn}>
                {reward.tile_btn_text || t('rewards.offerCard.redeemBtn')}
              </div>
            </div>
            {!getIntegrationOrAppSetting('ad_label_disabled') &&
              <div className={styles.adLabel} dangerouslySetInnerHTML={{__html: tAppPhrases('ad_label')}} />
            }
          </div>
        </>
    )
  }


  return (
    <div className={styles.RewardThumbnail} ref={thumbnailRef}>
      {isOnScreen &&
        <Helmet key={reward.id}>
          <link rel="prefetch" href={reward.hero_image || reward.brand_email_banner} />
        </Helmet>
      }
      <div className={styles.cardWrapper} id={id}>
        <div className={clsx(styles.card, {[styles.claimed]: isClaimed})}>
          {reward.claim_at_tile_click && (!reward.code_type || reward.code_type === 'none') ? (
              <ClickOutToClaim
                  rewardId={reward.id}
                  resourceId={reward.resource_id}
                  advertiserId={reward.advertiser_id}
              >
                {renderCardBody()}
              </ClickOutToClaim>
          ) : (
              <div onClick={onThumbnailClick}>
                {renderCardBody()}
              </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RewardThumbnail

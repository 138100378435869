import {monotonicFactory} from "ulid";

export const TEST_APP_SLUG = 'goloot-test-application'
export const TRACKING = {
  carousel_visibility_threshold: 0.5,
  thumbnail_impression_threshold: 0.7,
}

export const REWARD_THUMBNAIL_WIDTH = 329

const searchParams = new URLSearchParams(window.location.search)
export const pageInfo = {
  tags: searchParams.get('page_info_tags'),
  _url: searchParams.get('page_info_url'), // initial value
  get url(): string {
    return this._url || window.location.href;
  },
  set url(newVal:string) {
    this._url = newVal;
  },
}

export const settings = {
  initSource: searchParams.get('init_source'),
  integrationType: searchParams.get('integration_type'),
  heroImagePlaceholder: searchParams.get('hero_image'),
  isStorefrontOverlay: searchParams.get('is_storefront_overlay') === 'true',
}

export const ulid = monotonicFactory() // Provides unique, sortable ids.
export const globalEvent = {
  previousEventId: searchParams.get('event_id_prev') || '',
  pageImpressionId: ulid(),
  appViewId: searchParams.get('app_view_id') || '',
  panelOpenId: '',
}

import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const SentIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Sent icon</title>
    <path d="M21.3301 1.995L2.20513 10.215C2.03451 10.2881 1.92576 10.4625 1.92013 10.65C1.91451 10.8375 2.02326 11.0006 2.19013 11.085L8.88013 14.43L21.3301 1.995ZM22.0051 2.67L9.57013 15.12L12.9151 21.81C12.9976 21.9731 13.1683 22.08 13.3501 22.08H13.3651C13.5526 22.0744 13.7101 21.9656 13.7851 21.795L22.0051 2.67Z" fill="currentColor" />
  </svg>
)

export default SentIcon

import {FunctionalComponent, h} from 'preact';
import styles from "./RewardSinglePlaceholder.style.scss";
import clsx from "clsx";
import Section from "../Section";
import Repeat from "../Repeat";

type Props = unknown

const RewardSinglePlaceholder: FunctionalComponent<Props> = (props) => {

  return (
      <div className="text-black-50" aria-hidden>
        <div className={clsx(styles.content)}>
          <Section extraClass="placeholder-wave">
            <div className="d-flex align-items-center gap-3 mb-2">
              <div className={clsx(styles.logo, "placeholder")} />
              <div className="placeholder w-75" />
            </div>
            <div className="placeholder w-100" />
          </Section>
          <Repeat numTimes={3}>
            {(index: number) => (
                <Section key={index} extraClass="placeholder-wave">
                  <div className="placeholder w-100 mb-2" />
                  <div className="placeholder w-100" />
                </Section>
            )}
          </Repeat>

        </div>
      </div>

  );
};

export default RewardSinglePlaceholder;


import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const LinkingIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Linking icon</title>
    <path d="M5 2C3.35547 2 2 3.35547 2 5V19C2 20.6445 3.35547 22 5 22H19C20.6445 22 22 20.6445 22 19V5C22 3.35547 20.6445 2 19 2H5ZM5 4H19C19.5664 4 20 4.43359 20 5V19C20 19.5664 19.5664 20 19 20H5C4.43359 20 4 19.5664 4 19V5C4 4.43359 4.43359 4 5 4ZM6 6V8H14.5937L6 16.5937L7.40625 18L16 9.40625V18H18V6H6Z" fill="currentColor" />
  </svg>
)

export default LinkingIcon

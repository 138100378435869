import { FooterComponent } from '../../Footer'
import qubProfil from './assets/qub-profil.svg'
import styles from './Footer.styles.scss'

const Footer: FooterComponent = ({ appInfo }) => {
  return (
    <footer className={styles.footer}>
      <a href={appInfo.app_website_url}>
        <img src={qubProfil} className={styles.img} height="36" />
      </a>
      <p className={styles.contact}>Si vous avez des questions, besoin d’assistance, contactez notre service client ici : <a href="https://aide.profil.qub.ca/contact-us">Contactez-nous</a></p>
    </footer>
  )
}

export default Footer

import {FunctionalComponent, h} from 'preact';
import {Children, createContext} from "preact/compat";
import {useContext, useEffect, useMemo} from "preact/hooks";
import {useSettingsContext} from "../SettingsContext";
import {setCustomColors} from "../../utils/styles";
import {setCssVariables} from "./utils";
// @ts-ignore
import {Helmet} from "react-helmet";

type ThemeContextValue = {
  btnPrimary: string;
  headerBgColor: string;
}

const ThemeContext = createContext<ThemeContextValue | undefined>(undefined)

const ThemeProvider: FunctionalComponent = ({children}) => {
  const {application} = useSettingsContext()

  const isGoogleFont = useMemo(() => {
    return application.theme.fonts?.url?.includes('https://fonts.googleapis.com')
  }, [application.theme.fonts?.url])

  useEffect(() => {
    const headerFonts = [application.theme.fonts.heading, 'DM Sans', 'Arial', 'sans-serif'].filter(Boolean)
    const bodyFonts = [application.theme.fonts.body, 'DM Sans', 'Arial', 'sans-serif'].filter(Boolean)

    setCssVariables({
      '--font-family-header-key': headerFonts.join(', '),
      '--font-family-body-key': bodyFonts.join(', '),
      '--font-weight-base-key': `${application.theme.font_weights.body}` || '400',
      '--font-weight-header-key': `${application.theme.font_weights.header}` || '700',
    })
    setCustomColors(
        application.theme.colors.btn_primary || '#1E293B',
        application.theme.colors.header_bg,
        application.theme.colors.text_btn_primary,
        application.theme.colors.header_font
    )
  }, [application])

  return (
      <ThemeContext.Provider
          value={{
            btnPrimary: application.theme.colors.btn_primary,
            headerBgColor: application.theme.colors.header_bg,
          }}
      >
        {application.theme.fonts?.url && (
            <>
              <Helmet>
                <link href={application.theme.fonts?.url} rel="stylesheet" />
              </Helmet>
            </>
        )}

        {Children.only(children)}
      </ThemeContext.Provider>
  );
};

export default ThemeProvider;

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext must be used within ThemeProvider')
  }
  return context
}


import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const Icon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Shopping bag icon</title>
    <g clip-path="url(#clip0_266_16650)">
    <path d="M11.9999 0.47998C9.35238 0.47998 7.19988 2.63248 7.19988 5.27998V6.23998H3.40488L1.37988 24H22.6199L20.5949 6.23998H17.7599V9.11998C17.7599 9.91498 17.1149 10.56 16.3199 10.56C16.213 10.56 16.1043 10.5525 16.0049 10.53C15.3618 10.3856 14.8799 9.80811 14.8799 9.11998C14.8799 8.49561 15.283 7.96873 15.8399 7.76998V9.11998C15.8399 9.38436 16.0536 9.59998 16.3199 9.59998C16.5861 9.59998 16.7999 9.38436 16.7999 9.11998V5.27998C16.7999 2.63248 14.6474 0.47998 11.9999 0.47998ZM11.9999 1.43998C14.1168 1.43998 15.8399 3.16311 15.8399 5.27998V6.23998H9.11988V9.11998C9.11988 9.91498 8.47488 10.56 7.67988 10.56C7.57301 10.56 7.46426 10.5525 7.36488 10.53C6.71988 10.3856 6.23988 9.80811 6.23988 9.11998C6.23988 8.49561 6.64301 7.96873 7.19988 7.76998V9.11998C7.19988 9.38436 7.41551 9.59998 7.67988 9.59998C7.94426 9.59998 8.15988 9.38436 8.15988 9.11998V5.27998C8.15988 3.16311 9.88301 1.43998 11.9999 1.43998Z" fill="currentColor" />
    </g>
    <defs>
    <clipPath id="clip0_266_16650">
    <rect width="24" height="24" fill="white" />
    </clipPath>
    </defs>
  </svg>
)

export default Icon

import { Fragment, FunctionalComponent, h } from 'preact'
import styles from './StandAloneErrorPage.styles.scss'
import ErrorImg from './ErrorImg'
import useTranslation from '../../hooks/useTranslation'
import { AppInfo } from '../../interfaces/publishers'
import {useSettingsContext} from "../../contexts/SettingsContext";
import QubErrorPage from "./publishers/qub/QubErrorPage";
import {useRouteError} from "react-router-dom";


export interface ErrorPageProps {
  appInfo?: Pick<AppInfo, 'name' | 'app_website_url' | 'logo' | 'slug'>
  unauthorized?: boolean,
  invalidEmail?: boolean,
  pendingAuth?: boolean,
}

export type ErrorPageComponent = FunctionalComponent<ErrorPageProps>

const email = window.localStorage.getItem('email') || ''

const StandAloneErrorPage: ErrorPageComponent = () => {
  const { t } = useTranslation()
  const { appSlug, application } = useSettingsContext()
  const error = useRouteError() as { status: number, statusText: string } || {};
  const invalidEmail = error.statusText === '401-invalid-email'
  const pendingAuth = error.statusText === '401-pending-auth'


  if (appSlug === 'qub') return <QubErrorPage />

  return (
    <div className={styles.errorPage}>
      <div className={styles.descriptionBlock}>
        {(invalidEmail && appSlug !== 'ellecanada' && appSlug !== 'ellequebec') &&
          <Fragment>
          <h1 className={styles.title}>{t('standalone.errorPage.invalidEmailHeader')}</h1>
          <p>{t('standalone.errorPage.invalidEmail', { email })}</p>
          </Fragment>
        }
        {(pendingAuth || appSlug === 'ellecanada' || appSlug === 'ellequebec')&&
          <Fragment>
          <h1 className={styles.title}>{t('standalone.errorPage.oops')}</h1>
          <p>{t('standalone.errorPage.pendingAuth', { name: application.name })}</p>
         </Fragment>
        }
        {!invalidEmail && !pendingAuth &&
          <Fragment>
          <h1 className={styles.title}>{t('standalone.errorPage.oops')}</h1>
          <p>{t('standalone.errorPage.description')}</p>
          </Fragment>
        }
        {/*fixed height to avoid layout shift when the button appears*/}
        <div style={{ height: 56 }}>
          {application.app_website_url && application.name && (
            <a href={application.app_website_url} className={styles.btnLink}>
              {t('standalone.backLink', { name: application.name })}
            </a>
          )}
        </div>
      </div>
      <ErrorImg className={styles.errorImg} />
    </div>
  )
}

export default StandAloneErrorPage

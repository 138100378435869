import {FunctionalComponent, h} from 'preact';
import clsx from "clsx";
import styles from "./AdUnitPlaceholder.styles.scss";

const AdUnitPlaceholder: FunctionalComponent = (props) => {

  return (
      <div className={clsx(styles.adUnitPlaceholder, "placeholder-glow")}>
        <div className={clsx('placeholder', styles.placeholder)} />
      </div>
  );
};

export default AdUnitPlaceholder;


import {ComponentChildren, Fragment, h, VNode} from 'preact'
import { useEffect, useState } from 'preact/hooks'

interface Props {
  delay?: number
  fallbackComponent?: VNode | string | null
  children: ComponentChildren
}

const Delayed = ({ children, delay = 600, fallbackComponent }: Props): VNode => {
  const [isTimeoutFinished, setIsTimeoutFinished] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsTimeoutFinished(true)
    }, delay)
    return () => clearTimeout(timeoutId)
  }, [delay])

  if (isTimeoutFinished) {
    return <Fragment>{children}</Fragment>
  }

  return <Fragment>{fallbackComponent}</Fragment>
}

export default Delayed

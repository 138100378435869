import {FunctionalComponent, h} from 'preact';
import Section from "../../../Section";
import BrandSummary from "../../../BrandSummary";
import ClaimCta from "../../../claimCta/ClaimCta";
import {Reward} from "../../../../interfaces/sdk";
import useReward from "../../../../hooks/useReward";
import styles from './CardBackContent.style.scss'
import Button from "../../../Button";
import {toLocalDateString} from "../../../../utils/strings";
import RichText from "../../../RichText";
import ExpandableContainer from "../../../ExpandableContainer";
import TermsAndConditions from "../../../TermsAndConditions";
import {useTranslation} from "../../../../contexts/I18nContext";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import {useState} from "preact/compat";
import {useEffect} from "preact/hooks";
import useIsTouchDevice from "../../../../hooks/useIsTouchDevice";
import {Swiper as SwiperType} from "swiper/types";
import NavLeftIcon from "../../../icons/NavLeftIcon";
import IconButton from "../../../icons/IconButton";
import NavRightIcon from "../../../icons/NavRightIcon";
import {useEffectOnceWhen} from "rooks";
import useEvents, {rewardIds} from "../../../../hooks/useEvents";
import clsx from "clsx";

interface Props {
  reward: Reward;
  onBackClick: () => unknown;
  flipped: boolean;
}

const SLIDES_AMOUNT = 2

const CardBackContent: FunctionalComponent<Props> = ({reward, onBackClick, flipped }) => {
  const claimId = reward.claimed?.[0].claim_id || ''

  const {t, locale} = useTranslation()
  const [swiper, setSwiper] = useState<SwiperType>()
  const {
    isLoading,
    isFetching,
    claimLoading,
    claimedData,
    handleClaim,
    handleShopClick,
    handleResendEmail,
    handleCopyCodeClick,
    isClaimed,
    redirectUrl,
  } = useReward({rewardId: reward.id, requestId: '-', enabled: flipped, claimId})
  const breakpoint = useBreakpoint()
  const isTouchDevice = useIsTouchDevice()
  const raiseEvent = useEvents()
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  useEffect(() => {
    if (!swiper) {
      return
    }
    swiper.allowTouchMove = flipped && (breakpoint === 'xs' || breakpoint === 'sm')

    if (!flipped) {
      //setTimeout to improve visual effect of card flipping
      setTimeout(() => {
        swiper.slideTo(0)
      }, 150)
    }
  }, [breakpoint, flipped, swiper])

  useEffectOnceWhen(async () => {
    raiseEvent({
      name: 'reward-view',
      request_id: '-',
      ...rewardIds(reward)
    })
  }, !!reward)

  if (isLoading) return <div>loading...</div>

  const {brand_name, brand_logo, steps_to_redeem, offer_details, brand_about, terms, terms_url} = reward

  return (
      <div className={styles.cardBackContent}>
        <Button
            link
            onClick={onBackClick}
            className={styles.backButton}
        >
          {t('common.back')}
        </Button>
        <Swiper
            className={styles.rewardDetailsSwiper}
            modules={[Pagination, Navigation]}
            slidesPerView={1}
            pagination={{clickable: true}}
            navigation={{
              prevEl: `.${styles.prevBtn}`,
              nextEl: `.${styles.nextBtn}`,
            }}
            onSwiper={(swiper: SwiperType) => setSwiper(swiper)}
            onActiveIndexChange={(swiper: SwiperType) => setActiveSlideIndex(swiper.activeIndex)}
        >
          <SwiperSlide className={styles.slide1}>
            <Section style={{padding: 8}} textCenter extraClass={styles.section} titleClass={styles.sectionTitle}>
              <BrandSummary name={brand_name} logo={brand_logo} />
              <h2 className='h6 mb-2 lh-sm'>
                {reward.offer_title}
              </h2>
              {!isFetching &&
                <ClaimCta
                  reward={reward}
                  claimLoading={claimLoading}
                  claimData={claimedData}
                  onClaimClicked={(_, userConsent) => handleClaim(false, userConsent)}
                  onShopClick={handleShopClick}
                  onResendEmail={handleResendEmail}
                  onCopyCodeClick={handleCopyCodeClick}
                  redirectUrl={redirectUrl}
                />
              }
            </Section>
          </SwiperSlide>
          <SwiperSlide className={styles.slide2}>
            <Section title={t('reward.expiration.title')} reducePadding extraClass={styles.section} titleClass={styles.sectionTitle}>
              {reward.discount_end_date
                  ? toLocalDateString(reward.discount_end_date, locale)
                  : toLocalDateString(reward.end_date, locale)
              }
            </Section>

            {isClaimed && Boolean(steps_to_redeem) && (
                <Section title={t('reward.redeem.title')} reducePadding extraClass={styles.section} titleClass={styles.sectionTitle}>
                  <RichText text={steps_to_redeem} />
                </Section>
            )}

             {Boolean(offer_details) && (
                <Section title={t('reward.details.title')} reducePadding extraClass={styles.section} titleClass={styles.sectionTitle}>
                  <ExpandableContainer>
                    <RichText text={offer_details} />
                  </ExpandableContainer>
                </Section>
            )}

            {!isClaimed && Boolean(brand_about) && (
                <Section title={t('reward.about.title')} reducePadding extraClass={styles.section} titleClass={styles.sectionTitle}>
                  <ExpandableContainer>
                    <RichText text={brand_about} />
                  </ExpandableContainer>
                </Section>
            )}

            {Boolean(terms) && (
                <Section title={t('reward.terms.title')} reducePadding extraClass={styles.section} titleClass={styles.sectionTitle}>
                  <ExpandableContainer
                      maxCollapsedHeight={0}
                      hideLinkText={t('reward.terms.hide')}
                      showLinkText={t('reward.terms.show')}
                      expandBtnClass={styles.expandBtnLink}
                  >
                    <RichText text={terms} />
                  </ExpandableContainer>
                </Section>
            )}

            <Section>
              <TermsAndConditions url={terms_url} />
            </Section>
          </SwiperSlide>
          {!isTouchDevice &&
            <div className={styles.backCardNav}>
              <IconButton className={clsx(styles.prevBtn, {'d-none': activeSlideIndex === 0})}>
                <NavLeftIcon />
              </IconButton>
              <IconButton className={clsx(styles.nextBtn, {'d-none': activeSlideIndex === SLIDES_AMOUNT - 1})}>
                <NavRightIcon />
              </IconButton>
            </div>
          }
        </Swiper>
      </div>
  );
};

export default CardBackContent;


import {useCallback} from "preact/hooks";
import {ClaimData, RewardsResponse} from "../interfaces/sdk";
import {queries} from "../queryKeyFactory";
import {produce} from "immer";
import {useSettingsContext} from "../contexts/SettingsContext";
import {useQueryClient} from "@tanstack/react-query";

const useRewardsClaimData = () => {
  const {initialParentRewardId, allRewardsLimit} = useSettingsContext()
  const queryClient = useQueryClient()

  return useCallback((publicRewardId: string, claimData: ClaimData) => {
    queryClient.setQueryData<RewardsResponse>(
        queries.rewards.list({
          list_type: 'claimable',
          reward_id: initialParentRewardId,
          limit: allRewardsLimit
        }).queryKey,
        (rewardsQuery) => {
          if (!rewardsQuery?.data?.payload) {
            return undefined
          }
          return produce(rewardsQuery, draft => {
            const rewardToUpdate = draft.data.payload.find(reward => reward.id === publicRewardId)
            if (rewardToUpdate) {
              rewardToUpdate.claimed = [claimData]
            }
          })
        })

  }, [allRewardsLimit, initialParentRewardId, queryClient])

}

export default useRewardsClaimData

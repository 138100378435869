import useEvents from "../hooks/useEvents";
import {useDidMount} from "rooks";
import {globalEvent} from "../global";
import {useSettingsContext} from "../contexts/SettingsContext";

const AppViewEvent = () => {
  const raiseEvent = useEvents()
  const {newsletterBannerClickId} = useSettingsContext()

  useDidMount(() => {
    if (!globalEvent.appViewId) {
      raiseEvent({name: 'app-view', newsletter_banner_click_id: newsletterBannerClickId})
    }
  })

  return null
}

export default AppViewEvent

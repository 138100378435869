import {FunctionalComponent} from 'preact';
import {Reward} from "../interfaces/sdk";
import RewardThumbnail from "./RewardThumbnail";

interface Props {
  rewards: Reward[];
  requestId?: string;
}

const RewardsList: FunctionalComponent<Props> = ({rewards, requestId = ''}) => {
  return (
      <>
        {rewards.map((reward: Reward) => {
          return (
            <div key={reward.id} className="col-12 col-md-auto">
              <RewardThumbnail
                  reward={reward}
              />
            </div>
          )
        })}
      </>
  );
};

export default RewardsList;


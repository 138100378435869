import { ClaimResponse, RewardResponse, RewardsResponse } from '../interfaces/sdk';
import { authAxios } from './auth';
import swap from "../utils/swap";
import {pageInfo, settings} from "../global";

export const claimReward = async (rewardId = '', requestId = '', lang = '', userConsent: string | undefined) => {
  const body = {
    user_consent: userConsent,
  }
  const { data } = await authAxios.post<ClaimResponse>(
    `sdk/claim/rewards/${rewardId}/${requestId}`, body,
    { headers: { lang}, params: { page_info_url: pageInfo.url } }
  )

  return data
}

export const resendRewardEmail = async (claimId: string, requestId: string) => {
  const body = { claimId, requestId}
  const { data } = await authAxios.post('sdk/rewards/resend-email', body, { params: { page_info_url: pageInfo.url } })

  return data
}

type FetchRewardsParams = {
  list_type: 'claimable' | 'claimed' | '';
  limit?: number,
  reward_id?: string,
}

export const fetchRewards = async (params: FetchRewardsParams) => {
  let reqPath = 'sdk/rewards';
  if (params.list_type) {
    reqPath = `${reqPath}/${params.list_type}`;
  }
  const {data} = await authAxios.get<RewardsResponse>(
      reqPath,
      {
        params: {
          limit: params.limit,
          reward_id: params.reward_id,
          ...{
            page_info_url: pageInfo.url,
            page_info_tags: pageInfo.tags,
          },
          init_source: settings.initSource
        }
      }
  )

  // Put the reward at first position in the list
  if (params.reward_id && params.list_type !== 'claimed') {
    const rewardIndex = data.data.payload.findIndex(reward => reward.id === params.reward_id)
    if (rewardIndex !== -1) {
      swap(data.data.payload, 0, rewardIndex)
    }
  }

  return data
}

export const fetchOneReward = async (rewardId: string, requestId: string) => {
  const {data} = await authAxios.get<RewardResponse>(`sdk/rewards/${rewardId}/${requestId}`, { params: { page_info_url: pageInfo.url }})

  return data
}

export const fetchClaimedReward = async (claimId:string) => {
  const {data} = await authAxios.get<RewardResponse>(`sdk/rewards/claimed/${claimId}`, { params: { page_info_url: pageInfo.url }})

  return data
}

import { FunctionalComponent } from 'preact'
import styles from './BrandSummary.style.scss'

interface Props {
  name: string;
  logo: string;
}

const BrandSummary: FunctionalComponent<Props> = ({ name, logo }: Props) => {
  return (
    <header>
      <div className={styles.heading}>
        <img className={styles.logo} src={logo} />
        <h1 className={styles.brandName}>{name}</h1>
      </div>
    </header>
  )
}

export default BrandSummary

import styles from './StandAloneLayout.style.scss'
import {Outlet} from "react-router-dom";
import PageMeta from "../../standalone/PageMeta";
import Header from "../../standalone/Header";
import {useSettingsContext} from "../../../contexts/SettingsContext";
import {isContextValidForPublisher} from "../../standalone/publishers";
import QubFooter from "../../standalone/publishers/qub/Footer";
import Footer from "../../standalone/Footer";
import QubHeader from "../../standalone/publishers/qub/Header";

const StandAloneLayout = () => {
  const {application, appSlug, context} = useSettingsContext()

  if (!isContextValidForPublisher(appSlug, context)) {
    throw new Response('', {status: 404, statusText: 'Invalid Context'})
  }

  return (
      <div className={styles.StandAloneLayout}>
        <PageMeta />
        {appSlug === 'qub' ? (
            <QubHeader appInfo={application} />
        ) : (
            <>
              {application.logo &&
                <Header appInfo={application} />
              }
            </>
        )}
        <Outlet />
        <div className={styles.footerWrapper}>
          <div className="container">
            {appSlug === 'qub' ? (
                <QubFooter appInfo={application} />
            ) : (
                <Footer appInfo={application} />
            )}
          </div>
        </div>
      </div>
  );
};

export default StandAloneLayout;


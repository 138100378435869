import { useQubProperty } from './qubProperties'
import { HeaderComponent } from '../../Header'
import style from './Header.styles.scss'

const Header: HeaderComponent = () => {
  const property = useQubProperty()

  return (
    <header className={style.header}>
      <div className={style.logo}>
        <a href={property.app_website_url}>
          <img src={property.logo} className={style.img} alt={property.name} />
        </a>
      </div>
      <div className={style.headerTitle}>
        <h2>RÉCOMPENSES AUX</h2>
        <h1>MEMBRES CONNECTÉS!</h1>
      </div>
    </header>
  )
}

export default Header

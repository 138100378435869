import styles from './PanelLayout.style.scss'
import {Outlet, useOutletContext} from "react-router-dom";
import OpenedPanelContainer from "../../OpenedPanelContent";
import NavBar from "../../NavBar";
import {useState} from "preact/hooks";
import useEvents from "../../../hooks/useEvents";
import {useDidMount, useDidUpdate} from "rooks";
import useAuth from "../../../hooks/useAuth";
import {parseToHsla} from "color2k";
import {useSettingsContext} from "../../../contexts/SettingsContext";
// @ts-ignore
import Postmate from "postmate";
import {pageInfo} from "../../../global";
import usePageViewEvent from "../../../hooks/events/usePageViewEvent";

const PanelLayout = () => {
  const [childApi, setChildApi] = useState<ChildAPI>()
  const {application} = useSettingsContext()
  const [isPanelOpened, setIsPanelOpened] = useState(false)
  const raiseEvent = useEvents()
  const {refetchUser} = useAuth()
  const raisePageViewEvent = usePageViewEvent()

  useDidMount(() => {
    const handshake = new Postmate.Model('gl-rewards-panel', {
      updatePageInfoUrl: (payload:any) => {
        pageInfo.url = payload.newUrl as string
      },
      pageView: (payload: any) => {
        raisePageViewEvent(payload)
      },
      colors: () => ({
        keyHsla: parseToHsla(application.theme.colors.btn_primary),
        textColor: (application.theme.colors.text_btn_primary)
      }),
      open: (payload: any) => {
        setIsPanelOpened(true)
        raiseEvent({name: 'panel-open', action_source: payload})
      },
      close: (payload: any) => {
        setIsPanelOpened(false)
        raiseEvent({name: 'panel-close', action_source: payload as any})
      },
    });
    handshake.then((parent: ChildAPI) => {
      setChildApi(parent)
    });
  })

  useDidUpdate(() => {
    if (isPanelOpened) {
      refetchUser()
    }
  }, [isPanelOpened])

  if (!childApi) return null

  return (
      <div className={styles.PanelLayout}>
        <OpenedPanelContainer isPanelOpened={isPanelOpened}>
          <div>
            <NavBar childApi={childApi} />
            <Outlet context={{isPanelOpened}} />
          </div>
        </OpenedPanelContainer>
      </div>
  );
};

type ContextType = {
  isPanelOpened: boolean
}
export const usePanelLayoutContext = () => {
  return useOutletContext<ContextType>()
}

export default PanelLayout;


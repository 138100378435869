import styles from './Button.style.scss'
import clsx from 'clsx'
import {Fragment, FunctionalComponent, h, VNode} from 'preact'
import LoadingSpinner from "./LoadingSpinner";

type Props = Omit<h.JSX.HTMLAttributes<HTMLButtonElement>, 'icon'> & {
    primary?: boolean;
    secondary?: boolean;
    outlined?: boolean;
    fullWidth?: boolean;
    isLoading?: boolean;
    link?: boolean;
    icon?: VNode;
    inContentRedeem?: boolean;
}

const Button: FunctionalComponent<Props> = ({
        children,
        icon,
        primary = true,
        secondary,
        outlined,
        fullWidth = true,
        className,
        isLoading,
        disabled,
        link = false,
        inContentRedeem = false,
        ...props
}) => {
    return (
        <button
            type="button"
            className={clsx(
                styles.btn,
                className,
                {
                    [styles.btn_primary]: (primary && !link),
                    [styles.btn_secondary]: secondary,
                    [styles.btn_outlined]: outlined,
                    [styles.btn_full_width]: fullWidth,
                    [styles.btn_link]: link,
                    [styles.btn_in_content_redeem]: inContentRedeem,
                })
            }
            disabled={disabled || isLoading}
            {...props}
        >
            <LoadingSpinner loading={isLoading}>
                {!!icon && <Fragment>{icon}</Fragment>}
                {children}
            </LoadingSpinner>
        </button>
    )
}

export default Button

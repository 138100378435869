import { useEffect } from 'preact/hooks'
import throttle from "lodash/throttle";

export const APP_SCROLL_EVENT_NAME = 'app-scroll'

const useAppSrollListener = (callback) => {
  useEffect(() => {
    const throttledCallback = throttle(callback, 1200, {leading: false, trailing: true})
    document.addEventListener(APP_SCROLL_EVENT_NAME, throttledCallback);
    return () => {
      document.removeEventListener(APP_SCROLL_EVENT_NAME, throttledCallback)
    }
  }, [callback])
}

export default useAppSrollListener

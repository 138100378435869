import {FunctionalComponent, h} from 'preact';

const PanelCloseIcon: FunctionalComponent = (props) => {

  return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.65408 5.32028C6.38884 5.32035 6.12964 5.39952 5.90963 5.54767C5.68962 5.69583 5.51878 5.90623 5.41898 6.15198C5.31917 6.39772 5.29491 6.66766 5.34932 6.92726C5.40372 7.18687 5.53432 7.42435 5.7244 7.60934L14.115 16L5.7244 24.3906C5.59643 24.5135 5.49427 24.6606 5.42389 24.8235C5.35351 24.9863 5.31633 25.1615 5.31452 25.3389C5.31272 25.5163 5.34633 25.6923 5.41338 25.8565C5.48043 26.0207 5.57958 26.17 5.70502 26.2954C5.83046 26.4208 5.97966 26.52 6.1439 26.587C6.30813 26.6541 6.4841 26.6877 6.66149 26.6859C6.83887 26.6841 7.01412 26.6469 7.17696 26.5765C7.33979 26.5061 7.48695 26.404 7.60981 26.276L16.0004 17.8854L24.3911 26.276C24.5139 26.404 24.6611 26.5061 24.8239 26.5765C24.9868 26.6469 25.162 26.6841 25.3394 26.6859C25.5168 26.6877 25.6927 26.6541 25.857 26.587C26.0212 26.52 26.1704 26.4208 26.2959 26.2954C26.4213 26.17 26.5205 26.0208 26.5875 25.8565C26.6546 25.6923 26.6882 25.5163 26.6864 25.3389C26.6846 25.1615 26.6474 24.9863 26.577 24.8235C26.5066 24.6606 26.4044 24.5135 26.2765 24.3906L17.8859 16L26.2765 7.60934C26.4691 7.42206 26.6007 7.18094 26.654 6.91758C26.7073 6.65422 26.6798 6.38091 26.5751 6.13346C26.4703 5.88601 26.2933 5.67597 26.0672 5.53087C25.841 5.38576 25.5763 5.31236 25.3077 5.32028C24.9613 5.3306 24.6326 5.47538 24.3911 5.72393L16.0004 14.1146L7.60981 5.72393C7.48556 5.5962 7.33697 5.49468 7.17281 5.42535C7.00866 5.35602 6.83228 5.32029 6.65408 5.32028Z"
            fill="#595959" />
      </svg>

  );
};

export default PanelCloseIcon;


import {h, FunctionalComponent} from 'preact'

type Props = h.JSX.HTMLAttributes<SVGElement> & {
  fill?: string
}

const ApprovalIcon: FunctionalComponent<Props> = ({
                                                    width = 35,
                                                    height = 36,
                                                    fill = '#454545',
                                                  }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 35 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M31.9207 16.9781L29.2007 14.1667L29.7479 10.2938C29.8593 9.50526 29.325 8.77097 28.5407 8.6324L24.6879 7.95669L22.855 4.5024C22.4836 3.80097 21.6193 3.51954 20.905 3.86954L17.3907 5.58669L13.8765 3.86954C13.1622 3.51954 12.2979 3.80097 11.925 4.50383L10.0922 7.95954L6.23932 8.63526C5.45503 8.7724 4.92075 9.50669 5.03217 10.2953L5.57932 14.1681L2.85932 16.9795C2.30503 17.5524 2.30503 18.4595 2.85932 19.0324L5.57932 21.8438L5.03217 25.7167C4.92075 26.5053 5.45503 27.2395 6.23932 27.3781L10.0922 28.0538L11.925 31.5081C12.2979 32.211 13.1622 32.4924 13.8779 32.1424L17.3907 30.4238L20.905 32.141C21.6207 32.491 22.4836 32.2095 22.8579 31.5067L24.6907 28.0524L28.5436 27.3767C29.3279 27.2395 29.8622 26.5038 29.7507 25.7153L29.2036 21.8424L31.9236 19.031C32.4736 18.4595 32.4736 17.551 31.9207 16.9781ZM15.9622 24.311L10.6665 19.0153L12.6865 16.9953L15.9622 20.271L23.5236 12.7095L25.5436 14.7295L15.9622 24.311Z"
            fill={fill}
        />
      </svg>
  )
}

export default ApprovalIcon

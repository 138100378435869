import { h, FunctionalComponent } from "preact"
import styles from './IconButton.style.scss'
import clsx from 'clsx'

type Props = h.JSX.HTMLAttributes<HTMLButtonElement>

const IconButton: FunctionalComponent<Props> = ({ children, className, ...props }) => {
  return (
    <button type="button" className={clsx([styles.iconButton, className])} {...props}>
      {children}
    </button>
  )
}

export default IconButton

import styles from './InContentLayout.style.scss'
import {Outlet, useOutletContext} from "react-router-dom";
import {globalEvent, pageInfo, settings} from "../../../global";
// @ts-ignore
import Postmate from 'postmate'
import {useState} from "preact/hooks";
import {useDidMount, useEffectOnceWhen, useResizeObserverRef} from "rooks";
import usePageViewEvent from "../../../hooks/events/usePageViewEvent";
import {queries} from "../../../queryKeyFactory";
import {useQueryClient} from "@tanstack/react-query";
import {useSettingsContext} from "../../../contexts/SettingsContext";
import {setAuthAxiosHeaders} from "../../../services/auth";
import {ClaimData, User} from "../../../interfaces/sdk";
import useAuth from "../../../hooks/useAuth";
import useEvents from "../../../hooks/useEvents";
import {StateUpdater} from "preact/compat";
import useRewardsClaimData from "../../../hooks/useRewardsClaimData";

const InContentLayout = () => {
  const [openedRewardId, setOpenedRewardId] = useState('')
  const {allRewardsLimit, activeIntegration} = useSettingsContext()
  const [childApi, setChildApi] = useState<ChildAPI>()
  const raisePageViewEvent = usePageViewEvent()
  const queryClient = useQueryClient()
  const {dispatchAuth} = useAuth()
  const raiseEvent = useEvents()
  const [containerRef] = useResizeObserverRef(() => {
    if (childApi) {
      childApi.emit('set-iframe-height', document.body.clientHeight)
    }
  });
  const setClaimData = useRewardsClaimData()

  useEffectOnceWhen(() => {
    if (childApi) {
      childApi.emit('in-content-app-loaded', null)
    }
  }, Boolean(childApi))

  useDidMount(() => {
    let handshake
    if (settings.isStorefrontOverlay) {
      handshake = new Postmate.Model(`gl-storefront-overlay-${activeIntegration?.id}`, {
        updatePageInfoUrl: (payload: any) => {
          pageInfo.url = payload.newUrl
        },
      })
    } else {
      handshake = new Postmate.Model(`gl-rewards-carousel-${activeIntegration?.id}`, {
        setPreviousEventId: (previousEventId: string) => {
          globalEvent.previousEventId = previousEventId
        },
        storefrontOverlayClosed: () => {
          setOpenedRewardId('')
          raiseEvent({name: 'storefront-overlay-close'})
        },
        updatePageInfoUrl: (payload: any) => {
          pageInfo.url = payload.newUrl
        },
        pageView: (payload: any) => {
          raisePageViewEvent(payload)
        },
        reloadRewards: (parentRewardId: string) => {
          void queryClient.fetchQuery({
            ...queries.rewards.list({
              list_type: 'claimable',
              reward_id: parentRewardId,
              limit: allRewardsLimit
            }),
          })
        },
        setUser: (user: User) => {
          setAuthAxiosHeaders(user.token, user.appKeyId)
          dispatchAuth({type: 'auth_success', payload: {email: user.email}});
        },
        setRewardClaimData: (payload: { publicRewardId: string, claimData: ClaimData}) => {
          setClaimData(payload.publicRewardId, payload.claimData)
        }
      });

    }
    handshake.then((parent: ChildAPI) => {
      setChildApi(parent)
    });
  })

  if (!childApi) return null

  return (
      <div className={styles.InContentLayout} ref={containerRef}>
        <Outlet context={{childApi, openedRewardId, setOpenedRewardId}} />
      </div>
  );
};

type ContextType = {
  childApi: ChildAPI
  openedRewardId: string
  setOpenedRewardId: StateUpdater<string>
}
export const useInContentLayoutContext = () => {
  return useOutletContext<ContextType>() || {}
}

export default InContentLayout;


import { FunctionalComponent, h } from 'preact'
import { useTranslation } from '../../contexts/I18nContext'
import { AppInfo } from '../../interfaces/publishers'
import styles from './Footer.styles.scss'

export interface FooterProps {
  appInfo: Pick<AppInfo, 'name' | 'app_website_url' | 'logo'>
}

export type FooterComponent = FunctionalComponent<FooterProps>

const Footer: FooterComponent = ({ appInfo }) => {
  const { t } = useTranslation()
  return (
    <footer className={styles.footer}>
      {appInfo.app_website_url && appInfo.name && (
        <a href={appInfo.app_website_url}>{t('standalone.backLink', { name: appInfo.name })}</a>
      )}
    </footer>
  )
}

export default Footer

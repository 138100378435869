import {Fragment, FunctionalComponent, h} from 'preact';
import DiscountCode from "../DiscountCode";
import SignInForm from "../SignInForm";
import toast from "react-hot-toast";
import PrivacyPolicy from "../PrivacyPolicy";
import {useTranslation} from "../../contexts/I18nContext";
import {useSettingsContext} from "../../contexts/SettingsContext";
import useAppPhrases from "../../hooks/useAppPhrases";
import {Reward} from "../../interfaces/sdk";
import useEvents from "../../hooks/useEvents";
import {OnClaimClicked, PrivacyTextData} from "./claimCta.types";
import {safeLocalStorage} from "../../utils/storage";
import {useQueryClient} from "@tanstack/react-query";
import {queries} from "../../queryKeyFactory";
import {useInContentLayoutContext} from "../layouts/InContentLayout/InContentLayout";

interface Props {
  textData: PrivacyTextData
  reward: Reward
  onClaimClicked: OnClaimClicked
}

const AnonymousClaimable: FunctionalComponent<Props> = ({textData, reward, onClaimClicked}) => {
  const {t} = useTranslation()
  const {application, setFirstClaimRewardId, allRewardsLimit, storeFrontType} = useSettingsContext()
  const {childApi} = useInContentLayoutContext()
  const tAppPhrases = useAppPhrases()
  const raiseEvent = useEvents()
  const queryClient = useQueryClient()

  const {show_claim_consent, claim_btn_text} = reward

  let privacyText = t('privacyPolicy.login--html', textData)

  if (application.newsletter_subscription_enabled && show_claim_consent) {
    privacyText = tAppPhrases('consent_claim_shared_to_publisher_brand', textData) || t('privacyPolicy.claim-shared-to-publisher-and-brand', textData)
  }
  if (application.newsletter_subscription_enabled && !show_claim_consent) {
    privacyText = tAppPhrases('consent_claim_shared_to_publisher', textData) || t('privacyPolicy.claim-shared-to-publisher', textData)
  }
  if (!application.newsletter_subscription_enabled && show_claim_consent) {
    privacyText = tAppPhrases('consent_claim_shared_to_brand', textData) || t('privacyPolicy.claim-shared-to-brand', textData)
  }

  const handleSignInAttempt = () => {
    void raiseEvent({name: 'profile-sign-in', view_context: 'reward-view'})
  }

  return (
      <Fragment>
        <DiscountCode codeType={reward.code_type} redact />
        <SignInForm
            ctaButtonText={claim_btn_text || t('reward.btn.claim')}
            onSignInAttempt={() => {
              setFirstClaimRewardId(reward.id)
              handleSignInAttempt()
            }}
            onSignInSuccess={async (user) => {
              onClaimClicked(reward.id, privacyText)

              if (storeFrontType === 'overlay') {
                if (childApi) {
                  childApi.emit('set-user', user)
                  childApi.emit('reload-rewards', reward.reward_id)
                }
              } else {
                await queryClient.fetchQuery({
                  ...queries.rewards.list({
                    list_type: 'claimable',
                    reward_id: reward.reward_id,
                    limit: allRewardsLimit
                  }),
                })
              }


            }}
            onSignInFail={(error) => {
              const errorMessage = (error as any)?.response?.data?.message ?? t('errors.generic')
              toast.error(errorMessage)
            }}
            newsletterMannerOfConsent='claim'
            from="claim"
        />
        <PrivacyPolicy text={privacyText} />
      </Fragment>
  )
};

export default AnonymousClaimable;


import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const BackIcon: FunctionalComponent<Props> = (props) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M18.5243 28C20.2963 28 21.3537 26.0253 20.371 24.5507L14.671 16L20.371 7.44933C21.3537 5.97467 20.2963 4 18.5243 4C17.783 4 17.0897 4.37067 16.6777 4.988L10.323 14.5213C9.72567 15.4173 9.72567 16.584 10.323 17.48L16.6777 27.0133C17.0897 27.6293 17.783 28 18.5243 28Z"
            fill="#595959" />
    </svg>

)

export default BackIcon

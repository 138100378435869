import { FunctionalComponent, h } from 'preact'

interface Props {
  className?: string
}

const ErrorImg: FunctionalComponent<Props> = (props) => {
  return (
    <svg
      width="130"
      height="119"
      viewBox="0 0 130 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M117.553 41.9768H113.974C113.578 41.9768 113.257 41.6556 113.257 41.2599C113.257 40.8641 113.578 40.543 113.974 40.543H117.553C117.948 40.543 118.269 40.8641 118.269 41.2599C118.269 41.6556 117.948 41.9768 117.553 41.9768Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M124.815 41.9768H121.238C120.843 41.9768 120.521 41.6556 120.521 41.2599C120.521 40.8641 120.843 40.543 121.238 40.543H124.815C125.211 40.543 125.532 40.8641 125.532 41.2599C125.532 41.6556 125.211 41.9768 124.815 41.9768Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M119.612 39.9179C119.217 39.9179 118.896 39.5967 118.896 39.201V35.6222C118.896 35.2264 119.217 34.9053 119.612 34.9053C120.008 34.9053 120.329 35.2264 120.329 35.6222V39.201C120.329 39.5967 120.008 39.9179 119.612 39.9179Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M119.612 47.6152C119.217 47.6152 118.896 47.294 118.896 46.8983V43.3195C118.896 42.9237 119.217 42.6025 119.612 42.6025C120.008 42.6025 120.329 42.9237 120.329 43.3195V46.8983C120.329 47.294 120.008 47.6152 119.612 47.6152Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.29574 65.2619H0.716912C0.321176 65.2619 0 64.9408 0 64.545C0 64.1493 0.321176 63.8281 0.716912 63.8281H4.29574C4.69147 63.8281 5.01265 64.1493 5.01265 64.545C5.01265 64.9408 4.69147 65.2619 4.29574 65.2619Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5585 65.2619H7.98156C7.58582 65.2619 7.26465 64.9408 7.26465 64.545C7.26465 64.1493 7.58582 63.8281 7.98156 63.8281H11.5585C11.9542 63.8281 12.2754 64.1493 12.2754 64.545C12.2754 64.9408 11.9542 65.2619 11.5585 65.2619Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35461 63.2031C5.95887 63.2031 5.6377 62.8819 5.6377 62.4862V58.9073C5.6377 58.5116 5.95887 58.1904 6.35461 58.1904C6.75034 58.1904 7.07152 58.5116 7.07152 58.9073V62.4862C7.07152 62.8819 6.75034 63.2031 6.35461 63.2031Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35461 70.9003C5.95887 70.9003 5.6377 70.5792 5.6377 70.1834V66.6046C5.6377 66.2089 5.95887 65.8877 6.35461 65.8877C6.75034 65.8877 7.07152 66.2089 7.07152 66.6046V70.1834C7.07152 70.5792 6.75034 70.9003 6.35461 70.9003Z"
        fill="black"
      />
      <mask
        id="mask0_1_4794"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="63"
        y="1"
        width="4"
        height="4"
      >
        <path d="M63.293 1.91113H66.2371V4.85124H63.293V1.91113Z" fill="black" />
      </mask>
      <g mask="url(#mask0_1_4794)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.765 2.48466C64.2699 2.48466 63.8665 2.88613 63.8665 3.38128C63.8665 3.87643 64.2699 4.2779 64.765 4.2779C65.2602 4.2779 65.6636 3.87643 65.6636 3.38128C65.6636 2.88613 65.2602 2.48466 64.765 2.48466ZM64.765 4.85143C63.9544 4.85143 63.293 4.19378 63.293 3.38128C63.293 2.57069 63.9544 1.91113 64.765 1.91113C65.5775 1.91113 66.2371 2.57069 66.2371 3.38128C66.2371 4.19378 65.5775 4.85143 64.765 4.85143Z"
          fill="black"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.5812 9.3596H59.1612C58.7655 9.3596 58.4443 9.03843 58.4443 8.64269C58.4443 8.24696 58.7655 7.92578 59.1612 7.92578H63.5812C63.977 7.92578 64.2982 8.24696 64.2982 8.64269C64.2982 9.03843 63.977 9.3596 63.5812 9.3596Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.642 24.6731H11.4972C11.1015 24.6731 10.7803 24.3519 10.7803 23.9562C10.7803 23.5604 11.1015 23.2393 11.4972 23.2393H14.642C15.0378 23.2393 15.359 23.5604 15.359 23.9562C15.359 24.3519 15.0378 24.6731 14.642 24.6731Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.0258 24.6731H17.881C17.4852 24.6731 17.1641 24.3519 17.1641 23.9562C17.1641 23.5604 17.4852 23.2393 17.881 23.2393H21.0258C21.4216 23.2393 21.7427 23.5604 21.7427 23.9562C21.7427 24.3519 21.4216 24.6731 21.0258 24.6731Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4513 22.8629C16.0556 22.8629 15.7344 22.5417 15.7344 22.1459V19.0011C15.7344 18.6054 16.0556 18.2842 16.4513 18.2842C16.847 18.2842 17.1682 18.6054 17.1682 19.0011V22.1459C17.1682 22.5417 16.847 22.8629 16.4513 22.8629Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4513 29.6285C16.0556 29.6285 15.7344 29.3073 15.7344 28.9116V25.7667C15.7344 25.371 16.0556 25.0498 16.4513 25.0498C16.847 25.0498 17.1682 25.371 17.1682 25.7667V28.9116C17.1682 29.3073 16.847 29.6285 16.4513 29.6285Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.5765 14.5614H66.0893C65.7757 14.5614 65.5215 14.3071 65.5215 13.9936C65.5215 13.682 65.7757 13.4277 66.0893 13.4277H68.5765C68.89 13.4277 69.1443 13.682 69.1443 13.9936C69.1443 14.3071 68.89 14.5614 68.5765 14.5614Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.6273 14.5614H71.1401C70.8265 14.5614 70.5723 14.3071 70.5723 13.9936C70.5723 13.682 70.8265 13.4277 71.1401 13.4277H73.6273C73.9408 13.4277 74.1951 13.682 74.1951 13.9936C74.1951 14.3071 73.9408 14.5614 73.6273 14.5614Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.0092 13.1296C69.6957 13.1296 69.4414 12.8754 69.4414 12.5618V10.0746C69.4414 9.7611 69.6957 9.50684 70.0092 9.50684C70.3227 9.50684 70.577 9.7611 70.577 10.0746V12.5618C70.577 12.8754 70.3227 13.1296 70.0092 13.1296Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.0092 18.4822C69.6957 18.4822 69.4414 18.2279 69.4414 17.9144V15.4253C69.4414 15.1136 69.6957 14.8594 70.0092 14.8594C70.3227 14.8594 70.577 15.1136 70.577 15.4253V17.9144C70.577 18.2279 70.3227 18.4822 70.0092 18.4822Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.13822 15.7532H2.14219C1.98352 15.7532 1.85352 15.6251 1.85352 15.4665C1.85352 15.3078 1.98352 15.1797 2.14219 15.1797H3.13822C3.2969 15.1797 3.42499 15.3078 3.42499 15.4665C3.42499 15.6251 3.2969 15.7532 3.13822 15.7532Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.16361 15.7532H4.16758C4.00891 15.7532 3.87891 15.6251 3.87891 15.4665C3.87891 15.3078 4.00891 15.1797 4.16758 15.1797H5.16361C5.32229 15.1797 5.45038 15.3078 5.45038 15.4665C5.45038 15.6251 5.32229 15.7532 5.16361 15.7532Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.71356 15.1789C3.55489 15.1789 3.42871 15.0508 3.42871 14.8921V13.8942C3.42871 13.7355 3.55489 13.6074 3.71356 13.6074C3.87224 13.6074 4.00033 13.7355 4.00033 13.8942V14.8921C4.00033 15.0508 3.87224 15.1789 3.71356 15.1789Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.71356 17.3264C3.55489 17.3264 3.42871 17.1983 3.42871 17.0396V16.0416C3.42871 15.883 3.55489 15.7549 3.71356 15.7549C3.87224 15.7549 4.00033 15.883 4.00033 16.0416V17.0396C4.00033 17.1983 3.87224 17.3264 3.71356 17.3264Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M126.817 68.68H125.821C125.662 68.68 125.532 68.5519 125.532 68.3932C125.532 68.2345 125.662 68.1064 125.821 68.1064H126.817C126.976 68.1064 127.104 68.2345 127.104 68.3932C127.104 68.5519 126.976 68.68 126.817 68.68Z"
        fill="black"
      />
      <mask
        id="mask1_1_4794"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="127"
        y="67"
        width="3"
        height="3"
      >
        <path d="M127.388 67.4375H129.13V69.3493H127.388V67.4375Z" fill="black" />
      </mask>
      <g mask="url(#mask1_1_4794)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M128.842 68.68H127.846C127.688 68.68 127.558 68.5519 127.558 68.3932C127.558 68.2345 127.688 68.1064 127.846 68.1064H128.842C129.001 68.1064 129.129 68.2345 129.129 68.3932C129.129 68.5519 129.001 68.68 128.842 68.68Z"
          fill="black"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M127.391 68.1057C127.233 68.1057 127.106 67.9795 127.106 67.8208V66.821C127.106 66.6623 127.233 66.5361 127.391 66.5361C127.55 66.5361 127.678 66.6623 127.678 66.821V67.8208C127.678 67.9795 127.55 68.1057 127.391 68.1057Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M127.391 70.2522C127.233 70.2522 127.106 70.126 127.106 69.9673V68.9675C127.106 68.8088 127.233 68.6826 127.391 68.6826C127.55 68.6826 127.678 68.8088 127.678 68.9675V69.9673C127.678 70.126 127.55 70.2522 127.391 70.2522Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.7709 32.4261C24.2758 32.4261 23.8743 32.8294 23.8743 33.3246C23.8743 33.8197 24.2758 34.2212 24.7709 34.2212C25.2661 34.2212 25.6695 33.8197 25.6695 33.3246C25.6695 32.8294 25.2661 32.4261 24.7709 32.4261ZM24.7709 34.7947C23.9603 34.7947 23.3008 34.1352 23.3008 33.3246C23.3008 32.5121 23.9603 31.8525 24.7709 31.8525C25.5834 31.8525 26.243 32.5121 26.243 33.3246C26.243 34.1352 25.5834 34.7947 24.7709 34.7947Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.20918 42.6936H1.78918C1.39344 42.6936 1.07227 42.3724 1.07227 41.9767C1.07227 41.5809 1.39344 41.2598 1.78918 41.2598H6.20918C6.60491 41.2598 6.92609 41.5809 6.92609 41.9767C6.92609 42.3724 6.60491 42.6936 6.20918 42.6936Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M111.722 24.8411C111.227 24.8411 110.825 25.2445 110.825 25.7396C110.825 26.2348 111.227 26.6363 111.722 26.6363C112.217 26.6363 112.621 26.2348 112.621 25.7396C112.621 25.2445 112.217 24.8411 111.722 24.8411ZM111.722 27.2098C110.912 27.2098 110.252 26.5502 110.252 25.7396C110.252 24.9271 110.912 24.2676 111.722 24.2676C112.535 24.2676 113.194 24.9271 113.194 25.7396C113.194 26.5502 112.535 27.2098 111.722 27.2098Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.6625 20.1858C43.068 20.1858 42.5881 20.6675 42.5881 21.2602C42.5881 21.8528 43.068 22.3365 43.6625 22.3365C44.2552 22.3365 44.7369 21.8528 44.7369 21.2602C44.7369 20.6675 44.2552 20.1858 43.6625 20.1858ZM43.6625 23.7703C42.2784 23.7703 41.1543 22.6443 41.1543 21.2602C41.1543 19.8761 42.2784 18.752 43.6625 18.752C45.0467 18.752 46.1708 19.8761 46.1708 21.2602C46.1708 22.6443 45.0467 23.7703 43.6625 23.7703Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.3266 30.2073C96.7339 30.2073 96.2522 30.689 96.2522 31.2817C96.2522 31.8743 96.7339 32.358 97.3266 32.358C97.9212 32.358 98.401 31.8743 98.401 31.2817C98.401 30.689 97.9212 30.2073 97.3266 30.2073ZM97.3266 33.7918C95.9425 33.7918 94.8184 32.6658 94.8184 31.2817C94.8184 29.8976 95.9425 28.7734 97.3266 28.7734C98.7107 28.7734 99.8348 29.8976 99.8348 31.2817C99.8348 32.6658 98.7107 33.7918 97.3266 33.7918Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.9774 71.2433C50.7327 71.2433 50.488 71.1496 50.3006 70.9642L35.2034 55.8651C34.8306 55.4904 34.8306 54.8862 35.2034 54.5135C35.5762 54.1387 36.1822 54.1387 36.555 54.5135L51.6542 69.6107C52.027 69.9854 52.027 70.5895 51.6542 70.9642C51.4668 71.1496 51.2221 71.2433 50.9774 71.2433Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.8792 71.2433C35.6345 71.2433 35.3898 71.1496 35.2024 70.9642C34.8297 70.5895 34.8297 69.9854 35.2024 69.6107L50.3016 54.5135C50.6744 54.1387 51.2804 54.1387 51.6532 54.5135C52.026 54.8862 52.026 55.4904 51.6532 55.8651L36.556 70.9642C36.3686 71.1496 36.1239 71.2433 35.8792 71.2433Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.2147 71.2433C86.97 71.2433 86.7253 71.1496 86.5379 70.9642L71.4407 55.8651C71.0679 55.4904 71.0679 54.8862 71.4407 54.5135C71.8135 54.1387 72.4196 54.1387 72.7923 54.5135L87.8915 69.6107C88.2643 69.9854 88.2643 70.5895 87.8915 70.9642C87.7041 71.1496 87.4594 71.2433 87.2147 71.2433Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.1165 71.2433C71.8718 71.2433 71.6271 71.1496 71.4398 70.9642C71.067 70.5895 71.067 69.9854 71.4398 69.6107L86.5389 54.5135C86.9117 54.1387 87.5177 54.1387 87.8905 54.5135C88.2633 54.8862 88.2633 55.4904 87.8905 55.8651L72.7933 70.9642C72.6059 71.1496 72.3612 71.2433 72.1165 71.2433Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M89.0707 82.9225H35.9237C35.396 82.9225 34.9678 82.4943 34.9678 81.9666C34.9678 81.439 35.396 81.0107 35.9237 81.0107H89.0707C89.5984 81.0107 90.0266 81.439 90.0266 81.9666C90.0266 82.4943 89.5984 82.9225 89.0707 82.9225Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.9577 83.1461V89.9291C71.9577 93.4754 74.8444 96.3622 78.3907 96.3622C82.0135 96.3622 84.9003 93.4754 84.9003 89.9291V83.1461H71.9577ZM78.4653 98.2739C73.7891 98.2739 70.0459 94.5307 70.0459 89.9291V82.1903C70.0459 81.6626 70.4741 81.2344 71.0018 81.2344H85.8562C86.3838 81.2344 86.8121 81.6626 86.8121 82.1903V89.9291C86.8121 94.5307 83.0688 98.2739 78.4653 98.2739Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.1414 92.137C77.6138 92.137 77.1855 91.7087 77.1855 91.1811V82.1805C77.1855 81.6528 77.6138 81.2246 78.1414 81.2246C78.6691 81.2246 79.0973 81.6528 79.0973 82.1805V91.1811C79.0973 91.7087 78.6691 92.137 78.1414 92.137Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.4981 32.5045C39.36 32.5045 20.5368 51.3278 20.5368 74.4659C20.5368 97.604 39.36 116.427 62.4981 116.427C85.6362 116.427 104.457 97.604 104.457 74.4659C104.457 51.3278 85.6362 32.5045 62.4981 32.5045ZM62.4981 118.339C38.3066 118.339 18.625 98.6592 18.625 74.4659C18.625 50.2744 38.3066 30.5928 62.4981 30.5928C86.6876 30.5928 106.369 50.2744 106.369 74.4659C106.369 98.6592 86.6876 118.339 62.4981 118.339Z"
        fill="black"
      />
    </svg>
  )
}

export default ErrorImg

import {FunctionalComponent, h} from 'preact';

import 'swiper/swiper.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.min.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Swiper as SwiperType} from 'swiper/types';
import {Navigation, A11y} from "swiper";

import styles from './OffersCarousel.style.scss'
import {Reward} from "../../../../interfaces/sdk";
import OfferSlide from "./OfferSlide";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import {useState} from "preact/compat";
import useEvents, {rewardIds} from "../../../../hooks/useEvents";
import {getVisibleRewards} from "../../../../hooks/useRewardsListEvents";
import {injectImpressionTrackingTag} from "../../../../utils/tracking";
import useIsOnScreen from "../../../../hooks/useIsOnScreen";
import {REWARD_THUMBNAIL_WIDTH, TRACKING} from "../../../../global";
import {useSettingsContext} from "../../../../contexts/SettingsContext";
import clsx from "clsx";
import {useEffect} from "preact/hooks";
import {useInContentLayoutContext} from "../../../layouts/InContentLayout/InContentLayout";
import {getWindowWidth} from "../../../../utils";
import useIsTouchDevice from "../../../../hooks/useIsTouchDevice";

interface Props {
  rewards: Reward[];
}

const OffersCarousel: FunctionalComponent<Props> = ({rewards}) => {
  const isTouchDevice = useIsTouchDevice()
  const {childApi, openedRewardId} = useInContentLayoutContext()
  const [calculatedCarouselWidth, setCalculatedCarouselWidth] = useState(329)
  const {storeFrontType} = useSettingsContext()
  const raiseEvent = useEvents()
  const [swiper, setSwiper] = useState<SwiperType>()
  const breakpoint = useBreakpoint();
  const {ref: carouselContainer} = useIsOnScreen({
    isOnScreenCallback: () => {
      raiseRewardsFeaturedViewEvent()
    }
  }, {root: null, threshold: TRACKING.carousel_visibility_threshold})

  const raiseRewardsFeaturedViewEvent = () => {
    if (openedRewardId) return

    const {visibleRewards, visibilityPercents, positions} = getVisibleRewards(rewards)
    raiseEvent({
      name: 'rewards-featured-view',
      request_id: '-',
      ...rewardIds(visibleRewards),
      claim_context: 'claimable',
      visibility_percents: visibilityPercents,
      positions,
    })
    injectImpressionTrackingTag(visibleRewards, visibilityPercents)
  }

  const onSlideChangeTransitionEnd = (swiper: SwiperType) => {
    //fixme: Now that we always center the active slide, this event gets triggered 2 times on load
    raiseRewardsFeaturedViewEvent()
  }

  useEffect(() => {
    if (childApi && rewards.length > 0) {
      const newContainerWidth = (REWARD_THUMBNAIL_WIDTH * rewards.length)
      setCalculatedCarouselWidth(newContainerWidth)
      childApi.emit('set-carousel-container-width', newContainerWidth)
    }
  }, [childApi, rewards.length])

  useEffect(() => {
    if (!swiper) {
      return
    }
    swiper.allowTouchMove = Boolean(isTouchDevice && !openedRewardId)
  }, [isTouchDevice, openedRewardId, swiper])

  if (!breakpoint) return null

  return (
      <div ref={carouselContainer}>
        <Swiper
            className={clsx(styles.offersSwiperContainer, {
              [styles.storeFrontType_overlay]: storeFrontType === 'overlay',
              [styles.storeFrontType_cardFlip]: storeFrontType === 'card-flip'
            })}
            modules={[Navigation, A11y]}
            a11y={{
              enabled: true
            }}
            spaceBetween={8}
            slidesPerView={'auto'}
            onSwiper={(swiper: SwiperType) => setSwiper(swiper)}
            centerInsufficientSlides
            onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
            centeredSlides={false}
            centeredSlidesBounds={false}
            navigation={calculatedCarouselWidth > getWindowWidth()}
        >
          {rewards.map(reward => (
              <SwiperSlide key={reward.id}>
                <OfferSlide
                    reward={reward}
                />
              </SwiperSlide>
          ))}
        </Swiper>
      </div>
  )
};

export default OffersCarousel;


import {FunctionalComponent, h} from 'preact';
import axios from 'axios'
import {useDidMount} from "rooks";
import {useParams, useSearchParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {ClaimResponse} from "../../interfaces/sdk";

const ClaimRoute: FunctionalComponent = () => {
  const {rewardId} = useParams()
  const [searchParams] = useSearchParams()
  const claimMutation = useMutation<ClaimResponse, unknown, { id: string; requestId: string, user_consent?: string }>({
    mutationFn: async ({id, requestId, user_consent}) => {
      const body = {
        user_consent,
      }
      const {data} = await axios.post<ClaimResponse>(
          `sdk/claim/rewards/${id}/${requestId}`, body,
          {
            headers: {
              lang: searchParams.get('lang'),
              Authorization: `Bearer ${searchParams.get('token')}`,
              'gl-app-key-id': searchParams.get('appKeyId'),
              'gl-fuid': searchParams.get('fuid'),
              'gl-iid': searchParams.get('iid'),
              'gl-sid': searchParams.get('sid'),
            },
            params: {
              page_info_url: searchParams.get('pageInfoUrl')
            }
          }
      )
      return data
    },
  })

  useDidMount(() => {
    const userConsent = document.getElementById('consentData')?.innerHTML || ''
    if (rewardId) {
      claimMutation.mutate({id: rewardId, requestId: '', user_consent: userConsent}, {
        onSuccess: (data) => {
          window.location.replace(data?.data?.discount_code?.shop_url)
        },
      })
    }
  })

  return null
};

export default ClaimRoute;


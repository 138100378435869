import { parseToHsla } from 'color2k'

export const setCustomColors = (keyHexColor: string, headerBGHexColor: string, keyFontColor: string, headerFontColor: string) => {
  // Set key color
  const hslaKey = parseToHsla(keyHexColor)
  document.documentElement.style.setProperty('--color-key-h', `${hslaKey[0]}deg`);
  document.documentElement.style.setProperty('--color-key-s', `${hslaKey[1] * 100}%`);
  document.documentElement.style.setProperty('--color-key-l', `${hslaKey[2] * 100}%`);
  document.documentElement.style.setProperty('--color-key-contrast', `${keyFontColor}`);

  // Set secondary key color
  document.documentElement.style.setProperty('--color-key-secondary', `${headerBGHexColor}`);
  document.documentElement.style.setProperty('--color-key-secondary-contrast', `${headerFontColor}`);
}


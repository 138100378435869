class PseudoStorage implements Storage {
  private store: Record<string, string> = {}

  setItem(key: string, value: string): void {
    this.store[key] = value
  }

  getItem(key: string): string | null {
    return this.store[key] ?? null
  }

  get length(): number {
    return Object.entries(this.store).length
  }

  clear(): void {
    this.store = {}
  }
  key(index: number): string | null {
    return Object.keys(this.store)[index] ?? null
  }
  removeItem(key: string): void {
    delete this.store[key]
  }
}

let safeLocalStorage: Storage
try {
  safeLocalStorage = window.localStorage
} catch (error) {
  safeLocalStorage = new PseudoStorage()
  console.warn('Local storage unavailable')
}

let safeSessionStorage: Storage
try {
  safeSessionStorage = window.sessionStorage
} catch (error) {
  safeSessionStorage = new PseudoStorage()
  console.warn('Session storage unavailable')
}

export {safeLocalStorage, safeSessionStorage}

import {FunctionalComponent} from "preact";
import ClaimableRewardsSection from '../../components/sections/ClaimableRewardsSection'

const PanelHomeRoute: FunctionalComponent = () => {
  return (
      <>
        <div className="container">
          <ClaimableRewardsSection />
        </div>
      </>
  )
}
export default PanelHomeRoute

import {useQuery} from "@tanstack/react-query";
import {queries} from "../../queryKeyFactory";
import {FormattedUserProgress} from "../../utils/userProgress";

const useUserProfileQuery = (): {progress: FormattedUserProgress | undefined} => {
  const {data: progress} = useQuery({
    ...queries.profile.user,
    refetchOnWindowFocus: false
  })

  return {
    progress
  }
}

export default useUserProfileQuery

import {Fragment, FunctionalComponent} from 'preact';
import { CSSProperties } from 'preact/compat';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { useTranslation } from '../contexts/I18nContext';
import Button from './Button';
import clsx from "clsx";

interface Props {
  startExpanded?: boolean;
  maxCollapsedHeight?: number;
  hideLinkText?: string;
  showLinkText?: string;
  expandBtnClass?: string;
}

const ExpandableContainer: FunctionalComponent<Props> = ({
  startExpanded = false,
  maxCollapsedHeight = 72,
  hideLinkText,
  showLinkText,
  children,
  expandBtnClass,
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(startExpanded)
  const [overflows, setOverflows] = useState(ref.current && ref.current.offsetHeight > maxCollapsedHeight)

  const recalculateOverlows = useCallback(() => {
    setOverflows(ref.current && ref.current.offsetHeight > maxCollapsedHeight)
  }, [ref, maxCollapsedHeight])

  useEffect(() => {
    recalculateOverlows()
    window.addEventListener('resize', recalculateOverlows)
    return () => window.removeEventListener('resize', recalculateOverlows)
  }, [recalculateOverlows])

  const wrapperStyles: CSSProperties = {
    overflow: 'hidden',
    maxHeight: expanded ? 'unset' : `${maxCollapsedHeight}px`
  }

  return (
    <Fragment>
      <div style={wrapperStyles}>
        <div ref={ref}>
          {children}
        </div>
      </div>
      {overflows && (
        <Button
          link
          className={clsx('body3 link secondary', expandBtnClass)}
          onClick={() => setExpanded((current) => !current)}
        >
          {expanded ? hideLinkText ?? t('common.readLess') : showLinkText ?? t('common.readMore')}
        </Button>
      )}
    </Fragment>
  )
}

export default ExpandableContainer

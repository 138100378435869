import {FunctionalComponent} from 'preact';
import FlippingCard from "../FlippingCard/FlippingCard";
import {Reward} from "../../../../interfaces/sdk";
import CardBackContent from "./CardBackContent";
import RewardThumbnail from "../../../RewardThumbnail";
import {useInContentLayoutContext} from "../../../layouts/InContentLayout/InContentLayout";
import {useSettingsContext} from "../../../../contexts/SettingsContext";

interface Props {
  reward: Reward;
}

const OfferSlide: FunctionalComponent<Props> = ({reward}) => {
  const {openedRewardId, setOpenedRewardId} = useInContentLayoutContext()
  const {storeFrontType} = useSettingsContext()

  if (storeFrontType === 'card-flip') {
    return (
        <FlippingCard
            id={reward.id}
            flipped={reward.id === openedRewardId}
            frontContent={(
                <RewardThumbnail
                    reward={reward}
                />
            )}
            backContent={(
                <CardBackContent
                    flipped={reward.id === openedRewardId}
                    reward={reward}
                    onBackClick={() => setOpenedRewardId('')}
                />
            )}
        />
    )
  }

  return (
      <RewardThumbnail
          reward={reward}
      />
  );
};

export default OfferSlide;


import styles from './RootLayout.style.scss'
import {Outlet} from "react-router-dom";
import {Toaster} from "react-hot-toast";

const RootLayout = () => {

  return (
      <div className={styles.RootLayout}>
        <Toaster position="top-center" />
        <Outlet />
      </div>
  );
};

export default RootLayout;


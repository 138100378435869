import {FunctionalComponent, h} from 'preact';
import styles from './PrivacyPolicy.style.scss'
import DOMPurify from 'dompurify';
import {useSettingsContext} from "../contexts/SettingsContext";
import clsx from "clsx";
import {useEffect, useRef} from "preact/hooks";

interface Props {
  text: string;
}

const PrivacyPolicy: FunctionalComponent<Props> = ({text}) => {
  const {layout} = useSettingsContext()
  const consentDataRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!consentDataRef.current) return

    const noHrefAnchors = consentDataRef.current.querySelectorAll<HTMLAnchorElement>('a[href=""], a:not([href])')
    noHrefAnchors.forEach(elem => {
      elem.addEventListener('click', e => {
        // Prevent click if href is empty
        e.preventDefault()
      })
    })
  }, [])

  if (!text) return null

  return (
      <div ref={consentDataRef} id="consentData"
           className={clsx(styles.text, {
             [styles.inContent]: layout === 'carousel'
           })}
      >
        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text, {ADD_ATTR: ['target']})}} />
      </div>
  )
};

export default PrivacyPolicy;

import {useEffect, useRef, useState} from "preact/hooks";
import {useIntersectionObserverRef} from "rooks";

type Settings = {
  isOnScreenCallback: () => void,
  isOnScreenDelay?: number
}

const MINIMUM_VIEW_DURATION = 1000

const useIsOnScreen = (
    {isOnScreenCallback, isOnScreenDelay = MINIMUM_VIEW_DURATION}: Settings,
    obsInit: IntersectionObserverInit
) => {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const timerId = useRef<any>()
  const [ref] = useIntersectionObserverRef((entries) => {
    if (entries && entries[0]) {
      setIsOnScreen(entries[0].isIntersecting);
    }
  }, obsInit);

  useEffect(() => {
    if (isOnScreen) {
      timerId.current = setTimeout(() => {
        isOnScreenCallback()
      }, isOnScreenDelay);
    } else {
      clearTimeout(timerId.current);
    }
    return () => {
      clearTimeout(timerId.current)
    }
  }, [isOnScreen, isOnScreenCallback, isOnScreenDelay])

  return {ref, isOnScreen}

}

export default useIsOnScreen;

import {FunctionalComponent, h} from 'preact';
import useTranslation from "../../hooks/useTranslation";

type Props = unknown

const NoClaimableRewards: FunctionalComponent<Props> = (props) => {
  const {t} = useTranslation()

  return (
      <div style={{padding: '4rem 0', textAlign: 'center'}}>
        {t('common.noRewards')}
        <div>
          <a href="javascript:window.location.reload()">{t('common.retry')}</a>
        </div>
      </div>
  );
};

export default NoClaimableRewards;


export const is = {
  // Is the value considered an object.
  obj: (a:any) => Object.prototype.toString.call(a).indexOf('Object') !== -1,
  // Is the value considered an array.
  arr: (a: any) => Array.isArray(a),
  // Is the value considered an string.
  str: (a: any) => typeof a === 'string',
  // Is the value considered an function.
  fnc: (a: any) => typeof a === 'function',
  // Is the value considered a number.
  nbr: (a: any) => typeof a === 'number' && !isNaN(a),
  // Is the value undefined.
  und: (a: any) => a === undefined,
  // Is the value empty (null or undefined).
  nil: (a: any) => is.und(a) || a === null,
  ios: () => {
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
      // detect Mac OS
      (navigator.userAgent.includes('Mac'))
  },
}

export const getOS = () => {
  return is.ios() ? 'ios' : 'android'
}

export const copyToClipboard = async (str:string) => {
  try {
    await navigator.clipboard.writeText(str)
    return true
  } catch (e) {
    if (document.execCommand) {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      const success = document.execCommand('copy')
      document.body.removeChild(el)
      return success
    }
  }
  return false
}

export const getBrowserLanguages = () => {
  const languages:string[] = [];

  (navigator.languages || [navigator.language]).forEach((language) => {
    if (!languages.includes(language)) {
      languages.push(language)
    }
    const tags = language.split('-')
    if (tags.length > 1 && !languages.includes(tags[0])) {
      languages.push(tags[0])
    }
  })

  return languages
}

export const getHostAppSlug = (): string => {
  const [part0, hostAppSlug] = window.location.hostname.split('.')

  /*
    Add this in the host file of your computer for testing

    ####
    #Domains for testing gl-rewards-app on different domains

    #Local
    127.0.0.1  rewards.qub.local
    127.0.0.1  rewards.narcity.local
    127.0.0.1  recompenses.qub.local
    127.0.0.1  recompenses.narcity.local

    #Staging
    199.36.158.100 rewards.qub.staging
    199.36.158.100 rewards.narcity.staging
    199.36.158.100 recompenses.qub.staging
    199.36.158.100 recompenses.narcity.staging
    ####

   */

  // We support domain name with this structure -->
  // rewards.${slug}.*
  // recompenses.${slug}.*
  if ((part0 === 'rewards' || part0 === 'recompenses') && hostAppSlug !== 'goloot') {
    return hostAppSlug
  }

  return ''
}

export const removeParamsFromUrl = (...params: string[]): void => {
  if (params.length) {
    const url = new URL(window.location.href)
    params.forEach((param) => {
      url.searchParams.delete(param)
    })
    window.history.replaceState({}, document.title, url.toString())
  }
}

export const isDateAfter = (a: string | number | Date, b: string | number | Date): boolean => {
  const dateA = new Date(a)
  const dateB = new Date(b)

  return dateA.valueOf() > dateB.valueOf()
}

export const getWindowWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth
}

export const getMainDomain = (url: string) => {
  const urlObj = new URL(url);
  const hostname = urlObj.hostname;

  // Split the hostname into parts
  const parts = hostname.split(".");

  // If the hostname has more than two parts, it has a subdomain
  if (parts.length > 2) {
    // Remove the subdomain parts
    return parts.slice(parts.length - 2).join(".");
  }

  return hostname;
};

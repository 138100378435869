import {FunctionalComponent} from 'preact';
import RewardSingleModal from "../../components/RewardSingleModal";

const StandAloneRewardRoute: FunctionalComponent = (props) => {

  return (
      <RewardSingleModal />
  );
};

export default StandAloneRewardRoute;


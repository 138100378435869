const BASE_PATH = '/panel'
export const home = () => `${BASE_PATH}`

type RewardSingleParams = { id: string, requestId: string, claimId: string }
export const rewardSingle = ({id, requestId, claimId}: RewardSingleParams) => {
  if (claimId) {
    return `${BASE_PATH}/reward/${id}/${requestId}/${claimId}`
  }
  return `${BASE_PATH}/reward/${id}/${requestId}`
}

export const profile = () => `${BASE_PATH}/profile`

import {useEffect} from "preact/hooks";
import OffersCarousel from "../../components/integrations/inContent/OffersCarousel/OffersCarousel";
import CarouselPlaceholder from "../../components/common/CarouselPlaceholder";
import {useSettingsContext} from "../../contexts/SettingsContext";
import useUserProfileQuery from "../../hooks/queries/useUserProfileQuery";
import WelcomeMessage from "../../components/WelcomeMessage";
import useClaimableRewardsQuery from "../../hooks/queries/useClaimableRewardsQuery";
import NoClaimableRewards from "../../components/common/NoClaimableRewards";
import useIntegrationPhrases from "../../hooks/useIntegrationPhrases";
import useRewardsClaimLimitReached from "../../hooks/useRewardsClaimLimitReached";
import {StorefrontType} from "../../interfaces/integrations";
import useOpenReward from "../../hooks/useOpenReward";
import AffiliateDisclosure from "../../components/AffiliateDisclosure";
import PoweredByGoloot from "../../components/PoweredByGoloot";

function InContentHomeRoute() {
  const tIntegration = useIntegrationPhrases()
  const {initialParentRewardId, storeFrontType, headerTitle, subheading} = useSettingsContext()
  const {data, isLoading, isRefetching} = useClaimableRewardsQuery()
  const rewards = useRewardsClaimLimitReached(data?.data.payload || [])
  const {progress} = useUserProfileQuery()
  const {handleOpenReward} = useOpenReward()

  useEffect(() => {
    if (initialParentRewardId) {
      const reward = rewards.find(item => item.reward_id === initialParentRewardId)
      if (reward) {
        handleOpenReward(reward, reward.claimed?.[0].claim_id || '')
      }
    }
  }, [handleOpenReward, initialParentRewardId, rewards])

  if (!progress) return null

  // headerTitle and subHeading are get params
  const _headerTitle = headerTitle || tIntegration('header_title')
  const _subheading = subheading || tIntegration('subheading')

  return (
      <div style={{padding: '13px 2px', overflow: 'hidden'}}>
        {rewards.length > 0 &&
          <div className="mb-4 text-center">
            <WelcomeMessage headerTitle={_headerTitle} subheading={_subheading} />
            <AffiliateDisclosure rewardsAmount={rewards.length} />
          </div>
        }
        <div style={{paddingTop: storeFrontType === StorefrontType.Overlay ? 0 : 8}}>
          {(isLoading || isRefetching) ? (
              <CarouselPlaceholder />
          ) : rewards.length > 0 && (
              <OffersCarousel
                  rewards={rewards}
              />
          )}
          {!isLoading && rewards.length === 0 &&
            <NoClaimableRewards />
          }

          <PoweredByGoloot rewardsAmount={rewards.length} />

        </div>
      </div>
  );
}

export default InContentHomeRoute;


import {h, FunctionalComponent} from "preact"

type Props = h.JSX.HTMLAttributes<SVGElement>

const SmsIcon: FunctionalComponent<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>SMS icon</title>
    <path d="M20.64 3.83997H3.35996C2.03621 3.83997 0.959961 4.91622 0.959961 6.23997V17.76C0.959961 19.0837 2.03621 20.16 3.35996 20.16H5.34746C5.51059 21.5493 5.09996 22.4925 4.07246 23.1093C3.88871 23.22 3.80059 23.4412 3.85684 23.6475C3.91496 23.8556 4.10434 24 4.31996 24C5.54809 24 8.49934 23.6175 9.92809 20.16H20.64C21.9637 20.16 23.04 19.0837 23.04 17.76V6.23997C23.04 4.91622 21.9637 3.83997 20.64 3.83997ZM7.19996 12.96C6.66934 12.96 6.23996 12.5306 6.23996 12C6.23996 11.4693 6.66934 11.04 7.19996 11.04C7.73059 11.04 8.15996 11.4693 8.15996 12C8.15996 12.5306 7.73059 12.96 7.19996 12.96ZM12 12.96C11.4693 12.96 11.04 12.5306 11.04 12C11.04 11.4693 11.4693 11.04 12 11.04C12.5306 11.04 12.96 11.4693 12.96 12C12.96 12.5306 12.5306 12.96 12 12.96ZM16.8 12.96C16.2693 12.96 15.84 12.5306 15.84 12C15.84 11.4693 16.2693 11.04 16.8 11.04C17.3306 11.04 17.76 11.4693 17.76 12C17.76 12.5306 17.3306 12.96 16.8 12.96Z" fill="currentColor" />
  </svg>
)

export default SmsIcon

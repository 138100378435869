import useEvents from "../useEvents";
import {useSettingsContext} from "../../contexts/SettingsContext";

const usePageViewEvent = () => {
  const raiseEvent = useEvents()
  const {newsletterBannerClickId} = useSettingsContext()

  return (payload:any) => {
    raiseEvent({
      name: 'page-view',
      ...{
        ...payload,
        newsletter_banner_click_id: newsletterBannerClickId
      }
    })
  }
}

export default usePageViewEvent

import {useSettingsContext} from "../../contexts/SettingsContext";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ClaimResponse, RewardResponse} from "../../interfaces/sdk";
import {claimReward} from "../../services/rewards";
import {queries} from "../../queryKeyFactory";
import {produce} from "immer";
import {formatUserProgress} from "../../utils/userProgress";
import {safeLocalStorage} from "../../utils/storage";
import useRewardsClaimData from "../useRewardsClaimData";
import {useInContentLayoutContext} from "../../components/layouts/InContentLayout/InContentLayout";
import {settings} from "../../global";

const useClaimRewardMutation = () => {
  const {activeIntegration} = useSettingsContext()
  const queryClient = useQueryClient()
  const setClaimData = useRewardsClaimData()
  const {childApi} = useInContentLayoutContext()

  return useMutation<ClaimResponse, unknown, { id: string; requestId: string; locale: string, user_consent?: string }>({
    mutationFn: ({id, requestId, locale, user_consent}) => claimReward(id, requestId, locale, user_consent),
    onSuccess: (response) => {
      const id = response.data.discount_code.redeem_published_reward_id
      if (activeIntegration) {
        safeLocalStorage.setItem(`claim_reward_id_${id}`, JSON.stringify(response.data.discount_code))
      }
      // Update query for single reward.
      queryClient.setQueryData<RewardResponse>(['reward', {id}], (oldData) => {
        if (oldData) {
          return produce(oldData, draft => {
            draft.data.claimed = [response.data.discount_code]
          })
        }
        return undefined
      })
      //Update the newly claimed reward in the list
      if (settings.isStorefrontOverlay) {
        childApi.emit('set-claim-data', {
          publicRewardId: id,
          claimData: response.data.discount_code
        })
      } else {
        setClaimData(id, response.data.discount_code)
      }

      // Update user progress.
      queryClient.setQueryData(queries.profile.user.queryKey, () => {
        return formatUserProgress(response.userProgress)
      })
    },
  })
}

export default useClaimRewardMutation

import ApprovalIcon from './ApprovalIcon'
import BackIcon from './BackIcon'
import ChevronRightIcon from './ChevronRightIcon'
import CloseIcon from './CloseIcon'
import EmailIcon from './EmailIcon'
import FacebookIcon from './FacebookIcon'
import GiftIcon from './GiftIcon'
import InstagramIcon from './InstagramIcon'
import LinkingIcon from './LinkingIcon'
import LoginIcon from './LoginIcon'
import LogoutIcon from './LogoutIcon'
import PadlockIcon from './PadlockIcon'
import ProfileIcon from './ProfileIcon'
import ReadingIcon from './ReadingIcon'
import RefreshIcon from './RefreshIcon'
import SentIcon from './SentIcon'
import ShareIcon from './ShareIcon'
import ShoppingBagIcon from './ShoppingBagIcon'
import SmsIcon from './SmsIcon'
import TwitterIcon from './TwitterIcon'
import EditIcon from "./EditIcon";
import PanelCloseIcon from "./PanelCloseIcon";

const IconNameMap = {
  approval: ApprovalIcon,
  back: BackIcon,
  chevronRight: ChevronRightIcon,
  close: CloseIcon,
  email: EmailIcon,
  facebook: FacebookIcon,
  gift: GiftIcon,
  instagram :InstagramIcon,
  linking :LinkingIcon,
  login :LoginIcon,
  logout :LogoutIcon,
  padlock: PadlockIcon,
  profile: ProfileIcon,
  reading: ReadingIcon,
  refresh: RefreshIcon,
  sent: SentIcon,
  share: ShareIcon,
  shoppingBag: ShoppingBagIcon,
  sms: SmsIcon,
  twitter: TwitterIcon,
  edit: EditIcon,
  panelClose: PanelCloseIcon,
}

export {
  IconNameMap,
  ApprovalIcon,
  BackIcon,
  ChevronRightIcon,
  CloseIcon,
  EmailIcon,
  FacebookIcon,
  GiftIcon,
  InstagramIcon,
  LinkingIcon,
  LoginIcon,
  LogoutIcon,
  PadlockIcon,
  ProfileIcon,
  ReadingIcon,
  RefreshIcon,
  SentIcon,
  ShareIcon,
  ShoppingBagIcon,
  SmsIcon,
  TwitterIcon,
  EditIcon,
  PanelCloseIcon,
}

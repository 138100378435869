import { useCallback, useEffect, useRef } from 'preact/hooks';
import { Reward } from '../interfaces/sdk';
import { sendEvent } from '../services/events';
import useAuth from './useAuth';
import { EventType, RewardIds, RewardsListIds, EventIdentity } from '../interfaces/events';
import {globalEvent, pageInfo, settings, ulid} from "../global";
import {useSettingsContext} from "../contexts/SettingsContext";
import {useInContentLayoutContext} from "../components/layouts/InContentLayout/InContentLayout";

export function rewardIds(p: Reward[]): RewardsListIds;
export function rewardIds(p: Reward): RewardIds;
export function rewardIds(p: Reward | Reward[]): RewardIds | RewardsListIds {
  return Array.isArray(p) ? {
    resource_ids: p.map(i => i.reward_id || ''),
    published_ids: p.map(i => i.id || ''),
    advertiser_ids: p.map(i => i.advertiser_id || ''),
  } : {
    resource_id: p.reward_id || '',
    published_id: p.id || '',
    advertiser_id: p.advertiser_id || '',
  }
}

const useEvents = () => {
  const {storeFrontType} = useSettingsContext()
  const {childApi} = useInContentLayoutContext()
  const { isLoading } = useAuth()
  const eventQueue = useRef<{event: EventType; identity: EventIdentity}[]>([])

  const raise = useCallback((event: EventType, identity: EventIdentity) => {
    const requestId = ('request_id' in event) ? event.request_id as string : ''
    // Spread declarations to define a data object that includes requestId but excludes name.
    const { name, ...data } = {...event, request_id: requestId}

    if (!isLoading) {
      void sendEvent({
        name,
        data,
        source: 'gl-web-sdk',
        conversion: false,
        init_source: settings.initSource || undefined,
        location: pageInfo.url || undefined,
        tags: pageInfo.tags || undefined,
        ...identity,
      })
    } else {
      eventQueue.current.push({event, identity})
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoading && eventQueue.current.length) {
      const queue = eventQueue.current
      eventQueue.current = []
      queue.forEach(({event, identity: session}) => raise(event, session))
    }
  }, [isLoading, raise])

  return useCallback((event: EventType) => {
    const eventId = ulid()

    const eventIdentity: EventIdentity = {
      created_client: new Date().toISOString(),
      event_id: eventId,
      page_impression_id: globalEvent.pageImpressionId,
    }

    if (globalEvent.panelOpenId) {
      eventIdentity.panel_open_id = globalEvent.panelOpenId
    }
    if (globalEvent.appViewId) {
      eventIdentity.app_view_id = globalEvent.appViewId
    }
    if (globalEvent.previousEventId) {
      eventIdentity.event_id_prev = globalEvent.previousEventId
    }

    globalEvent.previousEventId = eventId

    // This will keep the prevEventId of the storefront overlay in sync with the carousel
    if (settings.isStorefrontOverlay && childApi) {
      childApi.emit('set-prev-event-id', globalEvent.previousEventId)
    }

    switch (event.name) {
      case "app-view":
        globalEvent.appViewId = eventId
        break;
      case "panel-open":
        globalEvent.panelOpenId = eventId
        break;
    }

    return raise(event, eventIdentity)
  }, [childApi, raise, storeFrontType])
}

export default useEvents

import {FunctionalComponent} from 'preact';
import {Link, useSearchParams} from "react-router-dom";
import useEvents from "../hooks/useEvents";
import {useSettingsContext} from "../contexts/SettingsContext";
import {getLocalUser} from "../services/auth";
import {pageInfo} from "../global";
import {useTranslation} from "../contexts/I18nContext";

interface Props {
  rewardId: string;
  resourceId: string;
  advertiserId: string;
}

const ClickOutToClaim: FunctionalComponent<Props> = ({children, rewardId, resourceId, advertiserId}) => {
  const raiseEvent = useEvents();
  const [searchParams] = useSearchParams();
  const {frontEndUserId, userSessionId, application} = useSettingsContext()
  const {locale} = useTranslation()

  let localStorageUser
  try {
    const userString = getLocalUser(application.is_user_saved_in_session_storage, application.slug)
    if (userString !== undefined && userString !== 'undefined' && userString !== null) {
      localStorageUser = JSON.parse(userString)
    }
  } catch (e: any) {
    console.error(e)
  }

  const claimSearchParams = new URLSearchParams({
    iid: searchParams.get('integration_id') || '',
    fuid: frontEndUserId,
    sid: userSessionId,
    token: localStorageUser.accessToken,
    appKeyId: localStorageUser.appKeyId,
    pageInfoUrl: pageInfo.url,
    lang: locale
  })

  const handleRedeemClick = () => {
    raiseEvent({
      name: "reward-claim-click",
      view_context: "reward-view",
      resource_id: resourceId,
      published_id: rewardId,
      advertiser_id: advertiserId,
    });
  };

  return (
      <Link
          to={`/claim/${rewardId}?${claimSearchParams.toString()}`}
          target="_blank"
          onClick={handleRedeemClick}
      >
        {children}
      </Link>
  );
};

export default ClickOutToClaim;

